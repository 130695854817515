import {
    BaseSlots,
    createTheme,
    getColorFromRGBA,
    getColorFromString,
    getContrastRatio,
    IButtonStyles,
    IFontStyles,
    IPalette,
    ITheme,
    IToggleStyles,
    Theme,
    ThemeGenerator,
    themeRulesStandardCreator,
} from '@fluentui/react';
import {
    useDispatchContext,
    useStateContext,
} from '../services/contextProvider';
import { useCallback, useMemo } from 'react';

export const StorageThemeKey = 'ADVANCE_THEME';

export enum ThemeNames {
    default = 'Default',
    dark = 'Dark',
}

const defaultFontStyle = {
    fontFamily:
        '"new-hero", "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif',
};

const fonts: Partial<IFontStyles> = {
    xSmall: {
        fontWeight: 'light',
        fontSize: 9,
    },
    small: {
        fontWeight: 'light',
        fontSize: 11,
    },
    smallPlus: {
        fontWeight: 'regular',
        fontSize: 12,
    },
    medium: {
        fontWeight: 'light',
        fontSize: 12,
    },
    mediumPlus: {
        fontWeight: 'regular',
        fontSize: 14,
    },
    large: {
        fontWeight: 'semibold',
    },
    xLarge: {
        fontWeight: 'semibold',
    },
};

const buttonStyles: IButtonStyles = {
    root: {
        borderRadius: 4,
    },
};

export const defaultTheme = createTheme({
    defaultFontStyle: defaultFontStyle,
    fonts: fonts,
    components: {
        Button: {
            styles: buttonStyles,
        },
        PrimaryButton: {
            styles: buttonStyles,
        },
        DefaultButton: {
            styles: buttonStyles,
        },
        CommandBarButton: {
            styles: buttonStyles,
        },
        ActionButton: {
            styles: buttonStyles,
        },
        TextField: {
            styles: {
                root: {
                    borderRadius: 4,
                },
                prefix: {
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                },
                suffix: {
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                },
            },
        },
        Toggle: {
            styles: {
                text: {
                    paddingTop: '2px !important',
                },
            } as IToggleStyles,
        },
        MessageBar: {
            styles: {
                root: {
                    borderRadius: 8,
                },
            },
        },
        Dialog: {
            styles: {
                root: {
                    borderRadius: 8,
                },
            },
        },
        // DetailsHeader: {
        //     styles: {
        //         root: {
        //             selectors: {
        //                 '.ms-DetailsHeader-cell': {
        //                     whiteSpace: 'pre-line',
        //                     textOverflow: 'clip',
        //                     lineHeight: 'normal',
        //                 },
        //                 '.ms-DetailsHeader-cellTitle': {
        //                     height: '100%',
        //                     alignItems: 'center',
        //                     textAlign: 'center',
        //                 },
        //             },
        //         },
        //     },
        // },
    },

    effects: {
        roundedCorner2: '4px',
        roundedCorner4: '8px',
    },
    palette: {
        themePrimary: '#363636', // GP Charcoal
        themeLighterAlt: '#d0d0d0', // GP Silver
        themeLighter: '#818182', // GP Steel
        themeLight: '#ffbd59', // MR Gold
        themeTertiary: '#488eb4',
        themeSecondary: '#116491',
        themeDarkAlt: '#818182', // GP Steel
        themeDark: '#818182', // GP Steel
        themeDarker: '#363636', // GP Charcoal
        neutralLighterAlt: '#ececec', // GP Cloud
        neutralLighter: '#FAF9F8',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#818182',
        neutralSecondary: '#515250',
        neutralPrimaryAlt: '#4e4e4e',
        neutralPrimary: '#363636', // GP Charcoal
        neutralDark: '#363636', // GP Charcoal
        black: '#363636', // GP Charcoal
        white: '#ffffff',
    },
    semanticColors: {
        messageLink: '#363636', // GP Charcoal
        messageLinkHovered: '#363636', // GP Charcoal
    },
});

const getLightTheme = (primaryColour: string | null | undefined): ITheme => {
    if (primaryColour) {
        const themeRules = themeRulesStandardCreator();
        const colors = {
            primaryColor: getColorFromString(primaryColour) || primaryColour,
            textColor: getColorFromString('#1e1e1e') || '#1e1e1e',
            backgroundColor: getColorFromString('#ffffff') || '#ffffff',
        };

        ThemeGenerator.setSlot(
            themeRules[BaseSlots[BaseSlots.primaryColor]],
            colors.primaryColor,
            undefined,
            true,
            true
        );

        ThemeGenerator.setSlot(
            themeRules[BaseSlots[BaseSlots.foregroundColor]],
            colors.textColor,
            undefined,
            true,
            true
        );

        ThemeGenerator.setSlot(
            themeRules[BaseSlots[BaseSlots.backgroundColor]],
            colors.backgroundColor,
            undefined,
            true,
            true
        );

        const divisonThemeJson = ThemeGenerator.getThemeAsJson(themeRules);

        return createTheme({
            ...defaultTheme,
            palette: divisonThemeJson,
        });
    } else {
        return defaultTheme;
    }
};

const generateDarkTheme = (baseTheme: ITheme): ITheme => {
    const darkPalette: Partial<IPalette> = {
        themeDarker: baseTheme.palette.themeLighter,
        themeDark: baseTheme.palette.themeLight,
        themeDarkAlt: baseTheme.palette.themeLighterAlt,
        themePrimary: baseTheme.palette.themeLighter,
        themeSecondary: baseTheme.palette.themePrimary,
        themeTertiary: baseTheme.palette.themeDark,
        themeLight: baseTheme.palette.themeLight,
        themeLighter: baseTheme.palette.themePrimary,
        themeLighterAlt: baseTheme.palette.themeDarker,
        black: '#ffffff',
        neutralDark: '#faf9f8',
        neutralPrimary: '#f3f2f1',
        neutralPrimaryAlt: '#c8c6c4',
        neutralSecondary: '#a19f9d',
        neutralSecondaryAlt: '#979693',
        neutralTertiary: '#797775',
        neutralTertiaryAlt: '#484644',
        neutralQuaternary: '#3b3a39',
        neutralQuaternaryAlt: '#323130',
        neutralLight: '#292827',
        neutralLighter: '#000000',
        neutralLighterAlt: '#101010',
        white: '#1b1a19',
        redDark: '#F1707B',
    };

    return createTheme({
        defaultFontStyle: defaultFontStyle,
        fonts: fonts,
        palette: darkPalette,
        components: baseTheme.components,
        semanticColors: {
            buttonText: darkPalette.black,
            buttonTextPressed: darkPalette.neutralDark,
            buttonTextHovered: darkPalette.neutralPrimary,
            disabledBackground: darkPalette.neutralQuaternaryAlt,
            inputBackgroundChecked: darkPalette.themePrimary,
            menuBackground: darkPalette.neutralLighter,
            menuItemBackgroundHovered: darkPalette.neutralQuaternaryAlt,
            menuItemBackgroundPressed: darkPalette.neutralQuaternary,
            menuDivider: darkPalette.neutralTertiaryAlt,
            menuIcon: darkPalette.themeDarkAlt,
            menuHeader: darkPalette.black,
            menuItemText: darkPalette.neutralPrimary,
            menuItemTextHovered: darkPalette.neutralDark,
        },
        isInverted: true,
    });
};

type LogoThemes = 'Dark' | 'White' | 'Charcoal';

export const useThemes = (): {
    currentTheme: Theme;
    lightTheme: Theme;
    darkTheme: Theme;
    headerTheme: Theme;
    changeTheme: (themeName: 'Default' | 'Dark') => void;
    currentThemeName: string;
    checkAccessibility: (primaryColourHex: string) => {
        lightIsAccessible: boolean;
        darkIsAccessible: boolean;
    } | null;
    getContrastColourHex: (
        backgroundColourHex: string,
        colourOptions: string[]
    ) => string;
    buttonStyles: {
        mai: IButtonStyles;
    };
    logoTheme: LogoThemes;
} => {
    const { currentThemeName, themePrimaryColourHex } = useStateContext();
    const dispatch = useDispatchContext();

    const changeTheme = (themeName: 'Default' | 'Dark') => {
        localStorage.setItem(StorageThemeKey, themeName);
        dispatch({
            type: 'SetCurrentTheme',
            payload: themeName,
        });
    };

    const lightTheme = useMemo(
        () =>
            getLightTheme(
                themePrimaryColourHex ? `#${themePrimaryColourHex}` : null
            ),
        [themePrimaryColourHex]
    );

    const darkTheme = useMemo(
        () => generateDarkTheme(lightTheme),
        [lightTheme]
    );

    const storedTheme = localStorage.getItem(StorageThemeKey);

    const themeName: string = useMemo(() => {
        return currentThemeName || storedTheme || ThemeNames.default;
    }, [currentThemeName, storedTheme]);

    const currentTheme = themeName === ThemeNames.dark ? darkTheme : lightTheme;

    const headerBackgroundColourHex = useMemo(
        () =>
            themeName === 'Dark' || !themePrimaryColourHex
                ? '#363636'
                : `#${themePrimaryColourHex}`,
        [themeName, themePrimaryColourHex]
    );

    const headerIsDark = useMemo(() => {
        const headerBackgroundColour = getColorFromString(
            headerBackgroundColourHex
        );
        return headerBackgroundColour
            ? getContrastRatio(
                  headerBackgroundColour,
                  getColorFromRGBA({ r: 355, g: 255, b: 255 })
              ) >= 3.5
            : true;
    }, [headerBackgroundColourHex]);

    const logoTheme: LogoThemes = useMemo(() => {
        if (themePrimaryColourHex) {
            return headerIsDark ? 'White' : 'Charcoal';
        } else {
            return 'Dark';
        }
    }, [headerIsDark, themePrimaryColourHex]);

    const headerTheme = useMemo(() => {
        const basePalette = headerIsDark
            ? darkTheme.palette
            : lightTheme.palette;

        return createTheme({
            defaultFontStyle: defaultFontStyle,
            fonts: fonts,
            palette: {
                ...basePalette,
                // themePrimary: '#c7c8ca',
                // themeLighterAlt: '#010607',
                // themeLighter: '#05161b',
                // themeLight: '#092a32',
                // themeTertiary: '#125365',
                // themeSecondary: '#1b7a94',
                // themeDarkAlt: basePalette.themeLighterAlt,
                // themeDark: basePalette.themeLight,
                // themeDarker: '#70bace',
                // neutralLighterAlt: '#103657',
                neutralLighter:
                    headerIsDark && themePrimaryColourHex
                        ? basePalette.themeLighterAlt
                        : basePalette.neutralLighter,
                // neutralLight: basePalette.themePrimary,
                // neutralQuaternaryAlt: basePalette.themePrimary,
                // neutralQuaternary: '#275175',
                // neutralTertiaryAlt: '#3f688c',
                // neutralTertiary: '#c8c8c8',
                // neutralSecondary: basePalette.neutralSecondary, //headerIsDark ? '#f00' : '#363636',
                // neutralPrimaryAlt: '#dadada',
                // neutralPrimary: headerIsDark ? '#fff' : '#000',
                // neutralDark: '#363636',
                // black: '#f8f8f8',
                themePrimary: headerIsDark
                    ? basePalette.themePrimary
                    : '#363636',
                white: headerBackgroundColourHex,
            },
            isInverted: headerIsDark,
        });
    }, [
        lightTheme,
        darkTheme,
        headerIsDark,
        headerBackgroundColourHex,
        themePrimaryColourHex,
    ]);

    const checkAccessibility = (
        primaryColourHex: string
    ): {
        lightIsAccessible: boolean;
        darkIsAccessible: boolean;
    } | null => {
        const lightThemeToCheck = getLightTheme(primaryColourHex);
        const darkThemeToCheck = generateDarkTheme(lightThemeToCheck);

        const lightBgc = getColorFromString(lightThemeToCheck.palette.accent);

        const lightFgc = getColorFromString(lightThemeToCheck.palette.white);

        const darkBgc = getColorFromString(darkThemeToCheck.palette.accent);

        const darkFgc = getColorFromString(darkThemeToCheck.palette.white);

        if (!lightBgc || !lightFgc || !darkBgc || !darkFgc) {
            return null;
        }
        return {
            lightIsAccessible: getContrastRatio(lightBgc, lightFgc) >= 4.5,
            darkIsAccessible: getContrastRatio(darkBgc, darkFgc) >= 4.5,
        };
    };

    const getContrastColourHex = useCallback(
        (backgroundColourHex: string, colourOptions: string[]): string => {
            let highestContrast = 0;
            let highestContrastHex = '#000';

            const bgColour = getColorFromString(backgroundColourHex);

            for (const c of colourOptions) {
                const colour = getColorFromString(c);
                if (colour && bgColour) {
                    // Ideally we need > 4.5
                    const contrast = getContrastRatio(bgColour, colour);
                    if (contrast > highestContrast) {
                        highestContrast = contrast;
                        highestContrastHex = c;
                    }
                }
            }

            return highestContrastHex;
        },
        []
    );

    const buttonStyles = {
        mai: {
            root: {
                height: 24,
                //     marginBottom: 4,
                //     paddingRight: 8,
                //     borderRadius: 8,
                //     //border: 'none',
                //     //background: `linear-gradient(45deg, ${currentTheme.palette.themeDarkAlt} 30%, ${currentTheme.palette.themeSecondary} 90%)`,
                //     //boxShadow: '0 0 2px 1px rgba(50, 50, 50, .3)',
            },
            // icon: {
            //     color: currentTheme.palette.white,
            // },
            // iconHovered: {
            //     color: currentTheme.palette.white,
            // },
            // iconPressed: {
            //     color: currentTheme.palette.white,
            // },
            // label: {
            //     marginLeft: 0,
            //     color: currentTheme.palette.white,
            // },
        },
    };

    return {
        currentTheme,
        lightTheme,
        darkTheme,
        headerTheme,
        changeTheme,
        currentThemeName: themeName,
        checkAccessibility,
        getContrastColourHex,
        buttonStyles,
        logoTheme,
    };
};
