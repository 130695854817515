import React from 'react';
import { Text, Link, DayOfWeek, Shimmer } from '@fluentui/react';
import dayjs from 'dayjs';
import { Access } from '../data/extendedTypes';
import { useTaskEditRules } from '../hooks/useTaskEditRules';
import { DetailsListCellItemContainer } from './shared/DetailsListCellItemContainer';
import { DatePickerStrings } from '../services/i18n';
import { InlineDatePicker } from './inputs/InlineDatePicker';

export function ImpliedTaskListDateColumn(props: {
    task: {
        start: string | null;
        due: string | null;
        done: string | null;
        isDuplicate: boolean;
        missionId: string | null;
        parentTaskId: string | null;
        resourcedFromTaskId: string | null;
        utcAccepted: string | null;
        utcPostponed: string | null;
        utcCancelled: string | null;
        utcAtRisk: string | null;
        isPercentageCompleteFromResources: boolean;
        isPercentageCompleteFromSubTasks: boolean;
        resource: {
            userId: string | null;
            userContributorFYs: { code: string | null }[];
        } | null;
        tags: {
            id: string | null;
            name: string | null;
        }[];
        resourcedFromTask: {
            mission: {
                userId: string | null;
                rights: {
                    write: boolean;
                };
            } | null;
        } | null;
    };
    dateType: 'start' | 'due' | 'done';
    missionAccess: Access | undefined;
    allowInlineEdit?: boolean;
    onTaskEditClick: () => void;
    onDateChanged?: (date: string | null) => void;
    isBusy?: boolean;
}): JSX.Element {
    const { task, dateType, missionAccess } = props;

    const taskEditRules = useTaskEditRules(task, missionAccess);

    let isReadOnly: boolean;
    let dateValue: string | null;

    switch (dateType) {
        case 'start':
            isReadOnly = taskEditRules.isStartDateReadOnly;
            dateValue = task.start;
            break;
        case 'due':
            isReadOnly = taskEditRules.isDueDateReadOnly;
            dateValue = task.due;
            break;
        case 'done':
            isReadOnly = taskEditRules.isDoneDateReadOnly;
            dateValue = task.done;
            break;
    }

    const dateText = (
        <Text variant="small">
            {dateValue ? dayjs.utc(dateValue).format('DD MMM YYYY') : ' - '}
        </Text>
    );

    return (
        <DetailsListCellItemContainer>
            <Shimmer isDataLoaded={!props.isBusy} width={80} height={16}>
                {!isReadOnly && props.allowInlineEdit && (
                    <InlineDatePicker
                        formatDate={(date?: Date | null): string => {
                            return dayjs
                                .utc(date || undefined)
                                .tz(dayjs.tz.guess())
                                .format('DD MMM YYYY');
                        }}
                        strings={DatePickerStrings}
                        ariaLabel="Select a date"
                        firstDayOfWeek={DayOfWeek.Monday}
                        value={
                            dateValue ? dayjs(dateValue).toDate() : undefined
                        }
                        placeholder="Enter Date"
                        minDate={
                            dateType === 'due' && task.start
                                ? dayjs(task.start).toDate()
                                : undefined
                        }
                        onSelectDate={(date: Date | null | undefined): void => {
                            const dateString = date
                                ? dayjs(date).format('YYYY-MM-DD')
                                : null;

                            if (props.onDateChanged) {
                                props.onDateChanged(dateString);
                            }
                        }}
                    />
                )}

                {!isReadOnly && !props.allowInlineEdit && (
                    <Link onClick={props.onTaskEditClick}>{dateText}</Link>
                )}

                {isReadOnly && dateText}
            </Shimmer>
        </DetailsListCellItemContainer>
    );
}
