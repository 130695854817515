import React, { PropsWithChildren } from 'react';
import { mergeStyleSets, TooltipHost } from '@fluentui/react';

import { useUtcDates } from '../hooks/useUtcDates';
import { useLanguage } from '../services/i18n';
import { useThemes } from '../hooks/useThemes';

type CommentTooltipHostProps = {
    content?: string | null;
    authorName?: string | null;
    dateTime?: string;
};

export function CommentTooltipHost({
    content,
    authorName,
    dateTime,
    children,
}: PropsWithChildren<CommentTooltipHostProps>): JSX.Element {
    const { fromNow } = useUtcDates();
    const { t } = useLanguage();
    const { currentTheme } = useThemes();

    const timeStamp = dateTime ? fromNow(dateTime) : '\u00A0';

    const classNames = mergeStyleSets({
        toolTipCommentText: {
            margin: 0,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 1,
            paddingBottom: 1,
            borderLeft: `solid 4px ${currentTheme.palette.themeLight}`,
            fontStyle: 'italic',
            whiteSpace: 'pre-line',
        },
        toolTipCommentAuthor: {
            marginTop: 8,
        },
    });

    const tooltipContent = content ? (
        <>
            <blockquote className={classNames.toolTipCommentText}>
                {content?.trim()}
            </blockquote>
            <div className={classNames.toolTipCommentAuthor}>
                {authorName}, {timeStamp}
            </div>
        </>
    ) : (
        t('general.add-comment')
    );

    return <TooltipHost content={tooltipContent}>{children}</TooltipHost>;
}
