import React, { FormEvent, useState } from 'react';
import { Guid } from 'guid-typescript';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { ActionButton, IconButton, Stack, TextField } from '@fluentui/react';
import { sorters } from '../data/sorters';
import { DragHandlerButton } from './DragHandlerButton';
import { SortableContainer } from './SortableContainer';
import Loading from './Loading';

export type SortableTextInputItem = {
    id: string;
    name: string;
    sequence: number;
    isNew: boolean;
    isDeleted: boolean;
    version: string | null;
};

export type SortableTextInputListProps = {
    inputItems: SortableTextInputItem[];
    onChange: (inputItem: SortableTextInputItem[]) => void;
    isLoading: boolean;
    addButtonText?: string;
    isReadOnly: boolean;
    multiline?: boolean;
};

export function SortableTextInputList(props: SortableTextInputListProps) {
    const { inputItems: items } = props;

    const [activeDragItemId, setActiveDragItemId] = useState<string | null>();

    const handleNameChanged = (itemId: string, newValue: string): void => {
        const item = items?.find((g) => g.id === itemId);
        const updated = item
            ? [
                  {
                      ...item,
                      name: newValue,
                  },
                  ...(items?.filter((g) => g.id !== itemId) || []),
              ]
            : items;

        props.onChange(updated);
    };

    const handleDeleteClick = (itemId: string, isRestore: boolean): void => {
        const item = items?.find((g) => g.id === itemId);
        const updated = item
            ? [
                  {
                      ...item,
                      isDeleted: !isRestore,
                  },
                  ...(items?.filter((g) => g.id !== itemId) || []),
              ]
            : items;

        props.onChange(updated);
    };

    const handleDragging = (id: string | null) => setActiveDragItemId(id);

    const handleDropped = (id: string | null, newIndex: number) => {
        if (!id) {
            return;
        }

        const sorted = (items || []).slice().sort(sorters.sequenceSorter);

        const movedItem = sorted.find((m) => m.id === id);
        const remainingItems = sorted.filter((f) => f.id !== id);

        const reorderedItems = movedItem
            ? [
                  ...remainingItems.slice(0, newIndex),
                  movedItem,
                  ...remainingItems.slice(newIndex),
              ]
            : remainingItems;

        let sequence = 0;
        const updated = reorderedItems.map((g) => {
            return {
                ...g,
                sequence: sequence++,
            };
        });

        props.onChange(updated);
    };

    const handleAddButtonClick = () => {
        const updated = [
            ...(items || []),
            {
                id: Guid.create().toString(),
                name: '',
                isDeleted: false,
                isNew: true,
                version: '',
                sequence:
                    (items || []).length > 0
                        ? Math.max(...(items || []).map((g) => g.sequence)) + 1
                        : 0,
            },
        ];

        props.onChange(updated);
    };

    const sortedItems = items?.sort(sorters.sequenceSorter);

    return (
        <SortableContainer
            ids={sortedItems?.map((g) => g.id) || []}
            onDragging={handleDragging}
            onDropped={handleDropped}
        >
            <Stack
                tokens={{ childrenGap: 8 }}
                styles={{ root: { marginTop: 8 } }}
            >
                {props.isLoading && <Loading />}

                {sortedItems?.map((g) => (
                    <Stack.Item key={g.id}>
                        <SortableItem
                            isReadOnly={props.isReadOnly}
                            multiline={props.multiline}
                            item={g}
                            isActive={activeDragItemId === g.id}
                            onDeleteClick={() => handleDeleteClick(g.id, false)}
                            onRestoreClick={() => handleDeleteClick(g.id, true)}
                            onNameChanged={(newValue: string) => {
                                handleNameChanged(g.id, newValue);
                            }}
                        />
                    </Stack.Item>
                ))}
                {!props.isLoading && !props.isReadOnly && (
                    <Stack.Item>
                        <ActionButton
                            text={props.addButtonText || 'Add Item'}
                            onClick={handleAddButtonClick}
                            iconProps={{ iconName: 'Add' }}
                        />
                    </Stack.Item>
                )}
            </Stack>
        </SortableContainer>
    );
}

const DragHandlerMemo = React.memo(DragHandlerButton);

export function SortableItem(props: {
    isActive: boolean;
    isReadOnly: boolean;
    multiline?: boolean;
    onNameChanged: (newValue: string) => void;
    onDeleteClick: () => void;
    onRestoreClick: () => void;
    item: {
        id: string;
        name: string | null;
        sequence: number;
        isNew: boolean;
        isDeleted: boolean;
    };
}): JSX.Element {
    const { item, isReadOnly } = props;

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.item.id || '' });

    const handleNameChanged = (
        _ev: FormEvent,
        newValue?: string | undefined
    ) => {
        props.onNameChanged(newValue || '');
    };

    return (
        <div
            ref={setNodeRef}
            style={{
                transformOrigin: '0 0',
                opacity: props.isActive ? 0.4 : 1,
                transform: CSS.Translate.toString(transform),
                transition: transition || undefined,
            }}
        >
            <Stack horizontal>
                <Stack.Item>
                    <DragHandlerMemo
                        hidden={isReadOnly}
                        handleListeners={listeners}
                        handleAttributes={attributes}
                        iconName="GripperDotsVertical"
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        defaultValue={item.name || ''}
                        onChange={handleNameChanged}
                        readOnly={isReadOnly}
                        disabled={item.isDeleted}
                        multiline={props.multiline}
                        autoAdjustHeight={props.multiline}
                    />
                </Stack.Item>
                <Stack.Item>
                    {!item.isDeleted && !isReadOnly && (
                        <IconButton
                            title="Delete"
                            iconProps={{
                                iconName: 'Delete',
                            }}
                            onClick={props.onDeleteClick}
                        />
                    )}
                    {item.isDeleted && !isReadOnly && (
                        <IconButton
                            title="Restore"
                            iconProps={{ iconName: 'Undo' }}
                            onClick={props.onRestoreClick}
                        />
                    )}
                </Stack.Item>
            </Stack>
        </div>
    );
}
