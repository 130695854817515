import React, { useCallback, useEffect, useState } from 'react';
import {
    IRawStyle,
    Label,
    mergeStyleSets,
    PanelType,
    Text,
} from '@fluentui/react';
import {
    ChartDisplay,
    ChartType,
    FrequencyPeriods,
    GetMeasureQuery,
    Measure,
    MeasureTypes,
    Multipliers,
    PhaseType,
    refetchGetMissionMeasuresQuery,
    StatusTypes,
    TargetTypes,
    useGetMeasureLazyQuery,
    useGetMissionMeasuresQuery,
    useUpdateMeasureMutation,
    ValueTypes,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import MeasureGroupDropdown from '../../../components/MeasureGroupDropdown';
import { InputShimmer } from '../../../components/inputs';
import { EditPanel } from '../../../components/shared/EditPanel';
import { MeasurePicker } from '../../../components/MeasurePicker';
import MeasureCard from '../../../components/MeasureCard';

export default function MeasureAddLinkedPanel(props: {
    missionId: string;
    fyStartDate: string | undefined;
    fyEndDate: string | undefined;
    oneUpMissionId: string | null;
    showPanel: boolean;
    onCancel: () => void;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [measure, setMeasure] = useState<Measure>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [hasSaved, setHasSaved] = useState<boolean>(false);

    const [measureCardPreviewMeasure, setMeasureCardPreviewMeasure] =
        useState<GetMeasureQuery['measure']>();

    const { loading, data } = useGetMissionMeasuresQuery({
        skip: !props.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId || '',
        },
    });

    const [loadMeasure] = useGetMeasureLazyQuery();

    useEffect(() => {
        if (props.showPanel && props.missionId) {
            setHasSaved(false);
            setMeasure({
                id: null,
                missionId: props.missionId || '',
                measureGroupId: null,
                name: '',
                description: '',
                measureType: MeasureTypes.Numeric,
                phaseType: PhaseType.PeriodEnd,
                currency: null,
                multiplier: Multipliers.None,
                decimalPlaces: 0,
                statusType: StatusTypes.MoreThanTarget,
                yellowStart: 0.9,
                greenRange: 0,
                yellowRange: 0,
                isStatusLimited: false,
                sequence: 0, // New measure sequence
                version: '',
                frequencyNumber: 1,
                frequencyPeriod: FrequencyPeriods.Month,
                isLinked: false,
                linkedFromMeasureId: null,
                tags: [],

                previousFYMeasureId: null,
                fullYearTarget: null,
                fullYearString: null,
                isFullYearTarget: true,
                chartDisplay: ChartDisplay.ByFrequency,
                chartType: ChartType.Default,
                showForecast: false,
                showFutureLook: false,
                isCustom: true,
                targetType: TargetTypes.FullYear,
                valueType: ValueTypes.Simple,
                valueFormula: null,
            });
        } else {
            setMeasure(undefined);
        }
    }, [props]);

    const loadPreview = useCallback(
        async (previewMeasureId: string | null) => {
            if (previewMeasureId) {
                const linkedFromQuery = await loadMeasure({
                    variables: {
                        id: previewMeasureId,
                        tenantId: currentTenantId || '',
                    },
                });
                setMeasureCardPreviewMeasure(
                    linkedFromQuery.data?.measure || undefined
                );
            } else {
                setMeasureCardPreviewMeasure(null);
            }
        },
        [currentTenantId, loadMeasure]
    );

    useEffect(() => {
        if (measure?.linkedFromMeasureId) {
            loadPreview(measure?.linkedFromMeasureId);
        } else {
            loadPreview(null);
        }
    }, [measure?.linkedFromMeasureId, loadPreview]);

    const copyDefinitionFromAsync = async (
        linkedFromMeasureId: string,
        targetMeasure: Measure
    ) => {
        // This is done server side, but doesn't include the name
        const linkedFromQuery = await loadMeasure({
            variables: {
                id: linkedFromMeasureId,
                tenantId: currentTenantId || '',
            },
        });

        const linkedFrom = linkedFromQuery.data?.measure;

        if (linkedFrom) {
            targetMeasure.name = linkedFrom.name;
            targetMeasure.description = linkedFrom.description;
            targetMeasure.measureType = linkedFrom.measureType;
            targetMeasure.phaseType = linkedFrom.phaseType;
            targetMeasure.multiplier = linkedFrom.multiplier;
            targetMeasure.decimalPlaces = linkedFrom.decimalPlaces;
            targetMeasure.currency = linkedFrom.currency;
            targetMeasure.statusType = linkedFrom.statusType;
            targetMeasure.yellowStart = linkedFrom.yellowStart;
            targetMeasure.greenRange = linkedFrom.greenRange;
            targetMeasure.yellowRange = linkedFrom.yellowRange;
            targetMeasure.frequencyNumber = linkedFrom.frequencyNumber;
            targetMeasure.frequencyPeriod = linkedFrom.frequencyPeriod;
        }

        if (linkedFrom?.isLinked && targetMeasure) {
            targetMeasure.linkedFromMeasureId = linkedFrom.linkedFromMeasureId;
        }
    };

    const [updateMeasure, { error: saveError }] = useUpdateMeasureMutation();

    const handleSave = async () => {
        if (!measure) {
            return;
        }

        const measureToSave: Measure = { ...measure };

        setHasSaved(false);
        setIsSaving(true);

        if (measureToSave.linkedFromMeasureId) {
            await copyDefinitionFromAsync(
                measureToSave?.linkedFromMeasureId,
                measureToSave
            );
        }

        await updateMeasure({
            variables: {
                tenantId: currentTenantId || '',
                input: measureToSave,
            },
            refetchQueries: [
                refetchGetMissionMeasuresQuery({
                    tenantId: currentTenantId || '',
                    missionId: props.missionId,
                }),
            ],
        });

        setIsSaving(false);
        setHasSaved(true);

        await new Promise((res) => setTimeout(res, 1000));

        props.onCancel();
    };

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        } as IRawStyle,
    });

    const excludeMeasureIds =
        data?.measures?.map(
            (m) => (m.isLinked ? m.linkedFromMeasureId : m.id) || ''
        ) || [];

    const isValid = !!measure?.isLinked && !!measure.linkedFromMeasureId;

    return (
        <EditPanel
            showPanel={props.showPanel}
            headerText="New linked measure of success"
            onDismiss={props.onCancel}
            saveErrorMessage={saveError?.message}
            isSaving={isSaving}
            hasSaved={hasSaved}
            isValid={isValid}
            activeViewName={null}
            onUpdateClick={handleSave}
            panelType={PanelType.medium}
        >
            <div className={classNames.container}>
                <Text block variant="small">
                    Create a linked replica measure of success in this mission.
                </Text>

                <div>
                    <InputShimmer isDataLoaded={!loading}>
                        <Label>Find a measure of success</Label>
                        <MeasurePicker
                            excludeMeasureIds={excludeMeasureIds}
                            suggestedMeasuresMissionId={props.oneUpMissionId}
                            onMeasureSelected={(measureId) => {
                                setMeasure((m) => {
                                    if (m) {
                                        return {
                                            ...m,
                                            linkedFromMeasureId: measureId,
                                            isLinked: !!measureId,
                                        };
                                    }
                                });
                            }}
                        />
                    </InputShimmer>
                </div>

                {!!measureCardPreviewMeasure && (
                    <MeasureCard
                        missionAccess={{
                            read: false,
                            write: false,
                            export: false,
                            import: false,
                        }}
                        {...measureCardPreviewMeasure.lastAsOf}
                        {...measureCardPreviewMeasure}
                        id={measureCardPreviewMeasure?.id || ''}
                        name={measureCardPreviewMeasure?.name || ''}
                        description={
                            measureCardPreviewMeasure?.description || ''
                        }
                        linkedFromMeasure={
                            measureCardPreviewMeasure.linkedFromMeasure
                        }
                        isCompact
                        fyStartDate={props.fyStartDate}
                        fyEndDate={props.fyEndDate}
                    />
                )}

                <Text block variant="small">
                    Any changes to the above primary measure of success will be
                    reflected in this mission.
                </Text>

                <MeasureGroupDropdown
                    missionId={props.missionId}
                    tenantId={currentTenantId || ''}
                    onChange={(measureGroupId: string | null): void => {
                        setMeasure((m) => {
                            if (m) {
                                return {
                                    ...m,
                                    measureGroupId: measureGroupId,
                                };
                            }
                        });
                    }}
                    label="Group"
                />
            </div>
        </EditPanel>
    );
}
