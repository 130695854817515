import React from 'react';
import {
    getFocusStyle,
    Link,
    List,
    mergeStyleSets,
    Panel,
    PanelType,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import {
    useGetNotificationsQuery,
    GetNotificationsQuery,
    GetMissionNavQuery,
    GetMissionNavQueryVariables,
    GetMissionNavDocument,
    useDismissNotificationMutation,
    useDismissAllNotificationsMutation,
    GetContributorQuery,
    GetContributorQueryVariables,
    GetContributorDocument,
} from '../../data/types';
import { useStateContext } from '../../services/contextProvider';
import { useApolloClient } from '@apollo/client';
import { ExtractQueryArrayType } from '../../data/extendedTypes';
import { navigation, PathParams } from '../../services/navigation';
import Loading from '../Loading';
import { useActiveView } from '../../hooks/useActiveView';
import { NotificationItem } from './NotificationItem';

type NotificationsPanelProps = {
    showPanel: boolean;
    onDismiss: () => void;
};

type Notification = {
    notification: ExtractQueryArrayType<
        GetNotificationsQuery,
        ['notifications']
    >;
    onClick: () => void;
};

export default function NotificationsPanel(
    props: NotificationsPanelProps
): JSX.Element {
    const currentTheme = useTheme();

    const { semanticColors, palette } = currentTheme;

    const navigate = useNavigate();

    const {
        currentTenantCode,
        currentTenantId,
        currentUserId,
        currentFinancialYearCode,
    } = useStateContext();

    useActiveView(props.showPanel ? 'Notifications' : null);

    const { data, loading } = useGetNotificationsQuery({
        fetchPolicy: 'network-only',
        skip: !currentUserId || !currentTenantId || !props.showPanel,
        variables: {
            tenantId: currentTenantId || '',
            userId: currentUserId || '',
        },
    });

    const [dismissNotificationMutation] = useDismissNotificationMutation();

    const [dismissAllNotificationsMutation, { loading: isDismissingAll }] =
        useDismissAllNotificationsMutation({
            variables: {
                tenantId: currentTenantId || '',
                userId: currentUserId || '',
            },
        });

    const client = useApolloClient();

    const classNames = mergeStyleSets({
        itemCell: [
            getFocusStyle(currentTheme, { inset: -1 }),
            {
                cursor: 'pointer',
                minHeight: 54,
                paddingTop: 16,
                paddingBottom: 16,
                boxSizing: 'border-box',
                borderTop: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',
                selectors: {
                    '&:hover': { background: palette.neutralLighter },
                },
            },
        ],
        list: {
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
        },
    });

    const navigateToEvent = async (
        notificationEvent: ExtractQueryArrayType<
            GetNotificationsQuery,
            ['notifications', 'notificationEvent']
        > | null
    ) => {
        const hasMission = notificationEvent?.missionId;
        const hasContributor =
            notificationEvent?.task?.resource?.userContributorFYs.some(
                (fy) =>
                    fy.code?.toUpperCase() ===
                    currentFinancialYearCode?.toUpperCase()
            );

        if (
            !notificationEvent ||
            (!hasMission && !hasContributor) ||
            !currentTenantId
        ) {
            return;
        }

        if (
            !!notificationEvent.measure &&
            notificationEvent.measure.utcDeleted
        ) {
            return;
        }

        if (!!notificationEvent.task && notificationEvent.task.utcDeleted) {
            return;
        }

        let teamCode: string | undefined = undefined;
        let financialYearCode = currentFinancialYearCode;
        let contributorId: string | undefined = undefined;

        if (hasMission && notificationEvent?.missionId) {
            const missionData = await client.query<
                GetMissionNavQuery,
                GetMissionNavQueryVariables
            >({
                query: GetMissionNavDocument,
                variables: {
                    tenantId: currentTenantId,
                    missionId: notificationEvent?.missionId,
                },
            });

            const mission = missionData.data.mission;

            const team = mission?.team
                ? mission?.team
                : mission?.leaderOfTeams && mission?.leaderOfTeams.length > 0
                ? mission?.leaderOfTeams[0]
                : null;

            teamCode = team?.code || undefined;

            if (team?.division?.financialYear?.code) {
                financialYearCode = team?.division?.financialYear?.code;
            }
        } else if (
            hasContributor &&
            notificationEvent?.task?.resource?.userId &&
            financialYearCode
        ) {
            const contributorData = await client.query<
                GetContributorQuery,
                GetContributorQueryVariables
            >({
                query: GetContributorDocument,
                variables: {
                    tenantId: currentTenantId,
                    userId: notificationEvent?.task?.resource?.userId,
                    financialYearCode: financialYearCode,
                    contributorId: null,
                },
            });

            contributorId = contributorData.data.contributor?.id || undefined;
            teamCode =
                contributorData.data.contributor?.team?.code || undefined;
        }

        const pathParams: PathParams = {
            tenantCode: currentTenantCode,
            missionId: notificationEvent.missionId || undefined,
            measureId: notificationEvent.measureId || undefined,
            statusReportId: notificationEvent.statusReportId || undefined,
            templateReportId: notificationEvent.templateReportId || undefined,
            specifiedTaskId: notificationEvent.missionId
                ? notificationEvent.taskId || undefined
                : undefined,
            taskId: notificationEvent.taskId || undefined,
            contributorId: contributorId,
            financialYearCode: financialYearCode,
            teamCode: teamCode,
        };

        // Find if the user has this as a linked measure.
        const linkedMeasure = notificationEvent.measure?.linkedMeasures.find(
            (m) => m.isLinked && m.mission?.userId === currentUserId
        );

        // Navigate to this one instead.
        if (linkedMeasure) {
            pathParams.teamCode =
                linkedMeasure?.mission?.team?.code || undefined;
            pathParams.missionId = linkedMeasure.mission?.id || undefined;
            pathParams.measureId = linkedMeasure.id || undefined;
        }

        const href = navigation.getPathForParams(pathParams);

        navigate(href);
    };

    const notifications: Notification[] = (data?.notifications || []).map(
        (n) => {
            const handleClick = async () => {
                if (n.id && currentTenantId) {
                    await dismissNotificationMutation({
                        variables: {
                            tenantId: currentTenantId,
                            notificationId: n.id,
                        },
                    });
                }
                await navigateToEvent(n.notificationEvent);
                props.onDismiss();
            };
            return {
                notification: n,
                onClick: handleClick,
            };
        }
    );

    const onRenderCell = (item?: Notification): JSX.Element | null => {
        return item ? (
            <div className={classNames.itemCell}>
                <NotificationItem {...item} />
            </div>
        ) : null;
    };

    const hasUndismissed = notifications.some(
        (n) => !n.notification.utcDismissed
    );

    const handleDismissAllClick = async () => {
        await dismissAllNotificationsMutation();
    };

    return (
        <Panel
            isOpen={props.showPanel}
            type={PanelType.smallFixedFar}
            onDismiss={props.onDismiss}
            headerText="Notifications"
            closeButtonAriaLabel="Close"
            isFooterAtBottom={true}
        >
            <Stack
                tokens={{ childrenGap: 16 }}
                styles={{
                    root: {
                        paddingTop: 16,
                    },
                }}
            >
                {loading && notifications.length === 0 && <Loading />}

                {!loading && notifications.length === 0 && (
                    <Stack.Item>
                        <Text
                            variant="mediumPlus"
                            styles={{
                                root: { fontStyle: 'italic' },
                            }}
                        >
                            No notifications
                        </Text>
                    </Stack.Item>
                )}

                {notifications?.length > 0 && (
                    <Stack.Item align="end">
                        <Text variant="small">
                            <Link
                                onClick={handleDismissAllClick}
                                disabled={!hasUndismissed || isDismissingAll}
                            >
                                Mark all as read
                            </Link>
                        </Text>
                    </Stack.Item>
                )}

                {notifications?.length > 0 && (
                    <Stack.Item>
                        <List
                            items={notifications}
                            onRenderCell={onRenderCell}
                            className={classNames.list}
                        />
                    </Stack.Item>
                )}
            </Stack>
        </Panel>
    );
}
