import React, { useCallback, useMemo } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import {
    IBreadcrumbItem,
    IContextualMenuItem,
    ContextualMenuItemType,
} from '@fluentui/react';
import {
    useGetBreadcrumbsQuery,
    useGetFinancialYearsQuery,
} from '../../data/types';
import { navigation, paths } from '../../services/navigation';
import { useDispatchContext } from '../../services/contextProvider';
import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import { AdvanceBreadcrumb } from './Breadcrumbs';

export function useBreadcrumbItems(location: {
    tenantId: string;
    financialYearCode: string;
    tenantCode: string;
    tenantName: string;
    teamCode: string | undefined;
    missionId: string | undefined;
}): { items: AdvanceBreadcrumb[]; loading: boolean } {
    const {
        tenantId,
        financialYearCode,
        tenantCode,
        tenantName,
        teamCode,
        missionId,
    } = location;

    const navigate = useNavigate();
    const dispatch = useDispatchContext();

    const updateContext = useCallback(
        (context: {
            tenantCode: string;
            teamCode: string | null | undefined;
            themePrimaryColourHex: string | null | undefined;
            missionId?: string | null | undefined;
        }): void => {
            dispatch({
                type: 'SetCurrentTeamAndMission',
                payload: {
                    currentTenantCode: context.tenantCode,
                    currentTeamCode: context.teamCode,
                    currentMissionId: context.missionId,
                    themePrimaryColourHex: context.themePrimaryColourHex,
                },
            });
        },
        [dispatch]
    );

    const { loading, data } = useGetBreadcrumbsQuery({
        skip: !teamCode || !tenantId || !financialYearCode,
        variables: {
            tenantId: tenantId,
            financialYearCode: financialYearCode,
            teamCode: teamCode || '',
        },
    });

    const { loading: fyLoading, data: fyData } = useGetFinancialYearsQuery({
        skip: !tenantId,
        variables: {
            tenantId: tenantId,
        },
    });

    const sortedFys = useMemo(
        () =>
            orderBy(
                filter(fyData?.financialYears, (fy) => !fy.utcInactive),
                ['startDate', 'code']
            ),
        [fyData?.financialYears]
    );

    let currentMissionItem: IBreadcrumbItem | null = null;

    const getContributorMenuItem = useCallback(
        (
            contributor: {
                id: string | null;
                userDisplayName: string | null;
            },
            teamCode: string | null | undefined,
            primaryColourHex: string | null | undefined
        ): IContextualMenuItem => {
            return {
                key: 'subMenu_con' + contributor.id,
                text: contributor.userDisplayName || contributor.id || '',
                secondaryText: 'Contributor',
                iconProps: {
                    iconName: 'Contact',
                },
                href: generatePath(paths.contributorWithTeam, {
                    tenantCode: tenantCode?.toLowerCase(),
                    financialYearCode: financialYearCode?.toLowerCase(),
                    teamCode: teamCode?.toLowerCase() || '',
                    contributorId: contributor.id,
                }),
                onClick: (
                    ev?:
                        | React.MouseEvent<HTMLElement>
                        | React.KeyboardEvent<HTMLElement>,
                    item?: IContextualMenuItem
                ): boolean => {
                    if (item && item.href) {
                        updateContext({
                            tenantCode: tenantCode,
                            teamCode: teamCode,
                            themePrimaryColourHex: primaryColourHex,
                            missionId: null,
                        });
                        navigate(item.href);
                    }
                    // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                    if (ev) {
                        ev.preventDefault();
                        ev.defaultPrevented = false;
                    }
                    return true;
                },
            };
        },
        [tenantCode, financialYearCode, navigate, updateContext]
    );

    if (loading || fyLoading) {
        return {
            items: [],
            loading: true,
        };
    }

    const items: AdvanceBreadcrumb[] = (data?.breadcrumbs || []).map(
        (breadcrumb) => {
            if (!breadcrumb) {
                throw new Error('NULL breadcrumb found.');
            }

            let teamMissions: {
                id: string | null;
                sequence: number;
                title: string | null;
                owner: string | null;
                ownTeam: {
                    code: string | null;
                    name: string | null;
                    division: {
                        id: string | null;
                        primaryColourHex: string | null;
                    } | null;
                    dottedMissions: {
                        id: string | null;
                        sequence: number;
                        mission: {
                            id: string | null;
                            title: string | null;
                            owner: string | null;
                        } | null;
                    }[];
                    missions:
                        | {
                              id: string | null;
                              title: string | null;
                              owner: string | null;
                              sequence: number;
                          }[]
                        | null;
                    contributors: {
                        id: string | null;
                        userDisplayName: string | null;
                        userActive: boolean;
                    }[];
                } | null;
            }[] = [];

            breadcrumb?.missions.forEach((m) => {
                const ownTeam =
                    m.leaderOfTeams && m.leaderOfTeams.length > 0
                        ? m.leaderOfTeams[0]
                        : null;

                teamMissions.push({
                    ...m,
                    ownTeam: ownTeam,
                });
            });

            breadcrumb?.dottedMissions.forEach((dm) => {
                if (dm.mission) {
                    teamMissions.push({
                        ...dm.mission,
                        sequence: dm.sequence,
                        ownTeam: null,
                    });
                }
            });

            // filter out the leader mission here as it will be added later.
            teamMissions = teamMissions.filter(
                (mission) =>
                    mission &&
                    (!breadcrumb.leaderMission ||
                        mission.id !== breadcrumb.leaderMission.id)
            );

            teamMissions = orderBy(teamMissions, 'sequence');

            const menuItems: IContextualMenuItem[] = teamMissions.map(
                (mission) => {
                    if (!mission) {
                        throw new Error('NULL mission found.');
                    }

                    if (missionId && mission.id === missionId) {
                        currentMissionItem = {
                            key: 'menu' + mission.id,
                            text: mission.owner || '',
                            isCurrentItem: true,
                        };
                    }

                    const currentTeamCode = mission.ownTeam
                        ? mission.ownTeam.code
                        : breadcrumb.code
                          ? breadcrumb.code
                          : teamCode;

                    const division = mission.ownTeam
                        ? mission.ownTeam.division
                        : breadcrumb.division;

                    const subMissions =
                        mission.ownTeam?.missions
                            ?.map((m) => {
                                return {
                                    id: m.id,
                                    sequence: m.sequence,
                                    title: m.title,
                                    owner: m.owner,
                                };
                            })
                            .concat(
                                mission.ownTeam.dottedMissions.map((dm) => {
                                    return {
                                        sequence: dm.sequence,
                                        id: dm.mission?.id || null,
                                        title: dm.mission?.title || null,
                                        owner: dm.mission?.owner || null,
                                    };
                                })
                            ) || [];

                    const subMenuItems: IContextualMenuItem[] = orderBy(
                        subMissions,
                        'sequence'
                    ).map((m) => {
                        return (m && {
                            key: 'subMenu' + m.id,
                            text: m.owner,
                            secondaryText: m.title,
                            iconProps: {
                                iconName: 'Contact',
                            },
                            href: generatePath(paths.missionBuilder, {
                                tenantCode: tenantCode?.toLowerCase(),
                                financialYearCode:
                                    financialYearCode?.toLowerCase(),
                                teamCode:
                                    mission.ownTeam?.code?.toLowerCase() || '',
                                missionId: m.id || '',
                            }),
                            onClick: (
                                ev?:
                                    | React.MouseEvent<HTMLElement>
                                    | React.KeyboardEvent<HTMLElement>,
                                item?: IContextualMenuItem
                            ): boolean => {
                                if (item && item.href) {
                                    updateContext({
                                        tenantCode: tenantCode,

                                        teamCode: mission.ownTeam?.code,
                                        themePrimaryColourHex:
                                            mission.ownTeam?.division
                                                ?.primaryColourHex,
                                        missionId: m.id,
                                    });
                                    navigate(item.href);
                                }
                                // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                                if (ev) {
                                    ev.preventDefault();
                                    ev.defaultPrevented = false;
                                }
                                return true;
                            },
                        }) as IContextualMenuItem;
                    });

                    if (mission.ownTeam) {
                        // Add the primary mission
                        subMenuItems.unshift({
                            key: 'primary' + mission.id,
                            text: mission.owner,
                            secondaryText: mission.title,
                            iconProps: {
                                iconName: 'PartyLeader',
                            },
                            href: generatePath(paths.missionBuilder, {
                                tenantCode: tenantCode?.toLowerCase(),
                                financialYearCode:
                                    financialYearCode?.toLowerCase(),
                                teamCode:
                                    mission.ownTeam.code?.toLowerCase() || '',
                                missionId: mission.id || '',
                            }),
                            onClick: (
                                ev?:
                                    | React.MouseEvent<HTMLElement>
                                    | React.KeyboardEvent<HTMLElement>,
                                item?: IContextualMenuItem
                            ): boolean => {
                                if (item && item.href) {
                                    updateContext({
                                        tenantCode: tenantCode,

                                        teamCode: mission.ownTeam?.code,
                                        themePrimaryColourHex:
                                            mission.ownTeam?.division
                                                ?.primaryColourHex,
                                        missionId: mission.id,
                                    });
                                    navigate(item.href);
                                }

                                // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                                if (ev) {
                                    ev.preventDefault();
                                    ev.defaultPrevented = false;
                                }
                                return true;
                            },
                        } as IContextualMenuItem);

                        subMenuItems.unshift({
                            key: `viewTeamDivider${currentTeamCode}`,
                            itemType: ContextualMenuItemType.Divider,
                        });

                        // Add the view team link
                        subMenuItems.unshift({
                            key: `viewTeam${teamCode}`,
                            text: 'View Team',
                            iconProps: {
                                iconName: 'Group',
                            },
                            secondaryText: mission.ownTeam.name,
                            href: generatePath(paths.team, {
                                tenantCode: tenantCode?.toLowerCase(),
                                financialYearCode:
                                    financialYearCode?.toLowerCase(),
                                teamCode: currentTeamCode?.toLowerCase() || '',
                            }),
                            onClick: (
                                ev?:
                                    | React.MouseEvent<HTMLElement>
                                    | React.KeyboardEvent<HTMLElement>,
                                item?: IContextualMenuItem
                            ): boolean => {
                                if (item && item.href) {
                                    updateContext({
                                        tenantCode: tenantCode,
                                        teamCode: currentTeamCode,
                                        themePrimaryColourHex:
                                            division?.primaryColourHex,
                                    });
                                    navigate(item.href);
                                }
                                // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                                if (ev) {
                                    ev.preventDefault();
                                    ev.defaultPrevented = false;
                                }
                                return true;
                            },
                        } as IContextualMenuItem);
                    }

                    const contributorMenuItems: IContextualMenuItem[] =
                        mission.ownTeam?.contributors
                            .filter((c) => c.userActive)
                            .map((c) =>
                                getContributorMenuItem(
                                    c,
                                    currentTeamCode,
                                    division?.primaryColourHex
                                )
                            ) || [];

                    subMenuItems.push(...contributorMenuItems);

                    return {
                        key: `mission${mission.id}`,
                        text: mission.owner,
                        secondaryText:
                            subMenuItems.length === 0
                                ? mission.title
                                : undefined,
                        iconProps: {
                            iconName: 'Contact',
                        },
                        href: navigation.getPathForParams({
                            tenantCode: tenantCode,
                            financialYearCode: financialYearCode?.toLowerCase(),
                            teamCode: currentTeamCode?.toLowerCase(),
                            missionId: mission.id || undefined,
                        }),
                        subMenuProps: subMenuItems.length
                            ? { items: subMenuItems }
                            : undefined,
                        split: subMenuItems.length ? true : false,
                        onClick: (
                            ev?:
                                | React.MouseEvent<HTMLElement>
                                | React.KeyboardEvent<HTMLElement>,
                            item?: IContextualMenuItem
                        ): boolean => {
                            if (item && item.href) {
                                updateContext({
                                    tenantCode: tenantCode,
                                    themePrimaryColourHex:
                                        division?.primaryColourHex,
                                    teamCode: currentTeamCode,
                                    missionId: mission.id,
                                });
                                navigate(item.href);
                            }
                            // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                            if (ev) {
                                ev.preventDefault();
                                ev.defaultPrevented = false;
                            }
                            return true;
                        },
                    } as IContextualMenuItem;
                }
            );

            if (breadcrumb.leaderMission) {
                menuItems.unshift({
                    key: 'leader' + breadcrumb.leaderMission.id,
                    text: breadcrumb.leaderMission.owner || '',
                    secondaryText: breadcrumb.leaderMission.title || '',
                    iconProps: {
                        iconName: 'PartyLeader',
                    },
                    href: navigation.getPathForParams({
                        tenantCode: tenantCode,
                        financialYearCode: financialYearCode?.toLowerCase(),
                        teamCode: breadcrumb.code || undefined,
                        missionId: breadcrumb.leaderMission.id || undefined,
                    }),
                    onClick: (
                        ev?:
                            | React.MouseEvent<HTMLElement>
                            | React.KeyboardEvent<HTMLElement>,
                        item?: IContextualMenuItem
                    ): boolean => {
                        if (item && item.href) {
                            updateContext({
                                tenantCode: tenantCode,
                                teamCode: breadcrumb.code,
                                themePrimaryColourHex:
                                    breadcrumb.division?.primaryColourHex,
                                missionId: breadcrumb.leaderMission?.id,
                            });
                            navigate(item.href);
                        }

                        // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                        if (ev) {
                            ev.preventDefault();
                            ev.defaultPrevented = false;
                        }
                        return true;
                    },
                });

                menuItems.push(
                    ...(breadcrumb.contributors
                        .filter((c) => c.userActive)
                        .map((c) =>
                            getContributorMenuItem(
                                c,
                                breadcrumb.code,
                                breadcrumb.division?.primaryColourHex
                            )
                        ) || [])
                );

                menuItems.unshift({
                    key: `viewTeamDivider${breadcrumb.code}`,
                    itemType: ContextualMenuItemType.Divider,
                });

                // Add the view team link
                menuItems.unshift({
                    key: 'viewTeam' + breadcrumb.code,
                    text: 'View Team',
                    iconProps: {
                        iconName: 'Group',
                    },
                    secondaryText: breadcrumb.name || '',
                    href: generatePath(paths.team, {
                        tenantCode: tenantCode?.toLowerCase(),
                        financialYearCode: financialYearCode?.toLowerCase(),
                        teamCode: breadcrumb.code?.toLowerCase() || '',
                    }),
                    onClick: (
                        ev?:
                            | React.MouseEvent<HTMLElement>
                            | React.KeyboardEvent<HTMLElement>,
                        item?: IContextualMenuItem
                    ): boolean => {
                        if (item && item.href) {
                            updateContext({
                                tenantCode: tenantCode,
                                teamCode: breadcrumb.code,
                                themePrimaryColourHex:
                                    breadcrumb.division?.primaryColourHex,
                            });
                            navigate(item.href);
                        }
                        // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                        if (ev) {
                            ev.preventDefault();
                            ev.defaultPrevented = false;
                        }
                        return true;
                    },
                } as IContextualMenuItem);

                if (missionId && breadcrumb.leaderMission.id === missionId) {
                    currentMissionItem = {
                        key: 'currentleader' + breadcrumb.leaderMission.id,
                        text: breadcrumb.leaderMission.owner || '',
                        isCurrentItem: true,
                    };
                }
            }

            return {
                key: 'breadcrumb' + breadcrumb.id,
                text: breadcrumb.name || '',
                menuProps: {
                    items: menuItems,
                },
            } as AdvanceBreadcrumb;
        }
    );

    if (currentMissionItem) {
        items.push(currentMissionItem);
    }

    if (tenantCode && financialYearCode) {
        const fyItem = {
            key: 'financialYear',
            text: financialYearCode.toUpperCase(),
            menuProps: {
                items: sortedFys?.map((fy) => {
                    return {
                        key: `swapFy${fy.code?.toUpperCase()}`,
                        text: fy.code?.toLocaleUpperCase(),
                        secondaryText: `${dayjs(fy.startDate).format(
                            'MMM YYYY'
                        )} - ${dayjs(fy.endDate).format('MMM YYYY')}`,
                        href: navigation.getPathForParams({
                            tenantCode: tenantCode,
                            financialYearCode: fy.code?.toLowerCase(),
                        }),
                        onClick: (
                            ev?: React.MouseEvent | React.KeyboardEvent,
                            item?: IContextualMenuItem
                        ): boolean => {
                            dispatch({
                                type: 'SetupContext',
                                payload: {
                                    currentTenantCode: tenantCode,
                                    currentFinancialYearCode: fy.code || '',
                                    themePrimaryColourHex: null,
                                    currentTeamCode: null,
                                    currentMissionId: null,
                                },
                            });

                            if (item && item.href) {
                                navigate(item.href);
                            }
                            // Dimissied the menu item https://github.com/microsoft/fluentui/issues/13339
                            if (ev) {
                                ev.preventDefault();
                                ev.defaultPrevented = false;
                            }
                            return true;
                        },
                    } as IContextualMenuItem;
                }),
            },
        };

        items.unshift(fyItem);
    }

    if (tenantCode) {
        items.unshift({
            key: 'root',
            text: tenantName || tenantCode,
            menuProps: {
                items: [],
            },
        });
    }

    return {
        items: items,
        loading: false,
    };
}
