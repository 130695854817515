import React, { useState } from 'react';
import {
    Comment,
    GetCommentsQuery,
    useGetCommentsQuery,
    useLikeCommentToggleMutation,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { ExtractQueryArrayType } from '../data/extendedTypes';
import {
    MessageBar,
    MessageBarType,
    Stack,
    Text,
    IconButton,
    Persona,
    PersonaSize,
    mergeStyleSets,
    IRawStyle,
    IButtonProps,
    ActivityItem,
} from '@fluentui/react';

import Loading from './Loading';

import dayjs from 'dayjs';
import { photoService } from '../services/photo.service';
import { CommentDeleteModal } from './CommentDeleteModal';
import { useThemes } from '../hooks/useThemes';
import { CommentEditModal } from './CommentEditModal';

export type CommentsListProps = {
    missionId?: string | null | undefined;
    measureId?: string | null | undefined;
    taskId?: string | null | undefined;
    isOwnerOfCommentItem: boolean;
};

type CommentType = ExtractQueryArrayType<GetCommentsQuery, ['comments']>;

export function CommentsList(props: CommentsListProps): JSX.Element {
    const { currentTenantId, currentUserId, currentRoles } = useStateContext();

    const [confirmDeleteCommentId, setConfirmDeleteCommentId] = useState<
        string | null
    >(null);

    const [editingComment, setEditingComment] = useState<Comment | null>(null);

    const { data, loading, error } = useGetCommentsQuery({
        skip: !props.measureId && !props.taskId,
        fetchPolicy: 'cache-and-network',
        variables: {
            tenantId: currentTenantId || '',
            measureId: props.measureId || null,
            taskId: props.taskId || null,
        },
    });

    const [toggleLike] = useLikeCommentToggleMutation();

    if (error) {
        return (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={true}
            >
                <span>{error.message}</span>
            </MessageBar>
        );
    }

    if (loading || !data?.comments) {
        return <Loading />;
    }

    const commentSorter = (
        c1: { utcCreated: string },
        c2: { utcCreated: string }
    ): number => {
        if (c1.utcCreated === c2.utcCreated) {
            return 0;
        } else {
            return dayjs(c1.utcCreated).isBefore(c2.utcCreated) ? 1 : -1;
        }
    };

    const activityItems = data.comments
        .slice()
        .sort(commentSorter)
        .map((comment) => {
            const currentLike = comment.likes.find(
                (l) => l.userId === currentUserId
            );

            const isLiked = !!currentLike;

            const handleLikeButtonClicked = () => {
                toggleLike({
                    variables: {
                        tenantId: currentTenantId || '',
                        isLiked: !currentLike,
                        input: {
                            id: currentLike?.id || null,
                            commentId: comment.id,
                            userId: currentUserId || '',
                            version: '',
                        },
                    },
                });
            };

            const buttons: IButtonProps[] = [];

            if (currentUserId !== comment.userId) {
                buttons.push({
                    uniqueId: 'like',
                    toggle: true,
                    muted: !isLiked,
                    checked: isLiked,
                    text: isLiked ? 'Remove Like' : 'Like',
                    iconProps: isLiked
                        ? { iconName: 'LikeSolid' }
                        : { iconName: 'Like' },
                    allowDisabledFocus: true,
                    onClick: handleLikeButtonClicked,
                });
            }

            if (currentUserId === comment.userId) {
                buttons.push({
                    uniqueId: 'edit',
                    text: 'Edit Comment',
                    iconProps: {
                        iconName: 'Edit',
                    },
                    onClick: () =>
                        setEditingComment({
                            id: comment.id,
                            inReplyToId: comment.inReplyToId,
                            missionId: props.missionId ?? null,
                            measureId: props.measureId ?? null,
                            taskId: props.taskId ?? null,
                            text: comment.text,
                            version: comment.version,
                        }),
                });
            }

            if (
                currentUserId === comment.userId ||
                props.isOwnerOfCommentItem ||
                currentRoles.some((r) => r === 'GlobalAdmin')
            ) {
                buttons.push({
                    uniqueId: 'delete',
                    text: 'Delete Comment',
                    iconProps: {
                        iconName: 'Delete',
                    },
                    onClick: () => setConfirmDeleteCommentId(comment.id),
                });
            }

            return (
                <CommentListItem
                    key={`comment_${comment.id}`}
                    comment={comment}
                    buttons={buttons}
                />
            );
        });

    const handleDeleteComplete = () => setConfirmDeleteCommentId(null);
    const handleDeleteDismiss = () => setConfirmDeleteCommentId(null);

    const handleCommentSaved = () => setEditingComment(null);
    const handleCommentEditDismiss = () => setEditingComment(null);

    return (
        <React.Fragment>
            {currentTenantId && (
                <React.Fragment>
                    <CommentDeleteModal
                        tenantId={currentTenantId}
                        measureId={props.measureId}
                        taskId={props.taskId}
                        confirmDeleteCommentId={confirmDeleteCommentId}
                        onDeleteComplete={handleDeleteComplete}
                        onDismiss={handleDeleteDismiss}
                    />
                    <CommentEditModal
                        tenantId={currentTenantId}
                        comment={editingComment}
                        onCommentSaved={handleCommentSaved}
                        onDismiss={handleCommentEditDismiss}
                    />
                </React.Fragment>
            )}
            <Stack tokens={{ childrenGap: 8 }}>{activityItems}</Stack>

            {activityItems.length === 0 && (
                <Text variant="small" style={{ fontStyle: 'italic' }}>
                    No Comments
                </Text>
            )}
        </React.Fragment>
    );
}

function CommentListItem(props: {
    comment: CommentType;
    buttons: IButtonProps[] | undefined;
}): JSX.Element {
    const { comment } = props;
    const match = comment.username?.match(/\b\w/g) || [];
    const initials = (
        (match.shift() || '') + (match.pop() || '')
    ).toUpperCase();

    const { currentTheme } = useThemes();

    const commentContainerStyle: IRawStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        border: `solid 1px ${currentTheme.palette.neutralLight}`,
        borderRadius: 8,
        backgroundColor: currentTheme.palette.neutralLighterAlt,
    };

    const headerStyle: IRawStyle = {
        padding: 8,
        paddingBottom: 2,
    };

    const footerStyle: IRawStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 8,
        alignItems: 'end',
        padding: 8,
    };

    const classNames = mergeStyleSets({
        commentContainer: commentContainerStyle,
        header: headerStyle,
        footer: footerStyle,
        commentHeaderTimestamp: {
            color: currentTheme.palette.neutralDark,
        },
        commentText: {
            color: currentTheme.palette.neutralDark,
            whiteSpace: 'pre-line',
            paddingLeft: 8,
            paddingRight: 8,
        },
        likes: {
            color: currentTheme.palette.neutralDark,
        },
        editedLabel: {
            paddingLeft: 6,
        },
    });

    const handleRenderPrimaryText = (): JSX.Element => {
        return (
            <div>
                <Text block variant="medium">
                    {comment.username || 'Unknown Person'}
                </Text>
                <Text
                    block
                    variant="tiny"
                    className={classNames.commentHeaderTimestamp}
                >
                    {dayjs
                        .utc(comment.utcCreated)
                        .tz(dayjs.tz.guess())
                        .format('DD MMM YYYY - HH:mm (z)')}

                    {comment.utcUpdated ? (
                        <span
                            className={classNames.editedLabel}
                            title={`Edited ${dayjs
                                .utc(comment.utcUpdated)
                                .tz(dayjs.tz.guess())
                                .format('DD MMM YYYY - HH:mm (z)')}`}
                        >
                            Edited
                        </span>
                    ) : undefined}
                </Text>
            </div>
        );
    };

    return (
        <div className={classNames.commentContainer}>
            <div className={classNames.header}>
                <Persona
                    size={PersonaSize.size32}
                    text={comment.username || 'Unknown Person'}
                    onRenderPrimaryText={handleRenderPrimaryText}
                    imageInitials={initials}
                    imageUrl={photoService.getImageUrl(comment.userId)}
                    showUnknownPersonaCoin={!comment.username}
                />
            </div>
            <Text block variant="small" className={classNames.commentText}>
                {comment.text}
            </Text>
            <div className={classNames.footer}>
                <div>
                    {comment.likes.length > 0 && (
                        <ActivityItem
                            isCompact
                            activityDescription={
                                <Text
                                    variant="tiny"
                                    title={`Liked by: ${comment.likes
                                        .map((l) => l.username || '')
                                        .join(', ')}`}
                                >
                                    <span className={classNames.likes}>
                                        Liked by {comment.likes[0].username}
                                    </span>

                                    {comment.likes.length > 1 && (
                                        <span>
                                            <span> and </span>
                                            <span className={classNames.likes}>
                                                {comment.likes.length - 1}
                                                {comment.likes.length > 2
                                                    ? ' others'
                                                    : ' other'}
                                            </span>
                                        </span>
                                    )}
                                </Text>
                            }
                            activityPersonas={comment.likes.map((l) => ({
                                imageUrl: photoService.getImageUrl(l.userId),
                                text: l.username || 'Unknown',
                            }))}
                        />
                    )}
                </div>
                <div>
                    {props.buttons?.map((b) => {
                        return <IconButton key={b.uniqueId} {...b} />;
                    })}
                </div>
            </div>
        </div>
    );
}
