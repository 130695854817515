import React from 'react';
import icon from '../../images/advance-icon.png';

import { DefaultButton } from '@fluentui/react';

export type EngagementTargetKeys =
    | 'HelpIcon'
    | 'CreateMeasureButton'
    | 'TaskRatingsVisibilityDropdown'
    | 'PresentButton'
    | 'TaskNotificationsTitle';

export type Engagements = {
    key: string;
    targetKey?: EngagementTargetKeys;
    type: 'Announcement' | 'Popup' | 'Beacon';
    headline?: string;
    content: string | JSX.Element;
    activeFrom: string;
    activeTo: string;
    sequence: number;
    backgroundColour?: string;
    headlineColour?: string;
};

export const engagements: Engagements[] = [
    {
        key: 'Rebrand2024',
        type: 'Popup',
        headline: 'We’re excited to\nreveal a new look!',
        backgroundColour: '#363636',
        headlineColour: '#ffbd59',
        content: (
            <div
                style={{
                    backgroundColor: '#363636',
                    color: '#fff',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 8,
                    alignItems: 'center',
                }}
            >
                <div className="hideOnMobile">
                    <div style={{ paddingBottom: 42 }}>
                        <img src={icon} alt="Advance" />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16,
                        textAlign: 'center',
                    }}
                >
                    <div>
                        We hope you enjoy our new visual theme, crafted to
                        enhance your user experience with a fresh, modern
                        interface.
                    </div>
                    <div>
                        Our goal was to create a design that’s not only visually
                        appealing but also maintains the clarity and simplicity
                        you’ve come to expect.
                    </div>
                    <div>
                        <DefaultButton
                            styles={{
                                root: {
                                    height: 28,
                                },
                                label: {
                                    paddingTop: 4,
                                },
                            }}
                            text="Get Started!"
                        />
                    </div>
                </div>
            </div>
        ),
        activeFrom: '2024-06-20',
        activeTo: '2024-07-31',
        sequence: 1,
    },

    // {
    //     key: 'TaskNotificationsAssignTo',
    //     targetKey: 'TaskNotificationsTitle',
    //     type: 'Announcement',
    //     headline: 'New ‘Replace Existing’ Option!',
    //     content:
    //         'You can now assign incoming resource requests to replace your manually written tasks.',
    //     activeFrom: '2024-05-09',
    //     activeTo: '2024-05-31',
    //     sequence: 1,
    // },
    // {
    //     key: 'NewPresentOptions',
    //     targetKey: 'PresentButton',
    //     type: 'Beacon',
    //     headline: 'Present live or download your mission.',
    //     content:
    //         'Redesigned presentation views, now including a new Mission Summary, with a concise overview of critical mission components.',
    //     activeFrom: '2024-05-09',
    //     activeTo: '2024-05-31',
    //     sequence: 2,
    // },
    // {
    //     key: 'NewHelp',
    //     targetKey: 'HelpIcon',
    //     type: 'Announcement',
    //     headline: 'Explore Our New Knowledge Base!',
    //     content:
    //         'This extensive resource is your one-stop destination for all queries and information about our platform.',
    //     activeFrom: '2024-03-27',
    //     activeTo: '2024-05-01',
    //     sequence: 1,
    // },
    // {
    //     key: 'NewMeasureImportFeature',
    //     targetKey: 'CreateMeasureButton',
    //     type: 'Beacon',
    //     headline: 'New Feature!',
    //     content:
    //         'You can now import measures of success from a mission you had last year.',
    //     activeFrom: '2024-03-27',
    //     activeTo: '2024-05-01',
    //     sequence: 2,
    // },
    // {
    //     key: 'NewRatingsFeature',
    //     targetKey: 'TaskRatingsVisibilityDropdown',
    //     type: 'Beacon',
    //     headline: 'Reinforce task impact with Ratings!',
    //     content:
    //         'Easily set effort and cost ratings on implied tasks.\n\nNote: tasks with a higher effort rating will have a weighted affect on the overall specified task percent complete.',
    //     activeFrom: '2024-03-27',
    //     activeTo: '2024-05-01',
    //     sequence: 3,
    // },
];
