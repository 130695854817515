import {
    GetMeasureQuery,
    Measure,
    refetchGetMeasureValueHistoryQuery,
    refetchGetMissionMeasuresQuery,
    useDeleteMeasureMutation,
    useGetMeasureLazyQuery,
    useUnlinkMeasureMutation,
    useUpdateMeasureMutation,
} from '../data/types';

import { useStateContext } from '../services/contextProvider';

export const useMeasureWarning = (
    missionId: string,
    measure: {
        id: string | null;
        isLinked: boolean;
        name: string | null;
        linkedFromMeasure: {
            utcDeleted: string | null;
            name: string | null;
            mission: {
                utcDeleted: string | null;
                utcInactive: string | null;
            } | null;
        } | null;
    }
): {
    hasWarning: boolean;
    warningMessage: string | null;
    actions: {
        name: string;
        executeAction: (() => Promise<void>) | null;
        isSelected: boolean;
    }[];
} => {
    let hasWarning = false;
    let warningMessage: string | null = null;
    let actions: {
        name: string;
        executeAction: (() => Promise<void>) | null;
        isSelected: boolean;
    }[] = [];

    const { currentTenantId } = useStateContext();

    const [updateMeasure] = useUpdateMeasureMutation();

    const [getMeasureLazy] = useGetMeasureLazyQuery({
        nextFetchPolicy: 'network-only',
        variables: {
            id: measure.id || '',
            tenantId: currentTenantId || '',
        },
    });

    const [deleteMeasureMutation] = useDeleteMeasureMutation({
        variables: {
            tenantId: currentTenantId || '',
            id: measure.id || '',
            restore: false,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            refetchGetMissionMeasuresQuery({
                tenantId: currentTenantId || '',
                missionId: missionId,
            }),
        ],
    });

    const [unlinkMeasureMutation] = useUnlinkMeasureMutation();

    const getMeasureInputFromData = (data: GetMeasureQuery): Measure | null => {
        const loadedMeasure = data?.measure;

        if (!loadedMeasure) {
            return null;
        }

        return {
            id: loadedMeasure.id,
            missionId: loadedMeasure.missionId || '',
            measureGroupId: loadedMeasure.measureGroupId,
            name: loadedMeasure.name,
            description: loadedMeasure.description,
            measureType: loadedMeasure.measureType,
            phaseType: loadedMeasure.phaseType,
            currency: loadedMeasure.currency
                ? {
                      code: loadedMeasure.currency?.code,
                      descr: loadedMeasure.currency?.descr,
                      symbol: loadedMeasure.currency?.symbol,
                  }
                : null,
            multiplier: loadedMeasure.multiplier,
            decimalPlaces: loadedMeasure.decimalPlaces,
            statusType: loadedMeasure.statusType,
            yellowStart: loadedMeasure.yellowStart,
            greenRange: loadedMeasure.greenRange,
            yellowRange: loadedMeasure.yellowRange,
            isStatusLimited: loadedMeasure.isStatusLimited,
            frequencyNumber: loadedMeasure.frequencyNumber,
            frequencyPeriod: loadedMeasure.frequencyPeriod,
            isLinked: loadedMeasure.isLinked,
            linkedFromMeasureId: loadedMeasure.linkedFromMeasureId,
            tags: loadedMeasure.tags,
            sequence: loadedMeasure.sequence,
            version: loadedMeasure.version,

            previousFYMeasureId: loadedMeasure.previousFYMeasureId,
            fullYearTarget: loadedMeasure.fullYearTarget,
            fullYearString: loadedMeasure.fullYearString,
            isFullYearTarget: loadedMeasure.isFullYearTarget,
            chartDisplay: loadedMeasure.chartDisplay,
            chartType: loadedMeasure.chartType,
            showForecast: loadedMeasure.showForecast,
            showFutureLook: loadedMeasure.showFutureLook,
            isCustom: loadedMeasure.isCustom,
            targetType: loadedMeasure.targetType,
            valueType: loadedMeasure.valueType,
            valueFormula: loadedMeasure.valueFormula,
        };
    };

    const unlinkMeasure = async (keepValues: boolean) => {
        await unlinkMeasureMutation({
            variables: {
                tenantId: currentTenantId || '',
                measureId: measure.id || '',
                keepValues: keepValues,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                refetchGetMissionMeasuresQuery({
                    tenantId: currentTenantId || '',
                    missionId: missionId,
                }),
                refetchGetMeasureValueHistoryQuery({
                    tenantId: currentTenantId || '',
                    id: measure.id || '',
                    historyHasActual: false,
                    historySkip: 0,
                    historyTake: 12,
                }),
            ],
        });
    };

    if (
        measure.isLinked &&
        measure.linkedFromMeasure &&
        (measure.linkedFromMeasure?.utcDeleted ||
            measure.linkedFromMeasure.mission?.utcDeleted ||
            measure.linkedFromMeasure.mission?.utcInactive)
    ) {
        hasWarning = true;
        warningMessage =
            'This measure of success has been removed by the owner';
        actions = [
            {
                name: 'Remove this measure',
                executeAction: async () => {
                    await deleteMeasureMutation();
                },
                isSelected: true,
            },
            {
                name: 'Keep this measure (with values)',
                executeAction: async () => {
                    await unlinkMeasure(true);
                },
                isSelected: false,
            },
            {
                name: 'Keep measure (without values)',
                executeAction: async () => {
                    await unlinkMeasure(false);
                },
                isSelected: false,
            },
        ];
    } else if (
        measure.isLinked &&
        measure.linkedFromMeasure &&
        measure.linkedFromMeasure.name &&
        measure.linkedFromMeasure?.name !== measure.name
    ) {
        hasWarning = true;
        warningMessage = 'This measure of success has been renamed';
        actions = [
            {
                name: `Update this measure of success's name to "${measure.linkedFromMeasure.name}"`,
                executeAction: async () => {
                    const result = await getMeasureLazy();

                    if (!result.data) {
                        return;
                    }

                    const input = getMeasureInputFromData(result.data);

                    const fixedName = measure?.linkedFromMeasure?.name || '';

                    if (input && input.name !== fixedName) {
                        await updateMeasure({
                            variables: {
                                tenantId: currentTenantId || '',
                                input: {
                                    ...input,
                                    name: fixedName,
                                },
                            },
                        });
                    }
                },
                isSelected: true,
            },
        ];
    }

    return {
        hasWarning,
        warningMessage,
        actions,
    };
};
