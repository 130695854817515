import React from 'react';
import { Dropdown, mergeStyleSets } from '@fluentui/react';
import { ReportPeriodTypes } from '../../../data/types';
import { useTemplateReportPeriods } from '../hooks/useTemplateReportPeriods';

export function TemplateReportPeriodSelection(props: {
    fyStartDate: string | null | undefined;
    fyEndDate: string | null | undefined;
    reportPeriodType: ReportPeriodTypes | null | undefined;
    reportPeriod: number | null | undefined;
    onPeriodChanged: (
        reportPeriodType: ReportPeriodTypes,
        reportPeriod: number
    ) => void;
}) {
    const {
        reportPeriodType,
        reportPeriod,
        fyStartDate,
        fyEndDate,
        onPeriodChanged,
    } = props;

    const { getPeriods, formatPeriod } = useTemplateReportPeriods(
        fyStartDate,
        fyEndDate
    );

    const periods = getPeriods(reportPeriodType);

    if (!periods.some((p) => p.period === reportPeriod) && reportPeriod) {
        periods.push({
            text: `Selected - ${formatPeriod(reportPeriodType, reportPeriod)}`,
            period: reportPeriod,
        });
    }

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
    });

    return (
        <div className={classNames.container}>
            {reportPeriodType && (
                <Dropdown
                    label="Report Period Type"
                    selectedKey={reportPeriodType.toString()}
                    disabled
                    options={[
                        {
                            text: 'Current',
                            key: ReportPeriodTypes.None,
                        },
                        {
                            text: 'Month',
                            key: ReportPeriodTypes.Month,
                        },
                        {
                            text: 'Quarter',
                            key: ReportPeriodTypes.Quarter,
                        },
                        {
                            text: 'Half',
                            key: ReportPeriodTypes.Half,
                        },
                    ]}
                />
            )}

            {reportPeriodType &&
                reportPeriodType !== ReportPeriodTypes.None && (
                    <Dropdown
                        label="Reporting Period"
                        selectedKey={reportPeriod}
                        options={periods.map((p) => ({
                            text: p.text,
                            key: p.period || 0,
                        }))}
                        onChange={(_ev, option) => {
                            if (option) {
                                onPeriodChanged(
                                    reportPeriodType || ReportPeriodTypes.None,
                                    option.key as number
                                );
                            }
                        }}
                    />
                )}
        </div>
    );
}
