import React, { useEffect, useState } from 'react';
import {
    DefaultButton,
    IRawStyle,
    Label,
    mergeStyleSets,
    Stack,
} from '@fluentui/react';
import { InputShimmer } from '../../../components/inputs';
import { MeasurePicker } from '../../../components/MeasurePicker';
import { useStateContext } from '../../../services/contextProvider';
import {
    GetMeasureQuery,
    Measure,
    useGetMeasureLazyQuery,
} from '../../../data/types';
import MeasureCard from '../../../components/MeasureCard';

type Props = {
    fyStartDate: string | undefined;
    fyEndDate: string | undefined;
    selectMeasure: (measure: Measure | null) => void;
    excludeMeasureIds?: string[];
};

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    } as IRawStyle,
});

function MeasureSelect(props: Props): JSX.Element {
    const { currentTenantId } = useStateContext();
    // const { teamCode, missionId, measureId } = useParams();

    const [selectedMeasureId, setSelectedMeasureId] = useState<
        string | undefined | null
    >();
    // const [measure, setMeasure] = useState<Measure>();

    const [measureCardPreviewMeasure, setMeasureCardPreviewMeasure] =
        useState<GetMeasureQuery['measure']>();

    const [lazyLoadMeasure] = useGetMeasureLazyQuery();
    const [previewLoading, setPreviewLoading] = useState<boolean>(false);

    const loadPreview = async () => {
        if (selectedMeasureId) {
            setPreviewLoading(true);
            const { data } = await lazyLoadMeasure({
                variables: {
                    id: selectedMeasureId,
                    tenantId: currentTenantId || '',
                },
            });

            setMeasureCardPreviewMeasure(data?.measure);
            setPreviewLoading(false);
        } else {
            setMeasureCardPreviewMeasure(null);
        }
    };

    useEffect(() => {
        loadPreview();
    }, [selectedMeasureId]);

    return (
        <div className={classNames.container}>
            <div>
                <Label>Find a measure of success</Label>
                <MeasurePicker
                    excludeMeasureIds={props.excludeMeasureIds}
                    onMeasureSelected={setSelectedMeasureId}
                />
            </div>

            {!!measureCardPreviewMeasure && (
                <InputShimmer isDataLoaded={!previewLoading}>
                    <MeasureCard
                        missionAccess={{
                            read: false,
                            write: false,
                            export: false,
                            import: false,
                        }}
                        {...measureCardPreviewMeasure.lastAsOf}
                        {...measureCardPreviewMeasure}
                        id={measureCardPreviewMeasure?.id || ''}
                        name={measureCardPreviewMeasure?.name || ''}
                        description={
                            measureCardPreviewMeasure?.description || ''
                        }
                        linkedFromMeasure={
                            measureCardPreviewMeasure.linkedFromMeasure
                        }
                        isCompact
                        fyStartDate={props.fyStartDate}
                        fyEndDate={props.fyEndDate}
                    />
                </InputShimmer>
            )}

            <Stack horizontal tokens={{ childrenGap: 8 }}>
                {!!measureCardPreviewMeasure && (
                    <DefaultButton
                        iconProps={{ iconName: 'Add' }}
                        onClick={() => {
                            props.selectMeasure(measureCardPreviewMeasure);
                        }}
                    >
                        Add
                    </DefaultButton>
                )}
                <DefaultButton
                    iconProps={{ iconName: 'Cancel' }}
                    onClick={() => {
                        props.selectMeasure(null);
                    }}
                >
                    Cancel
                </DefaultButton>
            </Stack>
        </div>
    );
}

export default MeasureSelect;
