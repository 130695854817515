import * as React from 'react';

import {
    Stack,
    DefaultButton,
    Dropdown,
    ScrollablePane,
    ScrollbarVisibility,
    IDropdownOption,
    SearchBox,
} from '@fluentui/react';

import { useStateContext } from '../../services/contextProvider';
import { useGetUsersQuery, UserQl } from '../../data/types';
import { SetupPageContainer } from './components/SetupPageContainer';
import { UserDetailList } from './components/UserDetailList';
import { UserEditPanel } from './components/UserEditPanel';
import { photoService } from '../../services/photo.service';
import { AdvanceCard } from '../../components/AdvanceCard';

export function Users(): JSX.Element {
    const { userTenants } = useStateContext();
    const [showAddPanel, setShowAddPanel] = React.useState<boolean>(false);

    const [textFilter, setTextFilter] = React.useState<string | null>();
    const [tenantIdFilter, setTenantIdFilter] = React.useState<string | null>();
    const [selectedUser, setSelectedUser] = React.useState<UserQl | null>();

    const { data, loading, refetch } = useGetUsersQuery({
        variables: {
            tenantId: tenantIdFilter || null,
            searchText: textFilter || '',
            useCache: false,
        },
    });

    const items = data?.userSearch || [];

    const tenants = userTenants.map((ut) => ut.tenant);
    const tenantOptions = (tenants || [])
        .slice()
        .sort((a, b) =>
            (a.description || '').localeCompare(b.description || '')
        )
        .map((t) => {
            return { key: t.id || '', text: t.description || t.code || '' };
        });

    return (
        <SetupPageContainer>
            <Stack horizontal tokens={{ padding: 0, childrenGap: 'm' }}>
                <Stack.Item>
                    <SearchBox
                        styles={{
                            root: { minWidth: 300, maxWidth: 300 },
                        }}
                        onSearch={(newValue?: string): void => {
                            setTextFilter(newValue);
                        }}
                        onClear={(): void => {
                            setTextFilter(null);
                        }}
                        iconProps={{ iconName: 'Filter' }}
                        placeholder="Filter by name or email address"
                    />
                </Stack.Item>

                <Stack.Item grow>
                    <Dropdown
                        styles={{
                            root: { minWidth: 200, maxWidth: 300 },
                        }}
                        placeholder="Filter by tenant"
                        unselectable="on"
                        options={[
                            { key: '', text: 'All Tenants' },
                            ...tenantOptions,
                        ]}
                        onChange={(
                            _event: React.FormEvent,
                            option?: IDropdownOption
                        ): void => {
                            setTenantIdFilter((option?.key as string) || null);
                        }}
                    />
                </Stack.Item>

                <Stack.Item align="end">
                    <DefaultButton
                        text="New user"
                        iconProps={{ iconName: 'AddFriend' }}
                        onClick={(): void => {
                            setSelectedUser(null);
                            setShowAddPanel(true);
                        }}
                    />
                </Stack.Item>
            </Stack>

            <div className="content-container">
                <AdvanceCard
                    style={{
                        height: '100%',
                    }}
                >
                    <ScrollablePane
                        scrollbarVisibility={ScrollbarVisibility.always}
                    >
                        <UserDetailList
                            users={items || []}
                            isDataLoaded={!loading}
                            onItemEdit={(selectedId: string): void => {
                                setSelectedUser(
                                    (items || []).find(
                                        (u) => u.id === selectedId
                                    ) as UserQl
                                );
                                setShowAddPanel(true);
                            }}
                            onAccessEdit={null}
                        />
                    </ScrollablePane>
                </AdvanceCard>
            </div>

            <UserEditPanel
                showPanel={showAddPanel}
                showGlobalRights={true}
                user={selectedUser}
                key={`EditUserPanel_${selectedUser?.id}` || 'EditUserPanel_New'}
                onUpdate={(): void => {
                    setShowAddPanel(false);
                }}
                onCreate={(): void => {
                    setShowAddPanel(false);
                    refetch();
                }}
                onDelete={(): void => {
                    setShowAddPanel(false);
                    refetch();
                }}
                onCancel={(): void => {
                    setShowAddPanel(false);
                }}
                onPhotoChanged={(): void => {
                    photoService.refreshImageUrl(selectedUser?.id);
                }}
            />
        </SetupPageContainer>
    );
}
