import React, { useEffect } from 'react';
import { TemplateReportElementProps } from './TemplateReportElement';
import { sorters } from '../../../data/sorters';
import { useTemplateReportMeasures } from '../hooks/useTemplateReportMeasures';
import { mergeStyleSets } from '@fluentui/react';
import MeasureArrow from '../../../components/MeasureArrow';
import { AdvanceCard } from '../../../components/AdvanceCard';

export function TemplateReportMeasureTableElement(
    props: TemplateReportElementProps
) {
    const { measures, setSelectedMeasureIds, periodData } =
        useTemplateReportMeasures({ ...props });

    useEffect(() => {
        setSelectedMeasureIds(
            (props.sourceElement
                ? props.sourceElement.measureLinks
                : props.element.measureLinks
            )
                .slice()
                .sort(sorters.sequenceSorter)
                .map((sm) => sm.measureId)
        );
    }, [
        props.sourceElement,
        props.element.measureLinks,
        setSelectedMeasureIds,
    ]);

    const classNames = mergeStyleSets({
        table: {
            paddingTop: 4,
            paddingLeft: 4,
            paddingRight: 4,
            width: '100%',
            textAlign: 'left',
            '& THEAD TR TH': {
                paddingBottom: 8,
            },
            '& TR TD': {
                paddingTop: 4,
                paddingBottom: 4,
                verticalAlign: 'middle',
            },
        },
        valueCell: {
            whiteSpace: 'no-wrap',
            textAlign: 'right',
        },
        valueCellContent: {
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            justifyContent: 'end',
            alignItems: 'center',
        },
    });

    return (
        <AdvanceCard>
            <table className={classNames.table}>
                <thead>
                    <tr>
                        <th>Measure of Success</th>
                        <th className={classNames.valueCell}>Actual</th>
                        <th className={classNames.valueCell}>Target</th>
                    </tr>
                </thead>
                <tbody>
                    {measures.map((measure) => {
                        const measurePeriodData = periodData.find(
                            (pd) =>
                                pd.measureId === measure?.id ||
                                (measure?.isLinked &&
                                    measure.linkedFromMeasureId ===
                                        pd.measureId)
                        );

                        const target = measurePeriodData?.targetFormatted;
                        const actual = measurePeriodData?.actualFormatted;

                        return (
                            <tr key={measure.id}>
                                <td>{measure.name}</td>

                                <td className={classNames.valueCell}>
                                    <div
                                        className={classNames.valueCellContent}
                                    >
                                        <span
                                            title={
                                                measurePeriodData?.period ||
                                                undefined
                                            }
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            {actual !== null ? actual : 'NONE'}
                                        </span>
                                        {measurePeriodData && (
                                            <MeasureArrow
                                                {...measurePeriodData}
                                                isStatusLimited={
                                                    measure.isStatusLimited
                                                }
                                                iconFontSize={20}
                                                skipValuesCheck
                                            />
                                        )}
                                    </div>
                                </td>
                                <td className={classNames.valueCell}>
                                    <div
                                        className={classNames.valueCellContent}
                                        title={
                                            measurePeriodData?.nextPeriod ||
                                            undefined
                                        }
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        {target}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </AdvanceCard>
    );
}
