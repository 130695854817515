import React, { useState } from 'react';
import {
    TextField,
    PrimaryButton,
    mergeStyleSets,
    IRawStyle,
    Persona,
    PersonaSize,
} from '@fluentui/react';
import {
    useUpdateCommentMutation,
    Comment,
    refetchGetCommentsQuery,
    refetchGetMeasureQuery,
    refetchGetTaskQuery,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { PureQueryOptions } from '@apollo/client';
import { useThemes } from '../hooks/useThemes';
import { photoService } from '../services/photo.service';

type NewCommentProps = {
    missionId?: string | null | undefined;
    measureId?: string | null | undefined;
    taskId?: string | null | undefined;
    refetchMeasureId?: string | null | undefined;
};

export function NewComment(props: NewCommentProps): JSX.Element {
    const { currentTenantId, currentUserId, currentUserDisplayName } =
        useStateContext();

    const [newCommentText, setNewCommentText] = useState<string>('');

    const { currentTheme } = useThemes();

    const [saveNewComment, { loading: isSavingComment }] =
        useUpdateCommentMutation({
            onCompleted: () => {
                setNewCommentText('');
            },
        });

    const handleSaveButtonClick = () => {
        if (!newCommentText || !currentTenantId) {
            return;
        }

        const refetchQueries: PureQueryOptions[] = [
            refetchGetCommentsQuery({
                tenantId: currentTenantId,
                taskId: props.taskId || null,
                measureId: props.measureId || null,
            }),
        ];

        if (props.measureId) {
            refetchQueries.push(
                refetchGetMeasureQuery({
                    tenantId: currentTenantId,
                    id: props.measureId,
                })
            );
        } else if (props.taskId) {
            refetchQueries.push(
                refetchGetTaskQuery({
                    tenantId: currentTenantId,
                    id: props.taskId,
                })
            );
        }

        if (
            props.refetchMeasureId &&
            props.refetchMeasureId !== props.measureId
        ) {
            refetchQueries.push(
                refetchGetMeasureQuery({
                    tenantId: currentTenantId,
                    id: props.refetchMeasureId,
                })
            );

            refetchQueries.push(
                refetchGetCommentsQuery({
                    tenantId: currentTenantId,
                    taskId: null,
                    measureId: props.refetchMeasureId,
                })
            );
        }

        saveNewComment({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id: null,
                    text: newCommentText,
                    measureId: props.measureId || null,
                    missionId: props.missionId || null,
                    taskId: props.taskId || null,
                    inReplyToId: null,
                    version: '',
                } as Comment,
            },
            refetchQueries: refetchQueries,
        });
    };

    const handleCommentTextChange = (
        _ev: React.FormEvent,
        newValue: string | undefined
    ): void => setNewCommentText(newValue || '');

    const commentContainerStyle: IRawStyle = {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        border: `solid 1px ${currentTheme.palette.neutralLight}`,
        backgroundColor: currentTheme.palette.neutralLighterAlt,
        padding: 8,
        borderRadius: 8,
    };

    const textBoxContainerStyle: IRawStyle = {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
    };

    const classNames = mergeStyleSets({
        commentContainer: commentContainerStyle,
        textBoxContainer: textBoxContainerStyle,
    });

    return (
        <div className={classNames.commentContainer}>
            {!newCommentText && (
                <Persona
                    size={PersonaSize.size32}
                    text={currentUserDisplayName || ''}
                    imageUrl={photoService.getImageUrl(currentUserId)}
                    hidePersonaDetails
                />
            )}
            <div className={classNames.textBoxContainer}>
                <TextField
                    placeholder="Write a comment..."
                    multiline={!!newCommentText}
                    autoAdjustHeight
                    value={newCommentText}
                    onChange={handleCommentTextChange}
                />
                {!!newCommentText && (
                    <PrimaryButton
                        text="Save"
                        disabled={isSavingComment || !newCommentText}
                        onClick={handleSaveButtonClick}
                    />
                )}
            </div>
        </div>
    );
}
