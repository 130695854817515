import React, { useEffect, useState } from 'react';

import { TemplateReportElementProps } from './TemplateReportElement';
import { Guid } from 'guid-typescript';
import { useTemplateReportTasks } from '../hooks/useTemplateReportTasks';
import { sorters } from '../../../data/sorters';
import { mergeStyleSets } from '@fluentui/react';
import { AdvanceCard } from '../../../components/AdvanceCard';
import { EditibleText } from '../../../components/EditibleText';
import { TemplateReportElementType } from '../TemplateReport';

export function TemplateReportTaskBulletListElement(
    props: TemplateReportElementProps & {
        onElementChanged: (
            updated: TemplateReportElementType,
            forceImmediateSave: boolean
        ) => void;
    }
) {
    const { sourceElement, element } = props;
    const { title } = element;

    const { setSelectedTaskIds, specifiedTasks, impliedTasks } =
        useTemplateReportTasks(props);

    const [customFacts, setCustomFacts] = useState<
        {
            id: string;
            text: string;
        }[]
    >([]);

    useEffect(() => {
        setSelectedTaskIds(
            (sourceElement ? sourceElement.taskLinks : element.taskLinks)
                .slice()
                .sort(sorters.sequenceSorter)
                .map((sm) => sm.taskId)
        );
    }, [
        sourceElement,
        sourceElement?.taskLinks,
        element.taskLinks,
        setSelectedTaskIds,
    ]);

    useEffect(() => {
        setCustomFacts(
            (sourceElement ? sourceElement.textContent : element.textContent)
                ?.split('|')
                .map((cf) => ({
                    id: Guid.create().toString(),
                    text: cf,
                })) || []
        );
    }, [sourceElement, sourceElement?.textContent, element.textContent]);

    const classNames = mergeStyleSets({
        title: {
            margin: 0,
        },
        container: {
            height: '100%',
            paddingLeft: 8,
            paddingRight: 8,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        bulletList: {
            flex: 1,
            '& ul': {
                paddingLeft: 16,
            },
            '& ul li': {
                paddingBottom: 4,
            },
        },
    });

    const handleTitleUpdateClick = async (newTitle: string) => {
        props.onElementChanged(
            {
                ...props.element,
                title: newTitle,
            },
            true
        );
    };

    return (
        <AdvanceCard style={{ height: '100%' }} childrenGap={8}>
            <div className={classNames.container}>
                {!!props.element.title && (
                    <div>
                        <EditibleText
                            isRequired
                            isReadOnly={props.isReadOnly}
                            dialogTitle={'Edit Title'}
                            text={title || ''}
                            onUpdateClick={handleTitleUpdateClick}
                        >
                            <h4 className={classNames.title}>{title}</h4>
                        </EditibleText>
                    </div>
                )}
                <div className={classNames.bulletList}>
                    <ul>
                        {specifiedTasks.map((st) => {
                            const its = impliedTasks.filter(
                                (it) =>
                                    it.parentTaskId === st.id && !it.isDuplicate
                            );

                            return (
                                <li key={st.id}>
                                    {st.name}

                                    {!!its.length && (
                                        <ul>
                                            {its.map((it) => {
                                                return (
                                                    <li key={it.id}>
                                                        {it.name}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </li>
                            );
                        })}
                        {customFacts.map((cf) => {
                            return <li key={cf.id}>{cf.text}</li>;
                        })}
                    </ul>
                </div>
            </div>
        </AdvanceCard>
    );
}
