import React from 'react';
import {
    Stack,
    IContextualMenuItem,
    ContextualMenuItemType,
    Icon,
    TooltipHost,
    IContextualMenuItemProps,
    IContextualMenuItemRenderFunctions,
} from '@fluentui/react';
import {
    GetMissionTasksQuery,
    Tag,
    TagTypes,
    useGetMissionTasksLazyQuery,
    useGetTagsQuery,
    useGetTaskCategoryOptionsQuery,
    useUpdateTaskMutation,
    useUpdateTaskTagsMutation,
} from '../data/types';
import { useThemes } from '../hooks/useThemes';
import { ExtractQueryArrayType } from '../data/extendedTypes';
import { useStateContext } from '../services/contextProvider';
import { useInputMappers } from '../hooks/useInputMappers';
import { Chip } from './Chip';
import dayjs from 'dayjs';
import { gql, useApolloClient } from '@apollo/client';

export function SpecifiedTaskCardChipPicker(props: {
    divisionId: string | null;
    specifiedTask: ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>;
    isReadOnly: boolean;
    isLoading: boolean;
    onSendToTop: () => void;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { currentTheme } = useThemes();

    const client = useApolloClient();

    const { data, loading: categoriesLoading } = useGetTaskCategoryOptionsQuery(
        {
            skip: !currentTenantId,
            variables: {
                tenantId: currentTenantId || '',
                divisionId: props.divisionId,
            },
        }
    );

    const { data: tagsData, loading: tagsLoading } = useGetTagsQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
        },
    });

    const [getMissionTasksLazy] = useGetMissionTasksLazyQuery();

    const [updateTaskMutation, { loading: savingTask }] =
        useUpdateTaskMutation();
    const [updateTaskTagsMutation, { loading: savingTags }] =
        useUpdateTaskTagsMutation();

    const { getTaskInput } = useInputMappers();

    const updateCategory = async (newCategoryId: string | null) => {
        if (!props.specifiedTask.id || !currentTenantId) {
            return;
        }
        const taskForInput = {
            ...props.specifiedTask,
            taskCategoryId: newCategoryId,
        };

        const input = getTaskInput(taskForInput);

        const newCategory =
            data?.taskCategories?.find((t) => t.id === newCategoryId) || null;

        // Make it nice and snappy
        client.writeFragment({
            id: `TaskQL:${props.specifiedTask.id}`,
            fragment: gql`
                fragment CategoryUpdate on TaskQL {
                    taskCategoryId
                    taskCategory {
                        id
                        name
                        colourHex
                    }
                }
            `,
            data: {
                taskCategoryId: newCategoryId,
                taskCategory: newCategory,
            },
        });

        await updateTaskMutation({
            variables: {
                tenantId: currentTenantId,
                input: input,
            },
        });
    };

    const taskCategory = props.specifiedTask.taskCategory || null;

    const categoryMenuItems: IContextualMenuItem[] =
        data?.taskCategories
            ?.filter((tc) => tc.id !== taskCategory?.id)
            ?.map((tc) => {
                const color = tc?.colourHex ? `#${tc.colourHex}` : undefined;

                return {
                    key: tc.id || '',
                    text: tc.name || '',
                    disabled:
                        taskCategory?.id === tc.id || savingTask || savingTags,
                    iconProps: {
                        iconName: 'TagSolid',
                        styles: {
                            root: {
                                color: color,
                            },
                        },
                    },
                    onClick: () => {
                        updateCategory(tc.id || null);
                    },
                };
            }) || [];

    if (taskCategory?.id) {
        categoryMenuItems.push({
            key: 'clear-divider',
            itemType: ContextualMenuItemType.Divider,
        });

        categoryMenuItems.push({
            key: 'clear-category',
            text: 'Clear',
            iconProps: {
                iconName: 'Clear',
            },
            disabled: savingTask || savingTags,
            onClick: () => {
                updateCategory(null);
            },
        });
    }

    // The default tags.
    const classifications = [
        {
            key: 'main-effort',
            name: 'Main Effort',
            colour: '#f38420',
            iconName: 'FavoriteStarFill',
            description:
                'The main effort is most critical task within your mission.',
        },
        {
            key: 'flanking-activity',
            name: 'Flanking Activity',
            colour: '#1e8aa8',
            iconName: 'Combine',
            description:
                'A flanking activity directly supports the main effort.',
        },
        {
            key: 'enabling-activity',
            name: 'Enabling Activity',
            colour: '#498205',
            iconName: 'Commitments',
            description:
                'An enabling activity directly supports other missions within the cascade.',
        },
    ];

    // Check the tag data for in case the icon / colour has changed.
    classifications.forEach((c) => {
        const tagData = tagsData?.tags?.find((t) => t.name === c.name);
        if (tagData?.tagType === TagTypes.UserTag) {
            c.iconName = tagData.icon || c.iconName;
            c.colour = `#${tagData.colourHex}` || c.colour;
        }
    });

    const tags = props.specifiedTask.tags;

    const selectedClassification = classifications.find((c) =>
        tags?.some((t) => t.name === c.name)
    );

    const setTag = async (taskId: string, updatedTags: Tag[]) => {
        await updateTaskTagsMutation({
            variables: {
                taskId: taskId,
                tenantId: currentTenantId || '',
                tags: updatedTags,
            },
            optimisticResponse: {
                __typename: 'Mutations',
                taskTagsUpdate: {
                    __typename: 'TaskQL',
                    id: taskId,
                    tags: updatedTags.map((t) => {
                        return {
                            ...t,
                            __typename: 'TagQL',
                        };
                    }),
                    utcUpdated: dayjs.utc().toISOString(),
                    version: '',
                },
            },
        });
    };

    const setClassification = async (classificationKey: string | null) => {
        let updatedTags: Tag[] =
            tags?.map((t) => {
                return {
                    id: t.id,
                    name: t.name,
                    colourHex: t.colourHex,
                    icon: t.icon,
                    tagType: t.tagType,
                    isMeasureTag: t.isMeasureTag,
                    isTaskTag: t.isTaskTag,
                };
            }) || [];

        classifications.forEach((c) => {
            if (c.key !== classificationKey) {
                updatedTags = updatedTags.filter((t) => t.name !== c.name);
            } else {
                if (!updatedTags.some((t) => t.name === c.name)) {
                    updatedTags.push({
                        id: null,
                        name: c.name,
                        colourHex: c.colour.replace('#', ''),
                        icon: c.iconName,
                        tagType: TagTypes.UserTag,
                        isMeasureTag: false,
                        isTaskTag: true,
                    });
                }
            }
        });

        const functions: Promise<void>[] = [];

        functions.push(setTag(props.specifiedTask.id || '', updatedTags));

        if (classificationKey === 'main-effort') {
            // Remove any other task that has a main effort.
            if (props.specifiedTask.missionId) {
                const missionTasks = await getMissionTasksLazy({
                    variables: {
                        tenantId: currentTenantId || '',
                        missionId: props.specifiedTask.missionId,
                    },
                });

                const previousMainEffortTasks =
                    missionTasks?.data?.tasks?.filter(
                        (t) =>
                            t.parentTaskId === null &&
                            t.id !== props.specifiedTask.id &&
                            !t.isDuplicate &&
                            t.tags.some((tag) => tag.name === 'Main Effort')
                    ) || [];

                for (const pt of previousMainEffortTasks) {
                    if (pt?.id) {
                        functions.push(
                            setTag(
                                pt.id,
                                pt?.tags.filter(
                                    (tag) => tag.name !== 'Main Effort'
                                )
                            )
                        );
                    }
                }
            }

            await Promise.all(functions);

            props.onSendToTop();
        }
    };

    const renderClassificationMenuItem = (
        props: IContextualMenuItemProps,
        defaultRenders: IContextualMenuItemRenderFunctions
    ) => {
        return (
            <>
                {defaultRenders.renderCheckMarkIcon(props)}
                {defaultRenders.renderItemIcon(props)}
                {defaultRenders.renderItemName(props)}
                {
                    // eslint-disable-next-line react/prop-types
                    !!props.item?.secondaryText && (
                        // eslint-disable-next-line react/prop-types
                        <TooltipHost content={props.item?.secondaryText}>
                            <Icon
                                style={{ cursor: 'pointer' }}
                                iconName="Info"
                                aria-label="Rollup Info"
                            />
                        </TooltipHost>
                    )
                }
                {defaultRenders.renderSubMenuIcon(props)}
            </>
        );
    };

    const classificationMenuItems: IContextualMenuItem[] = classifications.map(
        (c) => {
            return {
                ...c,
                iconProps: {
                    iconName: c.iconName,
                },
                disabled: savingTask || savingTags,
                onClick: () => {
                    setClassification(c.key);
                },
                secondaryText: c.description,
                onRenderContent: renderClassificationMenuItem,
            };
        }
    );

    if (selectedClassification) {
        classificationMenuItems.push({
            key: 'clear-divider',
            itemType: ContextualMenuItemType.Divider,
        });

        classificationMenuItems.push({
            key: 'clear-classification',
            text: 'Clear',
            iconProps: {
                iconName: 'Clear',
            },
            disabled: savingTask || savingTags,
            onClick: () => {
                setClassification(null);
            },
        });
    }

    return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
            {(taskCategory ||
                data?.taskCategories?.length ||
                categoriesLoading) && (
                <Chip
                    enableShimmer={props.isLoading || categoriesLoading}
                    text={taskCategory?.name || 'No Category'}
                    iconName={taskCategory ? 'TagSolid' : 'Tag'}
                    backgroundColor={
                        taskCategory?.colourHex
                            ? `#${taskCategory?.colourHex}`
                            : currentTheme.palette.neutralQuaternary
                    }
                    disabled={!data?.taskCategories}
                    menuProps={
                        !props.isReadOnly
                            ? {
                                  items: categoryMenuItems,
                              }
                            : undefined
                    }
                    chipTextWidth={80}
                    collapseOnMobile
                />
            )}

            <Chip
                enableShimmer={
                    props.isLoading || tagsLoading || !props.specifiedTask.id
                }
                text={
                    selectedClassification?.name
                        ? `${selectedClassification?.name}\n${selectedClassification?.description}`
                        : null
                }
                backgroundColor={
                    selectedClassification?.colour ||
                    currentTheme.palette.neutralQuaternary
                }
                iconName={selectedClassification?.iconName || 'FavoriteStar'}
                menuProps={
                    !props.isReadOnly
                        ? { items: classificationMenuItems }
                        : undefined
                }
                hideText
                collapseOnMobile
            />
        </Stack>
    );
}
