import React, { useEffect, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';
import debounce from 'lodash/debounce';

import {
    CollapseAllVisibility,
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    GroupHeader,
    IColumn,
    IDetailsGroupDividerProps,
    IDetailsRowProps,
    IDetailsRowStyles,
    Link,
    SelectionMode,
    Separator,
    Stack,
    Text,
    TooltipHost,
} from '@fluentui/react';
import {
    GetMissionMeasuresQuery,
    GetMeasureGroupsQuery,
} from '../../../data/types';
import MeasureArrow from '../../../components/MeasureArrow';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';
import { useViewport } from '../../../hooks/useViewport';
import {
    ExtractQueryArrayType,
    MeasureSeriesNames,
} from '../../../data/extendedTypes';
import dayjs from 'dayjs';
import CommentItem from '../../../components/CommentItem';
import { useThemes } from '../../../hooks/useThemes';

export type MissionMeasure = ExtractQueryArrayType<
    GetMissionMeasuresQuery,
    ['measures']
>;

function MeasureTable(props: {
    measureData: GetMissionMeasuresQuery;
    measuresGroupsData: GetMeasureGroupsQuery;
    numberOfMeasuresDisplayed: number;
    onMeasureClick: (measureId: string) => void;
}): JSX.Element | null {
    const { currentTheme } = useThemes();

    const groups = props.measuresGroupsData?.measureGroups?.length
        ? orderBy(
              [
                  ...(props.measuresGroupsData?.measureGroups || []).slice(),
                  {
                      id: null,
                      name: 'Ungrouped',
                      sequence: 99999,
                  },
              ],
              'sequence'
          )
        : [];

    const measures = orderBy((props.measureData?.measures || []).slice(), [
        'measureGroupId',
        'sequence',
    ]);

    const listGroup =
        groups.length > 0
            ? groups
                  .filter((g) =>
                      measures.some((m) => m.measureGroupId === g.id)
                  )
                  .map((g) => {
                      return {
                          key: g.id || '',
                          name: g.name,
                          startIndex: measures.findIndex(
                              (m) => m.measureGroupId === g.id
                          ),
                          count: measures.filter(
                              (m) => m.measureGroupId === g.id
                          ).length,
                      };
                  })
            : undefined;

    const renderItemColumn = (
        measure?: MissionMeasure,
        _index?: number,
        column?: IColumn
    ): JSX.Element => {
        if (!column || !measure) {
            return <span />;
        }

        const fieldContent = measure[
            column.fieldName as keyof MissionMeasure
        ] as React.ReactNode | string;

        let actualFormatted: string | null = null;

        if (measure && measure.lastAsOf) {
            measure?.lastAsOf?.values?.forEach((v) => {
                if (v?.seriesType?.name === 'Actual') {
                    actualFormatted = v?.formatStr;
                }
            });
        }

        switch (column.key) {
            case 'name':
                return (
                    <DetailsListCellItemContainer>
                        <Link
                            onClick={() =>
                                props.onMeasureClick(measure.id || '')
                            }
                        >
                            <Text variant="tiny">{measure.name}</Text>
                        </Link>
                    </DetailsListCellItemContainer>
                );

            case 'actual':
                return (
                    <DetailsListCellItemContainer textAlign="right">
                        <Text variant="tiny">{actualFormatted}</Text>
                    </DetailsListCellItemContainer>
                );
            case 'status':
                return (
                    <DetailsListCellItemContainer>
                        {!!measure.lastAsOf && (
                            <MeasureArrow
                                {...measure.lastAsOf}
                                isStatusLimited={measure.isStatusLimited}
                                showPercentage={false}
                                percentagePosition="Right"
                                iconFontSize={14}
                                textFontSize={12}
                            />
                        )}
                    </DetailsListCellItemContainer>
                );
            default:
                return (
                    <DetailsListCellItemContainer>
                        <Text variant="tiny">{fieldContent}</Text>
                    </DetailsListCellItemContainer>
                );
        }
    };

    const renderHeader = (
        groupDividerProps: IDetailsGroupDividerProps | undefined
    ) => {
        return (
            <GroupHeader
                {...groupDividerProps}
                groupLevel={0}
                // styles={{
                //     expandIsCollapsed: { display: 'none' },
                // }}
                onRenderTitle={(a) => {
                    return (
                        <Text
                            variant="small"
                            styles={{ root: { fontWeight: 'bold' } }}
                        >
                            {a?.group?.name}
                        </Text>
                    );
                }}
            />
        );
    };

    // const renderDetailsHeader = (
    //     detailsHeaderProps?: IDetailsHeaderProps,
    //     defaultRender?: IRenderFunction<IDetailsHeaderProps>
    // ): JSX.Element | null => {
    //     if (defaultRender && detailsHeaderProps) {
    //         const newProps = Object.assign({}, detailsHeaderProps);
    //         newProps.styles = {
    //             root: {
    //                 paddingTop: 0,
    //                 fontSize: currentTheme.fonts.tiny.fontSize,
    //             },
    //         };
    //         //  newProps.collapseAllVisibility = CollapseAllVisibility.visible;
    //         return defaultRender({ ...newProps });
    //     }
    //     return null;
    // };
    const renderRow = (
        detailsRowProps?: IDetailsRowProps
    ): JSX.Element | null => {
        const measure = detailsRowProps?.item as MissionMeasure;
        let statusColourBg: string;

        const hasTarget = measure.lastAsOf?.values?.some(
            (v) => v?.seriesType?.name === 'Target' && v?.formatStr
        );

        const hasActual = measure.lastAsOf?.values?.some(
            (v) => v?.seriesType?.name === 'Actual' && v?.formatStr
        );

        switch (measure.lastAsOf?.arrowColour) {
            case 'GREEN':
                statusColourBg = '#dff6dd';
                break;
            case 'RED':
                statusColourBg = '#fde7e9';
                break;
            default:
                statusColourBg = '#fff4ce';
                break;
        }

        if (!hasTarget || !hasActual) {
            statusColourBg = currentTheme.palette.neutralLighter;
        }

        const customStyles: Partial<IDetailsRowStyles> = {};
        if (detailsRowProps) {
            customStyles.root = {
                backgroundColor: !currentTheme.isInverted
                    ? statusColourBg
                    : currentTheme.palette.neutralLighterAlt,
                marginTop: 2,
                selectors: {
                    '&:hover': {
                        backgroundColor: !currentTheme.isInverted
                            ? statusColourBg
                            : currentTheme.palette.neutralLighterAlt,
                    },
                },
            };

            const measure = detailsRowProps.item as MissionMeasure;

            return (
                <TooltipHost
                    content={<MeasureTableTooltip measure={measure} />}
                >
                    <DetailsRow
                        {...detailsRowProps}
                        styles={customStyles}
                        indentWidth={0}
                    />
                </TooltipHost>
            );
        }
        return null;
    };

    const initialColumns = useMemo(
        () => [
            {
                key: 'name',
                name: 'Measure',
                fieldName: 'name',
                minWidth: 150,
                isMultiline: true,
            },
            {
                key: 'actual',
                name: 'Actual',
                fieldName: 'actual',
                minWidth: 55,
                maxWidth: 55,
            },
            {
                key: 'status',
                name: '',
                fieldName: 'status',
                minWidth: 16,
                maxWidth: 16,
            },
        ],
        []
    );

    const [columns, setColumns] = useState<IColumn[]>(initialColumns);

    const [containerWidth, setContainerWidth] = useState<number>(0);
    const { width: viewportWidth } = useViewport();

    const div = useMemo(
        () =>
            debounce((node) => {
                if (node !== null && viewportWidth) {
                    setContainerWidth(
                        Math.round(node.getBoundingClientRect().width)
                    );
                }
            }, 100),
        [viewportWidth]
    );

    const resize = function () {
        if (containerWidth > 0) {
            setColumns((c) => {
                const firstColumnWidth =
                    containerWidth -
                    60 -
                    sum(c.filter((_c, i) => i !== 0).map((c) => c.minWidth));
                return [
                    {
                        ...initialColumns[0],
                        minWidth: firstColumnWidth,
                    },
                    ...c.filter((_c, i) => i !== 0),
                ];
            });
        }
    };

    useEffect(resize, [containerWidth, initialColumns]);

    return (
        <div ref={div}>
            <DetailsList
                key={columns[0].minWidth} // Hack to force reload as the DetailsList doesn't resize correctly when CollapseAllVisibility.hidden (https://github.com/microsoft/fluentui/issues/12663)
                items={measures}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selectionMode={SelectionMode.none}
                constrainMode={ConstrainMode.unconstrained}
                groups={listGroup}
                groupProps={{
                    collapseAllVisibility: CollapseAllVisibility.hidden,
                    onRenderHeader: renderHeader,
                }}
                isHeaderVisible={false}
                compact
                onShouldVirtualize={(): boolean => false}
                columns={columns}
                onRenderItemColumn={renderItemColumn}
                onRenderRow={renderRow}
                skipViewportMeasures
            />
        </div>
    );
}

function MeasureTableTooltip(props: { measure: MissionMeasure }): JSX.Element {
    const { measure } = props;
    const latestComment = measure.lastComment;

    return (
        <Stack tokens={{ childrenGap: 4 }} style={{ minWidth: 200 }}>
            <Text variant="medium">{measure.name}</Text>

            {!!measure.lastAsOf && (
                <Separator styles={{ root: { padding: 0 } }} />
            )}

            {!!measure.lastAsOf && (
                <Stack horizontal tokens={{ childrenGap: 16 }}>
                    <Text variant="xLarge">
                        {
                            measure.lastAsOf?.values?.find(
                                (v) =>
                                    v?.seriesType?.name ===
                                    MeasureSeriesNames.Actual
                            )?.formatStr
                        }
                    </Text>
                    <Stack.Item align="end">
                        <MeasureArrow
                            arrowDirection={measure.lastAsOf?.arrowDirection}
                            arrowColour={measure.lastAsOf?.arrowColour}
                            statusValue={measure.lastAsOf?.statusValue}
                            values={measure.lastAsOf?.values}
                            isStatusLimited={measure.isStatusLimited}
                            showPercentage={true}
                            percentagePosition="Right"
                            iconFontSize={14}
                            textFontSize={12}
                        />
                    </Stack.Item>
                </Stack>
            )}

            {!!measure.lastAsOf && (
                <Text variant="small">
                    TARGET:{' '}
                    {measure.lastAsOf?.values?.find(
                        (v) => v?.seriesType?.name === MeasureSeriesNames.Target
                    )?.formatStr ?? 'N/A'}
                </Text>
            )}

            {!!measure.lastAsOf && (
                <Text variant="tiny">
                    AS OF:{' '}
                    {dayjs(measure.lastAsOf?.asOfDate).format('DD MMM YYYY')}
                </Text>
            )}

            {!!latestComment && <Separator styles={{ root: { padding: 0 } }} />}

            {!!latestComment && (
                <CommentItem
                    id={latestComment?.id}
                    authorName={latestComment?.username}
                    authorUserId={latestComment?.userId}
                    content={latestComment?.text}
                    dateTime={latestComment?.utcCreated}
                    maximumContentLines={1}
                />
            )}
        </Stack>
    );
}

export default React.memo(MeasureTable);
