import React, { CSSProperties, useCallback, useEffect, useState } from 'react';

import {
    Stack,
    Text,
    Shimmer,
    ShimmerElementsGroup,
    ShimmerElementType,
    Link,
    IconButton,
    mergeStyleSets,
    TooltipHost,
    getShade,
    Shade,
    getColorFromString,
    IColor,
    IRawStyle,
    PrimaryButton,
} from '@fluentui/react';

import dayjs from 'dayjs';

import HistoryChart, { HistoryChartV1 } from './HistoryChart';
import MeasureArrow from './MeasureArrow';

import {
    ArrowDirection,
    Arrows,
    ChartDisplay,
    FrequencyPeriods,
    MeasureTypes,
    Multipliers,
    StatusTypes,
    ValueTypes,
    useGetMeasureValueHistoryQuery,
} from '../data/types';
import { useMeasureWarning } from '../hooks/useMeasureWarning';
import WarningButton from './WarningButton';
import { Access } from '../data/extendedTypes';
import { AdvanceCard } from './AdvanceCard';
import { useStateContext } from '../services/contextProvider';
import { useLatestValueWarning } from '../hooks/useLatestValueWarning';
import { useThemes } from '../hooks/useThemes';
import {
    DragHandlerButtonMemo,
    DragHandlerButtonProps,
} from './DragHandlerButton';
import { MeasureStatusColours } from '../Colours';
import { getLabelForDate } from '../scenes/Measure/utils/measureUtils';
import { useMeasureStatusCalc } from '../hooks/useMeasureStatusCalc';
import { useFormatters } from '../hooks/useFormatters';
import CommentButton from './CommentButton';
import CommentItem from './CommentItem';
import MeasureCalcTooltip from '../scenes/MeasureFormulaEditor/components/MeasureCalcTooltip';
import { CommentTooltipHost } from './CommentTooltipHost';

export type MeasureCardEvent = {
    measureId: string;
};

export type MeasureCardProps = {
    id: string;
    missionId: string;
    name: string;
    description: string;
    values?:
        | {
              decimalValue: number | null;
              stringValue: string | null;
              dateValue: string | null;
              formatStr: string | null;
              seriesType: {
                  name: string | null;
              } | null;
          }[]
        | null;
    statusValue?: number;
    isStatusLimited: boolean;
    asOfDate?: string;
    frequencyPeriod: FrequencyPeriods;
    chartDisplay: ChartDisplay | null;
    measureType: MeasureTypes;
    statusType: StatusTypes;
    valueType: ValueTypes;
    valueFormula: string | null;
    arrowColour?: 'GREEN' | 'RED' | 'YELLOW' | 'NONE';
    arrowDirection?: 'UP' | 'DOWN' | 'SAME' | 'NONE';
    currency: { symbol: string | null } | null;
    decimalPlaces: number;
    multiplier: Multipliers | null;
    yellowStart: number;
    greenRange: number;
    yellowRange: number;
    isLinked: boolean;
    fullYearTarget: number | null;
    isFullYearTarget: boolean;
    linkedFromMeasure: {
        id: string | null;
        utcDeleted: string | null;
        name: string | null;
        mission: {
            utcDeleted: string | null;
            utcInactive: string | null;
        } | null;
        lastComment: {
            id: string | null;
            utcCreated: string;
            text: string | null;
            username: string | null;
            userId: string | null;
        } | null;
    } | null;
    latestAttachment?: {
        id: string;
    } | null;
    commentCount?: number;
    lastComment: {
        id: string | null;
        utcCreated: string;
        text: string | null;
        username: string | null;
        userId: string | null;
    } | null;
    isCustom: boolean;
    showForecast: boolean;
    onClick?: (event: MeasureCardEvent) => void;
    onEditMeasureActual?: (event: MeasureCardEvent) => void;
    onAttachmentsClick?: (event: MeasureCardEvent) => void;
    onCommentsClick?: (event: MeasureCardEvent) => void;
    onLinkClick?: (event: MeasureCardEvent) => void;
    isLoading?: boolean;
    missionAccess: Access;
    dragHandleButtonProps?: DragHandlerButtonProps;
    isCompact?: boolean;
    fyStartDate: string | undefined;
    fyEndDate: string | undefined;
};

function MeasureCard(props: MeasureCardProps): JSX.Element {
    const { currentTenantId } = useStateContext();
    const { currentTheme, currentThemeName } = useThemes();

    const { formatMeasureValue } = useFormatters();

    const { data, loading: valuesLoading } = useGetMeasureValueHistoryQuery({
        skip: !props.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            id: props.id || '',
            historyHasActual: false,
            historySkip: 0,
            historyTake: 12,
        },
    });

    const recentHistory = (data?.measure?.valueHistory || [])
        .slice()
        .sort((m1, m2) =>
            dayjs(m1.asOfDate || '').isAfter(m2.asOfDate || '') ? 1 : -1
        );

    const { getYtd, calcStatus, isCalcSupported } = useMeasureStatusCalc();

    let formattedTarget = null;
    let formattedActual = null;
    let formattedYtdTarget = null;
    let formattedYtdActual = null;
    let ytdStatus = null;
    let ytdStatusColour = undefined;
    const showFullYear = props.chartDisplay === ChartDisplay.YtdVsFullYear;

    if (
        props.valueType === ValueTypes.Incremental &&
        !props.isCustom &&
        props.asOfDate &&
        isCalcSupported(props.measureType)
    ) {
        const final = recentHistory.length ? recentHistory.at(-1) : null;

        const { actualYtd, targetYtd } = getYtd(
            showFullYear && final
                ? final
                : {
                      asOfDate: props.asOfDate,
                      values: props.values || [],
                  },
            data?.measure?.valueHistory || []
        );

        const { actualYtd: previousActualYtd, targetYtd: previousTargetYtd } =
            getYtd(
                showFullYear && final
                    ? final
                    : {
                          asOfDate: props.asOfDate,
                          values: props.values || [],
                      },
                data?.measure?.valueHistory || [],
                true
            );

        const measureFormat = {
            ...props,
            currencySymbol: props.currency?.symbol || null,
        };

        formattedYtdActual =
            actualYtd !== null
                ? formatMeasureValue(measureFormat, actualYtd)
                : null;

        formattedYtdTarget =
            targetYtd !== null
                ? formatMeasureValue(measureFormat, targetYtd)
                : null;

        const previousYtdStatus = calcStatus(
            previousTargetYtd,
            previousActualYtd,
            measureFormat
        );

        ytdStatus = calcStatus(
            targetYtd,
            actualYtd,
            measureFormat,
            previousYtdStatus.statusValue
        );

        switch (ytdStatus.arrowColour) {
            case 'GREEN':
                ytdStatusColour = MeasureStatusColours.onTarget;
                break;
            case 'RED':
                ytdStatusColour = MeasureStatusColours.offTarget;
                break;
            case 'YELLOW':
                ytdStatusColour = MeasureStatusColours.nearTarget;
                break;
        }
    }

    props.values?.forEach((v) => {
        if (v?.seriesType?.name === 'Target') {
            formattedTarget = v?.formatStr;
        } else if (v?.seriesType?.name === 'Actual') {
            formattedActual = v?.formatStr;
        }
    });

    const hasValues =
        formattedTarget ||
        formattedActual ||
        (recentHistory && recentHistory.length > 0);

    const showHistoryChart = !props.isCompact;
    const showActionBar = !props.isCompact;

    const valuesSectionHeight = 60;

    const chartHeight = 100;
    const chartNameHeight = 16;
    const historySectionHeight = chartHeight + chartNameHeight;

    const midSectionHeight = showHistoryChart
        ? historySectionHeight + valuesSectionHeight + 16
        : valuesSectionHeight;

    const handleAddValuesClick = () => {
        if (props.onEditMeasureActual) {
            props.onEditMeasureActual({ measureId: props.id });
        }
    };

    const handleEditValuesClick = () => {
        if (props.onEditMeasureActual) {
            props.onEditMeasureActual({ measureId: props.id });
        }
    };

    const classNames = mergeStyleSets({
        hint: {
            color: currentTheme.palette.themeLighter,
        },
        midSectionContainer: {
            height: midSectionHeight,
            justifyContent: 'center',
            alignItems: 'start',
            display: 'flex',
            backgroundImage: '',
        },
        midSectionRow: {
            flexGrow: 1,
            textAlign: 'center',
            minWidth: 0, // https://github.com/recharts/recharts/issues/172
            display: 'flex',
            flexDirection: 'column',
            gap: 0,
        },
        noValuesRow: {
            flexGrow: 1,
            textAlign: 'center',
            alignSelf: 'center',
        },
        chartBox: {
            minHeight: historySectionHeight,
            paddingTop: 4,
            backgroundColor: currentTheme.palette.neutralLighterAlt,
            // borderTopWidth: 1,
            // borderTopStyle: 'solid',
            // borderBottomWidth: 1,
            // borderBottomStyle: 'solid',
            // borderColor: currentTheme.palette.neutralLight,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'end',
        },
        customChartBox: {
            minHeight: historySectionHeight,
            paddingTop: 8,
            paddingLeft: 8,
            paddingRight: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'end',
        },
    });

    let cardStyle: CSSProperties = {};

    let statusColour: string | undefined = undefined;
    let lightStatusBackgroundColour: string | undefined = undefined;

    const onTargetColour = getColorFromString(MeasureStatusColours.onTarget);
    const offTargetColour = getColorFromString(MeasureStatusColours.offTarget);
    const nearTargetColour = getColorFromString(
        MeasureStatusColours.nearTarget
    );

    const getBackgroundColour = (
        statusColour: IColor | undefined
    ): string | undefined => {
        if (currentThemeName === 'Dark') {
            return currentTheme.palette.white;
        }

        return statusColour
            ? getShade(
                  statusColour,
                  currentThemeName === 'Dark' &&
                      statusColour === offTargetColour
                      ? Shade.Shade3
                      : currentThemeName === 'Dark'
                        ? Shade.Shade2
                        : Shade.Shade1,
                  currentThemeName === 'Dark'
              )?.str
            : undefined;
    };

    switch (props.arrowColour) {
        case 'GREEN':
            statusColour = MeasureStatusColours.onTarget;
            lightStatusBackgroundColour = getBackgroundColour(onTargetColour);
            break;
        case 'RED':
            statusColour = MeasureStatusColours.offTarget;
            lightStatusBackgroundColour = getBackgroundColour(offTargetColour);
            break;
        case 'YELLOW':
            statusColour = MeasureStatusColours.nearTarget;
            lightStatusBackgroundColour = getBackgroundColour(nearTargetColour);
            break;
    }

    if (!hasValues || formattedTarget === null || formattedActual === null) {
        statusColour = currentTheme.palette.neutralQuaternary;
        lightStatusBackgroundColour = currentTheme.palette.neutralLighter;
    }

    if (props.isCompact) {
        cardStyle = {
            ...cardStyle,
            borderLeftStyle: 'solid',
            borderLeftWidth: 4,
            backgroundColor: lightStatusBackgroundColour,
            borderLeftColor: statusColour,
        };
    }

    const [chartName, setChartName] = useState<string>();

    const handleChartNameChanged = (chartName: string) =>
        setChartName(chartName);

    return (
        <AdvanceCard style={cardStyle} childrenGap={4}>
            <AdvanceCard.Item>
                <HeaderArea {...props} />
            </AdvanceCard.Item>

            <AdvanceCard.Item fill>
                <Stack tokens={{ childrenGap: 0 }}>
                    <Shimmer
                        isDataLoaded={!props.isLoading}
                        customElementsGroup={
                            <div>
                                <ShimmerElementsGroup
                                    width="100%"
                                    shimmerElements={[
                                        {
                                            type: ShimmerElementType.line,
                                            height: valuesSectionHeight - 8,
                                            width: '100%',
                                        },
                                    ]}
                                />
                                {showHistoryChart && (
                                    <ShimmerElementsGroup
                                        width="100%"
                                        shimmerElements={[
                                            {
                                                type: ShimmerElementType.gap,
                                                height: 8,
                                                width: '100%',
                                            },
                                        ]}
                                    />
                                )}
                                {showHistoryChart && (
                                    <ShimmerElementsGroup
                                        width="100%"
                                        shimmerElements={[
                                            {
                                                type: ShimmerElementType.line,
                                                height:
                                                    historySectionHeight + 4,
                                                width: '100%',
                                            },
                                        ]}
                                    />
                                )}
                                {showHistoryChart && (
                                    <ShimmerElementsGroup
                                        width="100%"
                                        shimmerElements={[
                                            {
                                                type: ShimmerElementType.gap,
                                                height: 10,
                                                width: '100%',
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        }
                    >
                        <div className={classNames.midSectionContainer}>
                            {!hasValues ? (
                                <div className={classNames.noValuesRow}>
                                    {props.missionAccess.write &&
                                    !props.isLinked ? (
                                        <PrimaryButton
                                            text="Add Values..."
                                            onClick={handleAddValuesClick}
                                        />
                                    ) : (
                                        <Text
                                            variant="small"
                                            style={{ fontStyle: 'italic' }}
                                        >
                                            No value updates have been entered.
                                        </Text>
                                    )}
                                </div>
                            ) : (
                                <div className={classNames.midSectionRow}>
                                    <ValuesArea
                                        {...props}
                                        minHeight={valuesSectionHeight}
                                        onEditValuesClick={
                                            handleEditValuesClick
                                        }
                                        formattedTarget={formattedTarget}
                                        formattedActual={formattedActual}
                                        formattedYtdTarget={formattedYtdTarget}
                                        formattedYtdActual={formattedYtdActual}
                                        ytdStatus={ytdStatus}
                                        statusColour={statusColour}
                                        ytdStatusColour={ytdStatusColour}
                                        statusBackgroundColour={
                                            lightStatusBackgroundColour
                                        }
                                        ytdLabel={
                                            showFullYear ? 'Full Year' : 'YTD'
                                        }
                                        hideYtdStatusArrow={showFullYear}
                                    />

                                    {showHistoryChart && (
                                        <Shimmer
                                            isDataLoaded={!valuesLoading}
                                            customElementsGroup={
                                                <ShimmerElementsGroup
                                                    flexWrap={true}
                                                    width="100%"
                                                    shimmerElements={[
                                                        {
                                                            type: ShimmerElementType.line,
                                                            height:
                                                                historySectionHeight +
                                                                4,
                                                            width: '100%',
                                                        },
                                                    ]}
                                                />
                                            }
                                        >
                                            <div
                                                className={
                                                    !props.isCustom
                                                        ? classNames.chartBox
                                                        : classNames.customChartBox
                                                }
                                            >
                                                <div
                                                    style={{
                                                        paddingLeft: 8,
                                                        height: chartNameHeight,
                                                    }}
                                                >
                                                    <Text
                                                        variant="xSmall"
                                                        style={{
                                                            verticalAlign:
                                                                'bottom',
                                                        }}
                                                    >
                                                        {chartName}
                                                    </Text>
                                                </div>

                                                {props.isCustom ? (
                                                    <HistoryChartV1
                                                        measureId={props.id}
                                                        measureType={
                                                            props.measureType
                                                        }
                                                        status={
                                                            props.statusValue ||
                                                            0
                                                        }
                                                        isStatusLimited={
                                                            props.isStatusLimited
                                                        }
                                                        arrowColour={
                                                            formattedTarget &&
                                                            props.arrowColour
                                                                ? props.arrowColour
                                                                : 'NONE'
                                                        }
                                                        isCustom={
                                                            props.isCustom
                                                        }
                                                        frequencyPeriod={
                                                            props.frequencyPeriod
                                                        }
                                                        recentHistory={
                                                            recentHistory
                                                        }
                                                        limitHistoryCount={6}
                                                        width="100%"
                                                        height={chartHeight}
                                                        showTarget={false}
                                                        showTargetPhasing={
                                                            false
                                                        }
                                                        showAxis={false}
                                                        showLegend={false}
                                                        showForecast={
                                                            props.showForecast
                                                        }
                                                    />
                                                ) : (
                                                    <HistoryChart
                                                        width="100%"
                                                        height={chartHeight}
                                                        measure={{
                                                            ...props,
                                                            lastAsOf:
                                                                props.asOfDate
                                                                    ? {
                                                                          asOfDate:
                                                                              props.asOfDate,
                                                                      }
                                                                    : null,
                                                        }}
                                                        recentHistory={
                                                            recentHistory
                                                        }
                                                        onChartNameChanged={
                                                            handleChartNameChanged
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </Shimmer>
                                    )}
                                </div>
                            )}
                        </div>
                    </Shimmer>
                </Stack>
            </AdvanceCard.Item>

            {showActionBar && (
                <AdvanceCard.Item fill>
                    <ActionArea {...props} />
                </AdvanceCard.Item>
            )}
        </AdvanceCard>
    );
}

function HeaderArea(props: MeasureCardProps): JSX.Element {
    const handleMeasureNameClick = () => {
        if (props.onClick) {
            props.onClick({ measureId: props.id });
        }
    };

    const tooltipContent = () => {
        return (
            <div>
                {(props.description ?? '').split('\n').map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            </div>
        );
    };

    return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
            <Stack.Item grow align="stretch">
                <Link
                    onClick={handleMeasureNameClick}
                    title={props.name}
                    disabled={!props.missionAccess.read}
                    styles={{
                        root: {
                            verticalAlign: 'middle',
                        },
                    }}
                >
                    {props.name?.trim() &&
                        (props.description?.trim() ? (
                            <TooltipHost
                                id={`tooltip-${props.id}`}
                                tooltipProps={{
                                    onRenderContent: tooltipContent,
                                }}
                            >
                                <Text
                                    variant="mediumPlus"
                                    className="line-clamp2"
                                    block
                                >
                                    <div className="line-clamp2">
                                        {props.name}
                                    </div>
                                </Text>
                            </TooltipHost>
                        ) : (
                            <Text
                                variant="mediumPlus"
                                className="line-clamp2"
                                block
                            >
                                <div className="line-clamp2">{props.name}</div>
                            </Text>
                        ))}

                    {!props.isLoading && !props.name?.trim() && (
                        <Text
                            variant="mediumPlus"
                            className="line-clamp2"
                            styles={{
                                root: {
                                    fontStyle: 'italic',
                                },
                            }}
                        >
                            Enter measure name
                        </Text>
                    )}
                </Link>
            </Stack.Item>

            {props.isCompact && (
                <Stack.Item align="start">
                    <ActionArea {...props} />
                </Stack.Item>
            )}
        </Stack>
    );
}

function ActionArea(props: MeasureCardProps): JSX.Element {
    const { currentTheme } = useThemes();

    const warning = useMeasureWarning(props.missionId, props);

    const {
        id,
        onLinkClick,
        onCommentsClick,
        onEditMeasureActual,
        lastComment,
        linkedFromMeasure,
        isLinked,
    } = props;

    const [latestComment, setLatestComment] = useState<{
        id: string | null;
        utcCreated: string;
        text: string | null;
        username: string | null;
        userId: string | null;
    } | null>(null);

    const {
        hasWarning: hasLatestValueWarning,
        warningMessage: latestValueWarningMessage,
    } = useLatestValueWarning(props, {
        startDate: props.fyStartDate,
        endDate: props.fyEndDate,
    });

    const handleMeasureLinkClick = useCallback(() => {
        if (onLinkClick) {
            onLinkClick({ measureId: id });
        }
    }, [id, onLinkClick]);

    const handleCommentsClick = useCallback(() => {
        if (onCommentsClick) {
            onCommentsClick({ measureId: id });
        }
    }, [id, onCommentsClick]);

    const handleEditValuesClick = useCallback(() => {
        if (onEditMeasureActual) {
            onEditMeasureActual({ measureId: id });
        }
    }, [id, onEditMeasureActual]);

    useEffect(() => {
        // Use the latest comment from the linked measure if linked
        let comment =
            isLinked && linkedFromMeasure?.lastComment
                ? linkedFromMeasure?.lastComment
                : lastComment;

        // ... unless this one has a newer comment that may have been added to this measure directly.
        if (
            lastComment &&
            dayjs(lastComment.utcCreated).isAfter(comment?.utcCreated)
        ) {
            comment = lastComment;
        }

        setLatestComment(comment);
    }, [lastComment, linkedFromMeasure?.lastComment, isLinked]);

    // The latest comment preview takes a bit more vertical space.
    const height = props.isCompact ? 32 : 38;

    return (
        <Shimmer
            isDataLoaded={!props.isLoading}
            customElementsGroup={
                <ShimmerElementsGroup
                    flexWrap={true}
                    width="100%"
                    shimmerElements={[
                        {
                            type: ShimmerElementType.line,
                            height: height,
                            width: '100%',
                        },
                    ]}
                />
            }
        >
            <Stack
                horizontal
                verticalAlign="end"
                style={{ height: height, paddingLeft: 8, paddingRight: 8 }}
            >
                {latestComment && !props.isCompact ? (
                    <Stack.Item align="end" grow>
                        <CommentItem
                            id={latestComment?.id}
                            authorName={latestComment?.username}
                            authorUserId={latestComment?.userId}
                            content={latestComment?.text}
                            dateTime={latestComment?.utcCreated}
                            onClick={
                                props.missionAccess.read
                                    ? handleCommentsClick
                                    : undefined
                            }
                            maximumContentLines={1}
                        />
                    </Stack.Item>
                ) : (
                    <Stack.Item grow align="end">
                        {props.commentCount !== undefined && (
                            <CommentTooltipHost
                                content={latestComment?.text}
                                authorName={latestComment?.username}
                                dateTime={latestComment?.utcCreated}
                            >
                                <CommentButton
                                    commentCount={props.commentCount}
                                    onClick={handleCommentsClick}
                                />
                            </CommentTooltipHost>
                        )}
                    </Stack.Item>
                )}

                {hasLatestValueWarning &&
                    latestValueWarningMessage &&
                    !props.isLinked && (
                        <TooltipHost content={latestValueWarningMessage}>
                            <IconButton
                                iconProps={{
                                    iconName: 'AlertSolid',
                                }}
                                disabled={!props.missionAccess.read}
                                onClick={handleEditValuesClick}
                                styles={{
                                    root: {
                                        cursor: 'pointer',

                                        color: currentTheme.semanticColors
                                            .warningIcon,
                                    },
                                    rootHovered: {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            />
                        </TooltipHost>
                    )}

                {warning.hasWarning && props.missionAccess.read && (
                    <Stack.Item align="end" style={{ height: 32 }}>
                        <WarningButton
                            warning={warning}
                            showActions={props.missionAccess.write}
                        />
                    </Stack.Item>
                )}

                {props.isLinked && !warning.hasWarning && (
                    <Stack.Item align="end">
                        <IconButton
                            title="This measure is linked."
                            iconProps={{
                                iconName: 'Link',
                            }}
                            onClick={handleMeasureLinkClick}
                            disabled={!props.missionAccess.read}
                        />
                    </Stack.Item>
                )}

                {props.valueFormula && (
                    <Stack.Item align="end">
                        <MeasureCalcTooltip
                            measureId={props.id}
                            valueFormula={props.valueFormula}
                        >
                            <IconButton
                                iconProps={{
                                    iconName: 'Calculator',
                                }}
                            />
                        </MeasureCalcTooltip>
                    </Stack.Item>
                )}

                <Stack.Item align="end">
                    {!!props.dragHandleButtonProps &&
                        !props.dragHandleButtonProps.hidden && (
                            <DragHandlerButtonMemo
                                {...props.dragHandleButtonProps}
                            />
                        )}
                </Stack.Item>
            </Stack>
        </Shimmer>
    );
}

function ValuesArea(
    props: MeasureCardProps & {
        ytdLabel: string;
        minHeight: number;
        onEditValuesClick: () => void;
        formattedTarget: string | null;
        formattedActual: string | null;
        formattedYtdTarget: string | null;
        formattedYtdActual: string | null;
        hideYtdStatusArrow: boolean;
        ytdStatus: {
            statusValue: number | null;
            arrowColour: Arrows;
            arrowDirection: ArrowDirection;
        } | null;
        statusColour: string | undefined;
        ytdStatusColour: string | undefined;
        statusBackgroundColour: string | undefined;
    }
): JSX.Element {
    const { currentTheme } = useThemes();

    const {
        formattedTarget,
        formattedActual,
        formattedYtdTarget,
        formattedYtdActual,
    } = props;

    const { formatMeasureStatus } = useFormatters();

    const asOfDateFormatted = props.asOfDate
        ? dayjs(props.asOfDate).format('DD MMM YYYY')
        : '';

    const containerStyle: IRawStyle = {
        minHeight: props.minHeight,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 8,
        paddingRight: 8,
    };

    const rowStyle: IRawStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    };

    const actualTextAndWarningStyle: IRawStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: 4,
    };

    const classNames = mergeStyleSets({
        container: containerStyle,
        row: rowStyle,
        actualTextAndWarning: actualTextAndWarningStyle,
    });

    return (
        <div className={classNames.container}>
            <div className={classNames.row}>
                <div
                    style={{
                        flexGrow: 1,
                        flexBasis: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                    }}
                >
                    {props.isCustom ||
                    !props.frequencyPeriod ||
                    props.frequencyPeriod === FrequencyPeriods.None ? (
                        <Text variant="xSmall" block nowrap>
                            {`As of - ${asOfDateFormatted}`}
                        </Text>
                    ) : (
                        <Text variant="xSmall" block nowrap>
                            {props.asOfDate && props.fyStartDate
                                ? getLabelForDate(
                                      props.fyStartDate,
                                      props.frequencyPeriod,
                                      props.asOfDate,
                                      false
                                  )
                                : null}
                        </Text>
                    )}

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 4,
                            alignItems: 'center',
                        }}
                    >
                        <Link
                            onClick={props.onEditValuesClick}
                            disabled={!props.missionAccess.read}
                        >
                            <Text
                                variant="xLarge"
                                block
                                nowrap
                                style={{
                                    color: currentTheme.palette.neutralDark,
                                }}
                            >
                                {formattedActual !== null
                                    ? formattedActual
                                    : 'None'}
                            </Text>
                        </Link>

                        {!!formattedActual && !!formattedTarget && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <MeasureArrow
                                    arrowDirection={props.arrowDirection}
                                    arrowColour={props.arrowColour}
                                    statusValue={props.statusValue}
                                    isStatusLimited={props.isStatusLimited}
                                    values={props.values}
                                    iconFontSize={18}
                                />
                            </div>
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 4,
                        }}
                    >
                        {!!formattedTarget && (
                            <Text variant="tiny" block nowrap>
                                {`Target: ${formattedTarget}`}
                            </Text>
                        )}

                        {!!formattedActual &&
                            !!formattedTarget &&
                            props.statusValue !== undefined && (
                                <Text variant="tiny" block nowrap>
                                    ·
                                </Text>
                            )}

                        {!!formattedActual &&
                            !!formattedTarget &&
                            props.statusValue !== undefined && (
                                <Text variant="tiny" block nowrap>
                                    {`Status: ${formatMeasureStatus(
                                        props.statusValue,
                                        props.isStatusLimited
                                    )}`}
                                </Text>
                            )}
                    </div>
                </div>

                {props.valueType === ValueTypes.Incremental &&
                    !props.isCustom && (
                        <div
                            style={{
                                flexGrow: 1,
                                flexBasis: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                borderLeftWidth: 1,
                                borderLeftStyle: 'solid',
                                borderLeftColor:
                                    currentTheme.palette.neutralLight,
                                paddingLeft: 8,
                            }}
                        >
                            <Text variant="xSmall" block nowrap>
                                {props.ytdLabel}
                            </Text>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 4,
                                    alignItems: 'center',
                                }}
                            >
                                <Link
                                    onClick={props.onEditValuesClick}
                                    disabled={!props.missionAccess.read}
                                >
                                    <Text
                                        variant="xLarge"
                                        block
                                        nowrap
                                        style={{
                                            color: currentTheme.palette
                                                .neutralDark,
                                        }}
                                    >
                                        {formattedYtdActual !== null
                                            ? formattedYtdActual
                                            : 'None'}
                                    </Text>
                                </Link>

                                {!!formattedYtdActual &&
                                    !!formattedYtdTarget &&
                                    !props.hideYtdStatusArrow &&
                                    props.ytdStatus !== null && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <MeasureArrow
                                                arrowDirection={
                                                    props.ytdStatus
                                                        .arrowDirection
                                                }
                                                arrowColour={
                                                    props.ytdStatus.arrowColour
                                                }
                                                statusValue={
                                                    props.ytdStatus.statusValue
                                                }
                                                isStatusLimited={
                                                    props.isStatusLimited
                                                }
                                                values={props.values}
                                                iconFontSize={18}
                                            />
                                        </div>
                                    )}
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 4,
                                }}
                            >
                                {!!formattedYtdTarget && (
                                    <Text variant="tiny" block nowrap>
                                        {`Target: ${formattedYtdTarget}`}
                                    </Text>
                                )}

                                {!!formattedYtdActual &&
                                    !!formattedYtdTarget &&
                                    props.ytdStatus?.statusValue !==
                                        undefined && (
                                        <Text variant="tiny" block nowrap>
                                            ·
                                        </Text>
                                    )}

                                {!!formattedYtdActual &&
                                    !!formattedYtdTarget &&
                                    props.ytdStatus?.statusValue !==
                                        undefined && (
                                        <Text variant="tiny" block nowrap>
                                            {`Status: ${formatMeasureStatus(
                                                props.ytdStatus?.statusValue,
                                                props.isStatusLimited
                                            )}`}
                                        </Text>
                                    )}
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
}

// drag/drop causes rerender so memo... although the drag handler currently changes
export default React.memo(MeasureCard);
