import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import dayjs from 'dayjs';

import { EngagementTargetKeys, Engagements, engagements } from './Engagements';
import { sorters } from '../../data/sorters';

interface EngagementContextType {
    dismissedEngagements: string[];
    dismissEngagement: (key: string) => void;
    dismissEngagementForTarget: (
        targetKey: EngagementTargetKeys | undefined
    ) => void;
    activeEngagements: Engagements[];
}

const EngagementContext = createContext<EngagementContextType | undefined>(
    undefined
);

export const useEngagement = () => {
    const context = useContext(EngagementContext);
    if (!context) {
        throw new Error(
            'useEngagement must be used within a EngagementProvider'
        );
    }
    return context;
};

export function EngagementProvider(props: {
    children?: React.ReactNode;
}): JSX.Element {
    const dismissedFeaturesStorageKey = `ADVANCE_DISMISSEDFEATURES`;

    const getDismissedFeaturesFromStorage = () => {
        const stored = localStorage.getItem(dismissedFeaturesStorageKey);

        const keys: string[] = [];

        if (stored) {
            stored.split('|').forEach((k) => {
                if (k as string) {
                    keys.push(k as string);
                }
            });
        }

        return keys;
    };

    const [activeEngagements, setActiveEngagements] = useState<Engagements[]>(
        []
    );

    const [dismissedEngagements, setDismissedEngagements] = useState<string[]>(
        getDismissedFeaturesFromStorage()
    );

    const isEngagementDismissed = useCallback(
        (f: Engagements): boolean =>
            dismissedEngagements.some((k) => k === f.key),
        [dismissedEngagements]
    );

    useEffect(() => {
        const unDismissedEngagements = engagements
            .filter((e) => {
                return isEngagmentInDate(e) && !isEngagementDismissed(e);
            })
            .sort(sorters.sequenceSorter);

        const firstPopupOrAnnouncement = unDismissedEngagements.find(
            (e) => e.type === 'Announcement' || e.type === 'Popup'
        );

        const others = unDismissedEngagements.filter(
            (e) => e.type !== 'Announcement' && e.type !== 'Popup'
        );

        // Only one popup or announcement should be active at the same time.
        setActiveEngagements([
            ...(firstPopupOrAnnouncement ? [firstPopupOrAnnouncement] : []),
            ...others,
        ]);
    }, [dismissedEngagements, isEngagementDismissed]);

    const isEngagmentInDate = (f: Engagements): boolean => {
        return dayjs().isBetween(f.activeFrom, f.activeTo, 'day', '[]');
    };

    const dismissEngagement = (key: string) => {
        const engagement = key
            ? activeEngagements.find((e) => e.key === key)
            : null;

        if (engagement) {
            const updatedDismissedFeatures = [
                ...dismissedEngagements.filter((k) => k !== engagement.key),
                engagement.key,
            ];

            setDismissedEngagements(updatedDismissedFeatures);

            localStorage.setItem(
                dismissedFeaturesStorageKey,
                updatedDismissedFeatures.join('|')
            );
        }
    };

    const dismissEngagementForTarget = (
        targetKey: EngagementTargetKeys | undefined
    ) => {
        const engagement = targetKey
            ? activeEngagements.find((e) => e.targetKey === targetKey)
            : null;

        if (engagement) {
            dismissEngagement(engagement.key);
        }
    };

    return (
        <EngagementContext.Provider
            value={{
                dismissedEngagements,
                dismissEngagement,
                dismissEngagementForTarget,
                activeEngagements,
            }}
        >
            {props.children}
        </EngagementContext.Provider>
    );
}
