import React, { useState } from 'react';
import { Panel, PrimaryButton, Text, mergeStyleSets } from '@fluentui/react';
import { TemplateReportElementType } from '../TemplateReport';
import { NewAttachment } from '../../../components/NewAttachment';
import { AttachmentsList } from '../../../components/AttachmentsList';
import { useStateContext } from '../../../services/contextProvider';
import { useThemes } from '../../../hooks/useThemes';

export function TemplateReportImageElement(props: {
    templateReportId: string;
    element: TemplateReportElementType;
    onElementChanged: (
        updated: TemplateReportElementType,
        forceImmediateSave: boolean
    ) => void;
    isReadOnly: boolean;
}): JSX.Element {
    const { element } = props;

    const { currentTenantId, configuration } = useStateContext();

    const { currentTheme } = useThemes();

    const { storageFnUrl } = configuration;

    //const [title, setTitle] = useState(element.title);
    const [isUploadPanelOpen, setIsUploadPanelOpen] = useState(false);

    // const handleTitleUpdateClick = async (newTitle: string) => {
    //     setTitle(newTitle);
    //     props.onElementChanged(
    //         {
    //             ...props.element,
    //             title: newTitle,
    //         },
    //         true
    //     );
    // };

    const classNames = mergeStyleSets({
        title: {
            margin: 0,
        },
        container: {
            height: '100%',
            //paddingLeft: 8,
            //paddingRight: 8,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        imageContainer: {
            flex: 1,
        },
        image: {
            display: 'block',
            width: '100%',
            boxShadow: currentTheme.effects.elevation4,
            transition: 'box-shadow 0.5s ease',
            borderRadius: 8,
            ':hover': {
                boxShadow: currentTheme.effects.elevation16,
            },
        },
    });

    const handleAttachmentSelected = (attachmentId: string | null) => {
        props.onElementChanged(
            {
                ...props.element,
                attachmentId: attachmentId,
            },
            true
        );

        setIsUploadPanelOpen(false);
    };

    const handleAttachmentDeleted = (attachmentId: string | null) => {
        if (props.element.attachmentId === attachmentId) {
            props.onElementChanged(
                {
                    ...props.element,
                    attachmentId: null,
                },
                true
            );
        }
    };

    const imageUrl = element.attachmentId
        ? `${storageFnUrl}/api/Attachment?tenantId=${currentTenantId}&attachmentId=${element.attachmentId}`
        : null;

    return (
        <>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                }}
            >
                {!props.isReadOnly && (
                    <div
                        style={{
                            alignSelf: 'end',
                        }}
                        className="no-print"
                    >
                        <PrimaryButton
                            text="Upload Image..."
                            onClick={() => setIsUploadPanelOpen(true)}
                        />
                    </div>
                )}

                <div className={classNames.container}>
                    {/* {!!element.title && (
                            <div>
                                <EditibleText
                                    isRequired
                                    isReadOnly={props.isReadOnly}
                                    dialogTitle={'Edit Title'}
                                    text={title || ''}
                                    onUpdateClick={handleTitleUpdateClick}
                                >
                                    <h4 className={classNames.title}>
                                        {title}
                                    </h4>
                                </EditibleText>
                            </div>
                        )} */}
                    <div className={classNames.imageContainer}>
                        {imageUrl ? (
                            <img src={imageUrl} className={classNames.image} />
                        ) : (
                            <Text variant="medium">No image selected</Text>
                        )}
                    </div>
                </div>
            </div>
            <TemplateReportImageUploadPanel
                isOpen={isUploadPanelOpen}
                templateReportId={props.templateReportId}
                onDismiss={() => setIsUploadPanelOpen(false)}
                onAttachmentSelected={handleAttachmentSelected}
                onAttachmentDeleted={handleAttachmentDeleted}
            />
        </>
    );
}

function TemplateReportImageUploadPanel(props: {
    templateReportId: string;
    isOpen: boolean;
    onDismiss: () => void;
    onAttachmentSelected: (attachmentId: string | null) => void;
    onAttachmentDeleted: (attachmentId: string | null) => void;
}) {
    return (
        <Panel
            headerText="Upload Image"
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
        >
            <NewAttachment
                restrictUpload="Image"
                templateReportId={props.templateReportId}
            />

            <AttachmentsList
                templateReportId={props.templateReportId}
                showImagePreview
                allowSelection
                onAttachmentSelected={props.onAttachmentSelected}
                onAttachmentDeleted={props.onAttachmentDeleted}
            />
        </Panel>
    );
}
