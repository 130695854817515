import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
    ActionButton,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Stack,
} from '@fluentui/react';

import MeasureCard, {
    MeasureCardEvent,
    MeasureCardProps,
} from '../../../components/MeasureCard';

import { useStateContext } from '../../../services/contextProvider';
import {
    useGetMissionMeasuresQuery,
    useUpdateMeasureSequenceMutation,
    ArrowDirection,
    Arrows,
    MeasureTypes,
    FrequencyPeriods,
    StatusTypes,
    ValueTypes,
} from '../../../data/types';
import { Access } from '../../../data/extendedTypes';
import orderBy from 'lodash/orderBy';
import {
    DndContext,
    DragOverlay,
    KeyboardSensor,
    TouchSensor,
    MouseSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    rectSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GroupContainer } from './GroupContainer';
import { useSortableGroups } from '../../../hooks/useSortableGroups';
import {
    MeasureFlatList,
    MeasureFlatListItem,
} from '../../../components/MeasureFlatList';
import { AdvanceCard } from '../../../components/AdvanceCard';
import { useThemes } from '../../../hooks/useThemes';

type MeasureListProps = {
    missionId: string | undefined;
    measureSummary:
        | {
              id: string | null;
              name: string | null;
              sequence: number | null;
              measureGroupId: string | null;
          }[]
        | null;
    measureGroups:
        | {
              id: string | null;
              name: string | null;
              sequence: number;
          }[]
        | null;
    onMeasureCountChanged: (count: number) => void;
    onMeasureCardClick: (measureId: string) => void;
    onEditMeasureActualClick: (measureId: string) => void;
    onAttachmentsClick: (measureId: string) => void;
    onCommentsClick: (measureId: string) => void;
    onLinkClick: (measureId: string) => void;
    onAddNewMeasureButtonClick: (
        measureGroupId: string | null | undefined
    ) => void;
    missionAccess: Access;
    viewMode: 'Compact' | 'Tile' | 'List';
    fyStartDate: string | undefined;
    fyEndDate: string | undefined;
};

function SortableMeasure(props: {
    id: string | null;
    measureCardProps: MeasureCardProps;
    className: string;
    missionAccess: Access;
}): JSX.Element {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props.id || '',
    });

    return (
        <div
            ref={setNodeRef}
            className={props.className}
            style={{
                opacity: isDragging ? 0.4 : 1,
                transform: CSS.Transform.toString(transform),
                transition: transition || undefined,
            }}
        >
            <MeasureCard
                {...props.measureCardProps}
                dragHandleButtonProps={{
                    hidden: !props.missionAccess.write,
                    handleListeners: listeners,
                    handleAttributes: attributes,
                }}
            />
        </div>
    );
}

function MeasureList(props: MeasureListProps): JSX.Element {
    const { currentTheme } = useThemes();

    const { currentTenantId } = useStateContext();

    const { onMeasureCountChanged } = props;

    const [collapseGroups, setCollapsedGroups] = React.useState<
        (string | null)[]
    >([]);

    const { loading, data, error } = useGetMissionMeasuresQuery({
        skip: !props.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId || '',
        },
    });

    useEffect(() => {
        if (onMeasureCountChanged && !loading) {
            onMeasureCountChanged(data?.measures?.length || 0);
        }
    }, [data, loading, onMeasureCountChanged]);

    const [updateMeasureSequence] = useUpdateMeasureSequenceMutation();

    const {
        onMeasureCardClick,
        onEditMeasureActualClick,
        onAttachmentsClick,
        onCommentsClick,
        onLinkClick,
    } = props;

    const handleMeasureCardClick = useCallback(
        (event: MeasureCardEvent) => {
            onMeasureCardClick(event.measureId);
        },
        [onMeasureCardClick]
    );

    const handleEditMeasureActualClick = useCallback(
        (event: MeasureCardEvent) => {
            onEditMeasureActualClick(event.measureId);
        },
        [onEditMeasureActualClick]
    );

    const handleAttachmentsClick = useCallback(
        (event: MeasureCardEvent) => {
            onAttachmentsClick(event.measureId);
        },
        [onAttachmentsClick]
    );

    const handleCommentsClick = useCallback(
        (event: MeasureCardEvent) => {
            onCommentsClick(event.measureId);
        },
        [onCommentsClick]
    );

    const handleMeasureLinkClick = useCallback(
        (event: MeasureCardEvent) => {
            onLinkClick(event.measureId);
        },
        [onLinkClick]
    );

    const GetPropsForMeasure = (measureId: string): MeasureCardProps => {
        const name = props.measureSummary?.find(
            (m) => m.id === measureId
        )?.name;

        const fullMeasure = !loading
            ? data?.measures?.find((m) => m.id === measureId)
            : null;

        const measureCardProps: MeasureCardProps = {
            id: measureId,
            missionId: props.missionId || '',
            name: name || fullMeasure?.name || '',
            description: fullMeasure?.description || '',
            isLoading: !fullMeasure,
            missionAccess: props.missionAccess,
            isCustom: fullMeasure?.isCustom ?? true,
            onClick: handleMeasureCardClick,
            onEditMeasureActual: handleEditMeasureActualClick,
            onAttachmentsClick: handleAttachmentsClick,
            onCommentsClick: handleCommentsClick,
            onLinkClick: handleMeasureLinkClick,
            measureType: fullMeasure?.measureType || MeasureTypes.None,
            statusType: fullMeasure?.statusType || StatusTypes.None,
            valueType: fullMeasure?.valueType || ValueTypes.Simple,
            valueFormula: fullMeasure?.valueFormula || null,
            frequencyPeriod:
                fullMeasure?.frequencyPeriod || FrequencyPeriods.None,
            asOfDate: fullMeasure?.lastAsOf?.asOfDate || undefined,
            arrowColour: fullMeasure?.lastAsOf?.arrowColour || Arrows.Yellow,
            arrowDirection:
                fullMeasure?.lastAsOf?.arrowDirection || ArrowDirection.Same,
            statusValue: fullMeasure?.lastAsOf?.statusValue || 0,
            yellowStart: fullMeasure?.yellowStart || 9,
            isStatusLimited: fullMeasure?.isStatusLimited || false,
            isLinked: fullMeasure?.isLinked || false,
            linkedFromMeasure: fullMeasure?.linkedFromMeasure || null,
            lastComment: fullMeasure?.lastComment || null,
            showForecast: fullMeasure?.showForecast || false,
            chartDisplay: fullMeasure?.chartDisplay || null,
            currency: fullMeasure?.currency || null,
            decimalPlaces: fullMeasure?.decimalPlaces || 0,
            multiplier: fullMeasure?.multiplier || null,
            commentCount:
                (fullMeasure?.commentCount || 0) +
                (fullMeasure?.isLinked
                    ? fullMeasure?.linkedFromMeasure?.commentCount || 0
                    : 0),
            isCompact: props.viewMode === 'Compact',
            fyStartDate: props.fyStartDate,
            fyEndDate: props.fyEndDate,
            yellowRange: fullMeasure?.yellowRange || 0,
            greenRange: fullMeasure?.greenRange || 0,
            fullYearTarget: fullMeasure?.fullYearTarget ?? null,
            isFullYearTarget: fullMeasure?.isFullYearTarget ?? false,
        };

        if (fullMeasure) {
            if (fullMeasure.lastAsOf) {
                measureCardProps.values = fullMeasure?.lastAsOf?.values;
            }

            measureCardProps.latestAttachment = fullMeasure.lastAttachment
                ? {
                      id: fullMeasure?.lastAttachment?.id || '',
                  }
                : null;
        }

        return measureCardProps;
    };

    const classNames = mergeStyleSets({
        measureCards: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))',
            gridAutoRows:
                props.viewMode !== 'Compact'
                    ? 'minmax(236px, auto)'
                    : 'minmax(116px, auto)',
            gridAutoFlow: 'dense',
        },
        measureCard: {
            padding: 8,
            gridColumnEnd: 'span 1',
            gridRowEnd: 'span 1',
        },
    });

    const UNGROUPED_ID = 'UNGROUPED';
    const VOID_ID = 'void';

    type Items = Record<string, string[]>;
    const [items, setItems] = useState<Items>({});
    const [activeId, setActiveId] = useState<string | null>(null);

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: { y: 5, x: 5 },
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    React.useEffect(() => {
        const measures = orderBy(props.measureSummary || [], 'sequence');

        const groups = props.measureSummary?.find((m) => !m.measureGroupId)
            ? [
                  ...orderBy(props.measureGroups || [], 'sequence'),
                  {
                      id: UNGROUPED_ID,
                      name: 'Ungrouped',
                      sequence: 99999,
                  },
              ]
            : orderBy(props.measureGroups || [], 'sequence');

        const groupsAndMeasures: Items = {};

        groups.forEach((g) => {
            groupsAndMeasures[g.id || UNGROUPED_ID] = measures
                .filter((m) => (m.measureGroupId || UNGROUPED_ID) === g.id)
                .map((m) => m.id || VOID_ID);
        });

        setItems(groupsAndMeasures);
    }, [props.measureGroups, props.measureSummary]);

    const updateMeasureOrderAsync = async (
        measureId: string,
        measureGroupId: string | null,
        newIndex: number
    ): Promise<void> => {
        const sortedMeasures = orderBy(props.measureSummary, 'sequence');
        const movedItem = sortedMeasures.find((m) => m.id === measureId);

        if (sortedMeasures && movedItem) {
            const remainingItems = sortedMeasures.filter(
                (m) => m.id !== measureId && m.measureGroupId === measureGroupId
            );

            const reorderedItems = [
                ...remainingItems.slice(0, newIndex),
                movedItem,
                ...remainingItems.slice(newIndex),
            ];

            setItems((current) => {
                const updated: Items = {};

                Object.keys(current).forEach((currentGroupId) => {
                    const key = currentGroupId || UNGROUPED_ID;

                    updated[key as string] =
                        currentGroupId === (measureGroupId || UNGROUPED_ID)
                            ? reorderedItems.map((r) => r.id || '')
                            : current[currentGroupId as string];
                });

                return updated;
            });

            const measuresToUpdate = reorderedItems
                .filter(
                    (m, index) =>
                        m.sequence !== index ||
                        (m.measureGroupId || null) !== (measureGroupId || null)
                )
                .map((m) => {
                    return {
                        id: m.id,
                        newSequence: reorderedItems.indexOf(m),
                    };
                });

            await Promise.all(
                measuresToUpdate.map((measure) => {
                    return updateMeasureSequence({
                        variables: {
                            tenantId: currentTenantId || '',
                            measureId: measure.id || '',
                            sequence: measure.newSequence,
                            measureGroupId: measureGroupId || null,
                        },
                        optimisticResponse: {
                            __typename: 'Mutations',
                            measureSequenceUpdate: {
                                id: measure.id,
                                sequence: measure.newSequence,
                                measureGroupId: measureGroupId || null,
                                version: null,
                                __typename: 'MeasureQL',
                            },
                        },
                    });
                })
            );
        }
    };

    const { handleDragOver, handleDragEnd, handleDragStart, handleDragCancel } =
        useSortableGroups(
            items,
            setItems,
            setActiveId,
            updateMeasureOrderAsync
        );

    const onExpandGroup = (measureGroupId: string | null) => {
        setCollapsedGroups(
            collapseGroups.slice().filter((id) => id !== measureGroupId)
        );
    };

    const onCollapseGroup = (measureGroupId: string | null) => {
        setCollapsedGroups([
            ...collapseGroups.slice().filter((id) => id !== measureGroupId),
            measureGroupId,
        ]);
    };

    if (error) {
        return (
            <MessageBar messageBarType={MessageBarType.error}>
                {error.message}
            </MessageBar>
        );
    }

    const measurePlaceholerStyle: CSSProperties = {
        border: `dashed 2px ${currentTheme.palette.neutralLighterAlt}`,
        borderRadius: 8,
        minHeight: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 8,
    };

    if (props.viewMode === 'List') {
        const measuresForList: MeasureFlatListItem[] | undefined =
            data?.measures?.map((m) => {
                const group = m.measureGroupId
                    ? props.measureGroups?.find(
                          (mg) => mg.id === m.measureGroupId
                      )
                    : null;

                return {
                    ...m,
                    group: group || null,
                };
            });

        return (
            <div style={{ padding: 8 }}>
                <AdvanceCard style={{ padding: 0 }}>
                    <MeasureFlatList
                        {...props}
                        measures={measuresForList}
                        measuresLoading={loading}
                        defaultColumns={[
                            'displaySequence',
                            'name',
                            'target',
                            'actual',
                            'status',
                            'sparkline',
                            'asOfDate',
                        ]}
                        onMeasureClick={(_missionId, measureId) =>
                            props.onMeasureCardClick(measureId)
                        }
                    />
                </AdvanceCard>
            </div>
        );
    }

    return (
        <React.Fragment>
            {!props.measureSummary?.length &&
                !props.measureGroups?.length &&
                props.missionAccess.write && (
                    <div className={classNames.measureCards}>
                        <div
                            className={classNames.measureCard}
                            style={measurePlaceholerStyle}
                        >
                            <ActionButton
                                iconProps={{ iconName: 'Add' }}
                                text="Measure of Success"
                                onClick={() =>
                                    props.onAddNewMeasureButtonClick(null)
                                }
                            />
                        </div>
                    </div>
                )}

            <DndContext
                sensors={sensors}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
                onDragCancel={handleDragCancel}
            >
                {Object.keys(items).map((containerId) => {
                    const group = props.measureGroups?.find(
                        (g) => g.id === containerId
                    );

                    const isExpanded = !collapseGroups.some(
                        (id) => id === containerId
                    );

                    return (
                        <SortableContext
                            key={containerId}
                            items={items[`${containerId}`] || []}
                            strategy={rectSortingStrategy}
                        >
                            <GroupContainer
                                containerClassName={classNames.measureCards}
                                group={
                                    group
                                        ? group
                                        : {
                                              id: containerId,
                                              name: 'Ungrouped',
                                          }
                                }
                                showGroupName={
                                    (props.measureGroups || []).length > 0
                                }
                                isExpanded={isExpanded}
                                onExpandGroup={onExpandGroup}
                                onCollapseGroup={onCollapseGroup}
                            >
                                {items[`${containerId}`].map((measureId) => {
                                    const measureCardProps =
                                        GetPropsForMeasure(measureId);
                                    return (
                                        <SortableMeasure
                                            key={measureId}
                                            id={measureId}
                                            measureCardProps={measureCardProps}
                                            className={classNames.measureCard}
                                            missionAccess={props.missionAccess}
                                        />
                                    );
                                })}

                                {items[`${containerId}`].length === 0 &&
                                    props.missionAccess.write && (
                                        <div
                                            className={classNames.measureCard}
                                            style={measurePlaceholerStyle}
                                        >
                                            <Stack
                                                verticalAlign="center"
                                                horizontalAlign="center"
                                                styles={{
                                                    root: { height: '100%' },
                                                }}
                                            >
                                                <Stack.Item>
                                                    <ActionButton
                                                        iconProps={{
                                                            iconName: 'Add',
                                                        }}
                                                        text="Measure of Success"
                                                        onClick={() =>
                                                            props.onAddNewMeasureButtonClick(
                                                                group?.id
                                                            )
                                                        }
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                        </div>
                                    )}
                            </GroupContainer>
                        </SortableContext>
                    );
                })}
                {createPortal(
                    <DragOverlay>
                        {activeId ? (
                            <MeasureCard
                                key={`${activeId}_overlay`}
                                {...GetPropsForMeasure(activeId)}
                                dragHandleButtonProps={{
                                    hidden: false,
                                }}
                            />
                        ) : null}
                    </DragOverlay>,
                    document.body
                )}
            </DndContext>
        </React.Fragment>
    );
}

export default React.memo(MeasureList);
