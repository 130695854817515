import * as React from 'react';
import {
    Modal,
    mergeStyleSets,
    IconButton,
    IButtonStyles,
    IDragOptions,
    ContextualMenu,
} from '@fluentui/react';
import { useThemes } from '../../hooks/useThemes';
import Iframe from 'react-iframe';
import Loading from '../Loading';
import { useCallback, useState } from 'react';

export function useLearnMoreContent(tenantCode: string | undefined): {
    isEnabled: boolean;
    embedUrl: string | null;
} {
    // TODO: Store this somewhere
    let embedUrl: string | null = null;

    switch (tenantCode?.toUpperCase()) {
        case 'TOPSOE':
            embedUrl =
                'https://mckinneyrogers.instilled.com/containers/2441466711291991570?embed=true&username=topsoe&accessKey=f2c27dd16b924896897fd07d0e99c5f8&auth=querystring';
            break;
        case 'ATNI':
            embedUrl =
                'https://mckinneyrogers.instilled.com/containers/2409658422489257716?embed=true&username=atn&accessKey=06770abe4fee44db8529c6dbba5e31cb&auth=querystring';
            break;
        case 'GPS':
            embedUrl =
                'https://mckinneyrogers.instilled.com/containers/2393783111696520634?embed=true&username=mckinneyrogers&accessKey=553c60daafd54358a647be0187c9fd1d&auth=querystring';
            break;
        case 'HEINEKENIR':
            embedUrl =
                'https://mckinneyrogers.instilled.com/containers/2613193614498469236?embed=true&username=heinekenireland&accessKey=56f17620c1c340cab39e8e9a0196a2af&auth=querystring';
            break;
        case 'EXAMPLE':
            embedUrl =
                'https://mckinneyrogers.instilled.com/containers/2393783111696520634?embed=true&auth=invitee';
            break;
    }

    return {
        isEnabled: !!embedUrl,
        embedUrl: embedUrl,
    };
}

export function LearnMoreModal(props: {
    tenantCode: string | undefined;
    isOpen: boolean;
    onDismiss: () => void;
}): JSX.Element {
    const { currentTheme } = useThemes();

    const learnMoreContent = useLearnMoreContent(props.tenantCode);

    const [isMinimised, setIsMinimised] = useState(false);
    const [contentReloadKey, setContentReloadKey] = useState(0);

    const contentStyles = mergeStyleSets({
        container: {
            width: isMinimised ? 300 : '100%',
            height: isMinimised ? 200 : '100%',
        },
        header: {
            borderTop: `4px solid ${currentTheme.palette.themePrimary}`,
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'end',
            padding: '12px 12px 6px 24px',
        },

        body: {
            paddingLeft: isMinimised ? 4 : 20,
            paddingRight: isMinimised ? 4 : 20,
            height: isMinimised ? 'calc(100% - 58px)' : 'calc(100% - 74px)',
        },
        scrollableContent: {
            position: 'absolute',
            margin: 0,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
        },
        iFrameStyle: {
            display: 'block',
            width: '100%',
            height: '100%',
        },
    });

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: currentTheme.palette.neutralPrimary,
        },
        rootHovered: {
            color: currentTheme.palette.neutralDark,
        },
    };

    const dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
        keepInBounds: !isMinimised,
    };

    const handleMinimise = useCallback(
        () => setIsMinimised(!isMinimised),
        [isMinimised]
    );

    const handleReload = useCallback(() => {
        setContentReloadKey(contentReloadKey + 1);
        setIsMinimised(false);
    }, [contentReloadKey]);

    return (
        <Modal
            isOpen={props.isOpen}
            dragOptions={dragOptions}
            onDismiss={props.onDismiss}
            containerClassName={contentStyles.container}
            scrollableContentClassName={contentStyles.scrollableContent}
            isModeless={isMinimised}
            styles={{
                main: {
                    // Fix for modal position after maximise
                    transform: !isMinimised
                        ? 'translate(0px) !important;'
                        : undefined,
                },
            }}
        >
            <div className={contentStyles.header}>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{
                        iconName: 'Home',
                    }}
                    title="Home"
                    ariaLabel={'Home'}
                    onClick={handleReload}
                />

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{
                        iconName: isMinimised ? 'MiniExpand' : 'MiniContract',
                    }}
                    ariaLabel={isMinimised ? 'Expand' : 'Contract'}
                    onClick={handleMinimise}
                />

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close popup modal"
                    onClick={props.onDismiss}
                />
            </div>
            <div className={contentStyles.body}>
                <LearnMoreContent
                    key={`CONTENT_${contentReloadKey}`}
                    url={learnMoreContent.embedUrl}
                />
            </div>
        </Modal>
    );
}

const LearnMoreContent = React.memo(function LearnMoreContentFrame(props: {
    url: string | null;
}): JSX.Element {
    const { url } = props;

    const classNames = mergeStyleSets({
        iFrameStyle: {
            width: '100%',
            height: '100%',
        },
    });

    return url ? (
        <Iframe
            id="learnModeIframe"
            url={url}
            className={classNames.iFrameStyle}
            frameBorder={0}
        />
    ) : (
        <Loading />
    );
});
