import React, { useEffect, useState } from 'react';
import {
    mergeStyleSets,
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    MessageBar,
    MessageBarType,
    DetailsListLayoutMode,
    SelectionMode,
    Spinner,
    SpinnerSize,
    IColumn,
    ChoiceGroup,
    ShimmeredDetailsList,
    Checkbox,
} from '@fluentui/react';
import {
    FrequencyPeriods,
    GetMeasureQuery,
    MeasureTypes,
    PhaseType,
    ValueTypes,
    refetchGetMeasureValueHistoryQuery,
    useFastUpdateMeasureAsOfMutation,
    useGetFinancialYearQuery,
    useRecalculateMeasureMutation,
    useUpdateMeasureMutation,
} from '../../../data/types';
import {
    CombineMethod,
    MonthRow,
    isSeriesValueEmpty,
    createSeriesValue,
    getLabelFreq,
} from '../utils/measureUtils';
import {
    ExtractQueryType,
    MeasureSeriesNames,
} from '../../../data/extendedTypes';
import { useStateContext } from '../../../services/contextProvider';
import dayjs from 'dayjs';
import { useInputMappers } from '../../../hooks/useInputMappers';
import { useMeasureValueFormatter } from '../../../hooks/useMeasureValueFormatter';
import { useMeasureUpgradeData } from '../hooks/useMeasureUpgradeData';

export function MeasureUpgradePreviewDialog(props: {
    measure: ExtractQueryType<GetMeasureQuery, ['measure']>;
    selectedFrequency: FrequencyPeriods;
    hideDialog: boolean;
    toggleShowDialog: () => void;
    onBack: () => void;
    onUpgradeCompleted: () => void;
}) {
    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const { selectedFrequency, measure } = props;

    const [combineMethod, setCombineMethod] = useState<CombineMethod>('latest');
    const [isYtd, setIsYtd] = useState(false);

    useEffect(() => {
        setCombineMethod('latest');
        setIsYtd(
            measure.isCustom && measure.phaseType === PhaseType.Cumulative
        );
    }, [selectedFrequency]);

    const measureValueFormatter = useMeasureValueFormatter(props.measure);

    const isMeasureV1 = props.measure?.isCustom;
    const isMeasureV2 = !isMeasureV1;

    const { data: fyData } = useGetFinancialYearQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: currentFinancialYearCode || '',
        },
    });

    const fyStartDate = fyData?.financialYear?.startDate.substring(0, 10);

    const { getMeasureInput } = useInputMappers();

    let labelTitle = 'As Of';
    let intervalTitle = 'Target';

    if (selectedFrequency === FrequencyPeriods.Month) {
        labelTitle = 'Month';
        intervalTitle = 'Target';
    } else if (selectedFrequency === FrequencyPeriods.Quarter) {
        labelTitle = 'Quarter';
        intervalTitle = 'Target';
    } else if (selectedFrequency === FrequencyPeriods.Year) {
        labelTitle = 'Year';
        intervalTitle = 'Target';
    }

    const measureId = measure.id;
    const isNumberMeasureType =
        measure?.measureType === MeasureTypes.Numeric ||
        measure?.measureType === MeasureTypes.Currency ||
        measure?.measureType === MeasureTypes.Percentage;

    // Is this measure being expanded out?
    const isExpandingOut =
        (measure.frequencyPeriod === FrequencyPeriods.Year &&
            selectedFrequency === FrequencyPeriods.Month) ||
        (measure.frequencyPeriod === FrequencyPeriods.Quarter &&
            selectedFrequency === FrequencyPeriods.Month) ||
        (measure.frequencyPeriod === FrequencyPeriods.Year &&
            selectedFrequency === FrequencyPeriods.Month) ||
        (measure.frequencyPeriod === FrequencyPeriods.Year &&
            selectedFrequency === FrequencyPeriods.Quarter);

    const isCombineMethodFixed =
        selectedFrequency === FrequencyPeriods.None ||
        !isNumberMeasureType ||
        isExpandingOut;

    const allowYtd =
        isNumberMeasureType &&
        props.selectedFrequency !== FrequencyPeriods.None &&
        (measure.isCustom || measure.frequencyPeriod === FrequencyPeriods.None);

    const data = useMeasureUpgradeData(
        measure,
        fyStartDate,
        selectedFrequency,
        isCombineMethodFixed ? 'latest' : combineMethod,
        allowYtd ? isYtd : false
    );

    const [
        updateMeasure,
        { loading: isSavingMeasure, error: saveMeasureError },
    ] = useUpdateMeasureMutation();

    const [
        fastUpdateMeasureAsOf,
        { loading: isSavingValue, error: saveValueError },
    ] = useFastUpdateMeasureAsOfMutation();

    const [recalculateMeasure, { loading: isRecalculating }] =
        useRecalculateMeasureMutation();

    const saveAsOf = async (row: MonthRow) => {
        const formattedAsOfDate = dayjs(row.dt).format('YYYY-MM-DD');
        const asOf = row.asOf;

        if (!asOf) {
            return;
        }

        const input = {
            id: asOf.id,
            measureId: measureId || '',
            asOfDate: formattedAsOfDate,
            version: asOf.version,
            values: asOf.values || [],
        };

        const phasedTarget = (asOf?.values || []).find(
            (v) => v.seriesType?.name === MeasureSeriesNames.PhasedTarget
        );

        // Clear out any phasing after upgrade so it doesn't interfer with future conversions.
        if (phasedTarget) {
            phasedTarget.decimalValue = null;
        } else if (asOf.id) {
            input.values.push(
                createSeriesValue(MeasureSeriesNames.PhasedTarget)
            );
        }

        if (!input.id && !asOf.values?.some((v) => !isSeriesValueEmpty(v))) {
            return;
        }

        await fastUpdateMeasureAsOf({
            variables: {
                tenantId: currentTenantId || '',
                input: input,
            },
        });
    };

    const handleSaveClick = async () => {
        const toDelete = (data?.unused || [])
            .slice()
            .sort((a, b) => dayjs(b.dt).valueOf() - dayjs(a.dt).valueOf());

        const toSave = (data?.rows || [])
            .slice()
            .filter(
                (asOf) =>
                    asOf.asOf?.id ||
                    asOf.asOf?.values?.some(
                        (v) =>
                            v.decimalValue !== null ||
                            v.stringValue !== null ||
                            v.dateValue !== null
                    )
            )
            .sort((a, b) => dayjs(b.dt).valueOf() - dayjs(a.dt).valueOf());

        // We need to delete first as if the asof dates match any where are updating, bad things happen.
        const deletePromises: Promise<void>[] = [];
        for (const unused of toDelete) {
            if (unused.asOf?.id) {
                for (const value of unused.asOf.values ?? []) {
                    value.decimalValue = null;
                    value.stringValue = null;
                    value.dateValue = null;
                }
                deletePromises.push(saveAsOf(unused));
            }
        }

        await Promise.all(deletePromises);

        await Promise.all(toSave.map((asOf) => saveAsOf(asOf)));

        await updateMeasure({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    ...getMeasureInput(measure),
                    isCustom: false,
                    frequencyPeriod: selectedFrequency,
                    valueType:
                        selectedFrequency === FrequencyPeriods.Year
                            ? ValueTypes.Simple
                            : isYtd
                              ? ValueTypes.Incremental
                              : measure.valueType,
                },
            },
        });

        await recalculateMeasure({
            awaitRefetchQueries: true,
            variables: {
                id: measureId || '',
                tenantId: currentTenantId || '',
            },
            refetchQueries: [
                refetchGetMeasureValueHistoryQuery({
                    tenantId: currentTenantId || '',
                    id: measure.id || '',
                    historyHasActual: false,
                    historySkip: 0,
                    historyTake: 12,
                }),
            ],
        });

        props.toggleShowDialog();
        props.onUpgradeCompleted();
    };

    let columns: IColumn[] = [
        {
            key: 'label',
            name: labelTitle,
            minWidth: 40,
            maxWidth: 200,
            isResizable: true,
            onRender: (d: MonthRow) =>
                selectedFrequency === FrequencyPeriods.None
                    ? d.dt
                    : d.label ||
                      getLabelFreq(d.index, selectedFrequency, d.dt, true),
        },
        {
            key: 'interval',
            name: intervalTitle,
            minWidth: 0,
            maxWidth: 100,
            isResizable: true,
            onRender: (d: MonthRow) =>
                isNumberMeasureType
                    ? measureValueFormatter.formatDecimalValue(
                          d.interval?.decimalValue
                      )
                    : d.interval.stringValue || d.interval.dateValue || '',
        },
        {
            key: 'actual',
            name: 'Actual',
            minWidth: 0,
            maxWidth: 100,
            isResizable: true,
            onRender: (d: MonthRow) =>
                isNumberMeasureType
                    ? measureValueFormatter.formatDecimalValue(
                          d.actual.decimalValue
                      )
                    : d.actual.stringValue || d.actual.dateValue || '',
        },
    ];

    if (isYtd) {
        columns.splice(2, 0, {
            key: 'ytd',
            name: 'Target (YTD)',
            minWidth: 0,
            maxWidth: 100,
            isResizable: true,
            fieldName: 'ytd',
            onRender: (d: MonthRow) =>
                d.ytd ? measureValueFormatter.formatDecimalValue(d.ytd) : '',
        });

        columns.push({
            key: 'actualYtd',
            name: 'Actual (YTD)',
            minWidth: 0,
            maxWidth: 100,
            isResizable: true,
            fieldName: 'actualYtd',
            onRender: (d: MonthRow) =>
                d.actualYtd
                    ? measureValueFormatter.formatDecimalValue(d.actualYtd)
                    : '',
        });
    }

    if (selectedFrequency === FrequencyPeriods.None) {
        columns = columns.filter((col) => col.key !== 'ytd');
    }

    const timeIntervalLabel =
        selectedFrequency === FrequencyPeriods.Month
            ? 'month'
            : selectedFrequency === FrequencyPeriods.Quarter
              ? 'quarter'
              : selectedFrequency === FrequencyPeriods.Year
                ? 'year'
                : 'Interval';

    const classNames = mergeStyleSets({
        messageBar: { marginTop: '12px' },
        form: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
    });

    return (
        <Dialog
            hidden={props.hideDialog}
            onDismiss={props.toggleShowDialog}
            minWidth="60vw"
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: isMeasureV2 ? 'Convert Frequency' : 'Upgrade Measure',
                closeButtonAriaLabel: 'Close',
            }}
            modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 450 } },
            }}
        >
            {data?.unused.length ? (
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    className={classNames.messageBar}
                    isMultiline
                >
                    <div>
                        {isMeasureV1 ? (
                            <div>
                                <div>
                                    Data loss is possible when upgrading
                                    measures into the new interval-based
                                    containers. Before proceeding, review and
                                    validate your data within the preview table
                                    below.
                                </div>
                                <div>
                                    If necessary, all value records can be
                                    preserved by choosing Intermittent on the
                                    previous page - values can then be edited
                                    accordingly after the upgrade is complete.
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div>
                                    Data loss is possible when converting
                                    measures into a new interval-based
                                    container. Before proceeding, review and
                                    validate your data within the preview table
                                    below.
                                </div>
                                <div>
                                    If necessary, all value records can be
                                    preserved by cancelling this conversion.
                                </div>
                            </div>
                        )}
                    </div>
                </MessageBar>
            ) : (
                <MessageBar
                    messageBarType={MessageBarType.info}
                    className={classNames.messageBar}
                >
                    Values can be edited once the upgrade is complete
                </MessageBar>
            )}

            <div className={classNames.form}>
                {!isCombineMethodFixed && (
                    <ChoiceGroup
                        // label={`If multiple values are present for the same ${timeIntervalLabel}:`}
                        selectedKey={combineMethod}
                        options={[
                            {
                                key: 'latest',
                                text: `Use the most recent values in each ${timeIntervalLabel}`,
                                onRenderField: (props, render) => {
                                    if (!render) {
                                        return null;
                                    }
                                    return (
                                        <div>
                                            {render(props)}
                                            {allowYtd && (
                                                <div
                                                    style={{
                                                        padding: 6,
                                                        paddingLeft: 28,
                                                    }}
                                                >
                                                    <Checkbox
                                                        label="Source values are aggregated (YTD)"
                                                        checked={isYtd}
                                                        disabled={
                                                            combineMethod ===
                                                            'add'
                                                        }
                                                        onChange={(
                                                            _ev,
                                                            checked
                                                        ) =>
                                                            setIsYtd(!!checked)
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                },
                            },
                            {
                                key: 'add',
                                text: `Use the sum of the values in each ${timeIntervalLabel}`,
                            },
                        ]}
                        onChange={(_ev, option) => {
                            const selected = option?.key as CombineMethod;
                            setCombineMethod(selected);
                            if (selected === 'add') {
                                setIsYtd(false);
                            }
                        }}
                    />
                )}
            </div>

            <ShimmeredDetailsList
                compact
                enableShimmer={
                    isSavingMeasure || isSavingValue || isRecalculating
                }
                shimmerLines={data?.rows.length}
                items={data?.rows || []}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                ariaLabelForGrid="Item details"
                onShouldVirtualize={(): boolean => false}
            />

            {saveMeasureError && (
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    className={classNames.messageBar}
                >
                    {saveMeasureError.message}
                </MessageBar>
            )}

            {saveValueError && (
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    className={classNames.messageBar}
                >
                    {saveValueError.message}
                </MessageBar>
            )}

            <DialogFooter
                styles={{
                    action: {
                        width: '100%',
                    },
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <DefaultButton
                            onClick={props.onBack}
                            text="Back"
                            iconProps={{
                                iconName: 'Back',
                            }}
                        />
                    </div>

                    <div>
                        <PrimaryButton
                            onClick={handleSaveClick}
                            disabled={isSavingMeasure || isSavingValue}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                            {(isSavingMeasure || isSavingValue) && (
                                <Spinner
                                    size={SpinnerSize.xSmall}
                                    style={{
                                        marginLeft: '4px',
                                    }}
                                />
                            )}
                        </PrimaryButton>

                        <DefaultButton
                            onClick={props.toggleShowDialog}
                            text="Cancel"
                        />
                    </div>
                </div>
            </DialogFooter>
        </Dialog>
    );
}
