import React, { useState } from 'react';

import { Stack, TextField, Toggle } from '@fluentui/react';
import { EditPanel } from '../../../components/shared/EditPanel';
import useForm from '../../../hooks/useForm';
import {
    useDeleteTenantMutation,
    useUpdateTenantMutation,
} from '../../../data/types';
import DeleteModal from '../../../components/shared/DeleteModal';

export type TenantEditPanelProps = {
    showPanel: boolean;
    tenant?: {
        id: string | null;
        code: string | null;
        description: string | null;
        isEnabled: boolean;
        isAnalyticsEnabled: boolean;
    } | null;
    onCancel: () => void;
    onUpdate: () => void;
    onCreate: () => void;
    onDelete: () => void;
};

export function TenantEditPanel(props: TenantEditPanelProps): JSX.Element {
    const [showPanel, setShowPanel] = useState(false);
    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);

    type formValuesType = {
        code: string;
        description: string;
        isEnabled: boolean;
        isAnalyticsEnabled: boolean;
    };

    const [updateTenant, { loading: isSaving, error: saveError }] =
        useUpdateTenantMutation({
            onCompleted: () => {
                if (props.tenant?.id) {
                    props.onUpdate();
                } else {
                    props.onCreate();
                }
            },
        });

    const [deleteTenant, { loading: isDeleting, error: deleteError }] =
        useDeleteTenantMutation({
            variables: {
                tenantId: props.tenant?.id || '',
            },
            onCompleted: () => {
                setIsConfirmingDelete(false);
                if (props.onDelete) {
                    props.onDelete();
                }
            },
        });

    const formDefaultValues: formValuesType = {
        code: props.tenant?.code || '',
        description: props.tenant?.description || '',
        isEnabled: props.tenant?.isEnabled || false,
        isAnalyticsEnabled: props.tenant?.isAnalyticsEnabled || true,
    };

    const formValidate = (
        values: formValuesType
    ): {
        [key: string]: string;
    } => {
        const formErrorMessages = {
            code: '',
            description: '',
        };

        if (!values.code) {
            formErrorMessages.code = 'Enter a code';
        }

        if (!values.description) {
            formErrorMessages.description = 'Enter a description';
        }

        return formErrorMessages;
    };

    const formCallback = (values: formValuesType): void => {
        updateTenant({
            variables: {
                input: {
                    id: props.tenant?.id || null,
                    ...values,
                },
            },
        });
    };

    const { errors, handleChange, handleSubmit, values, updateValue, reset } =
        useForm(formDefaultValues, formCallback, formValidate);

    React.useEffect(() => {
        if (props.showPanel !== showPanel) {
            reset();
            setShowPanel(props.showPanel);
            setIsConfirmingDelete(false);
        }
    }, [props.showPanel, reset, showPanel]);

    const isValid = true;
    const saveErrorMessage = saveError?.message;

    return (
        <EditPanel
            activeViewName="TenantEdit"
            onDismiss={props.onCancel}
            showPanel={showPanel}
            headerText={props.tenant?.id ? 'Edit Tenants' : 'New Tenant'}
            isSaving={isSaving}
            isValid={isValid}
            saveErrorMessage={saveErrorMessage}
            onUpdateClick={handleSubmit}
            onDeleteClick={(): void => {
                setIsConfirmingDelete(true);
            }}
        >
            <DeleteModal
                activeViewName="TenantDelete"
                isOpen={isConfirmingDelete}
                onDismiss={(): void => setIsConfirmingDelete(false)}
                message={
                    'Are you sure you want to delete this tenant? The record will be physically deleted. Any associated mission data will not be deleted.'
                }
                deleteAction={async (): Promise<void> => {
                    await deleteTenant();
                }}
                isDeleting={isDeleting}
                error={deleteError}
            />

            <Stack tokens={{ childrenGap: 8 }}>
                <TextField
                    label="Code"
                    required
                    name="code"
                    autoComplete="off"
                    value={values.code || ''}
                    errorMessage={errors.code}
                    onChange={handleChange}
                />

                <TextField
                    label="Description"
                    required
                    name="description"
                    autoComplete="off"
                    value={values.description || ''}
                    errorMessage={errors.description}
                    onChange={handleChange}
                />

                <Toggle
                    label="Enabled"
                    onText="Yes"
                    offText="No"
                    checked={values.isEnabled}
                    onChange={(
                        ev: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                    ): void => {
                        updateValue('isEnabled', checked || false);
                    }}
                />

                <Toggle
                    label="Analytics Enabled"
                    onText="Yes"
                    offText="No"
                    checked={values.isAnalyticsEnabled}
                    onChange={(
                        ev: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                    ): void => {
                        updateValue('isAnalyticsEnabled', checked || false);
                    }}
                />
            </Stack>
        </EditPanel>
    );
}
