import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
    CommandBar,
    ICommandBarItemProps,
    IPersonaSharedProps,
    Persona,
    PersonaSize,
    IContextualMenuItem,
    Stack,
    ContextualMenuItemType,
    DefaultButton,
    ThemeProvider,
    CommandBarButton,
    mergeStyleSets,
    IconButton,
} from '@fluentui/react';

import { authService, User } from '../../services/auth.service';
import { NavigationContext } from '../../navigationContext';

import { SearchPicker } from './';
import Breadcrumbs from './Breadcrumbs';

import { useStateContext } from '../../services/contextProvider';
import { navigation, paths } from '../../services/navigation';
import { photoService } from '../../services/photo.service';
import ProfilePanel from '../ProfilePanel';
import { useThemes } from '../../hooks/useThemes';
import NotificationsPanel from './NotificationsPanel';
import { useGetNotificationsCountQuery } from '../../data/types';
import { useViewport } from '../../hooks/useViewport';
import { AvailableLanguages, useLanguage } from '../../services/i18n';
import { useApolloClient } from '@apollo/client';
import { LearnMoreModal, useLearnMoreContent } from './LearnMoreModal';
import HelpPanel from './HelpPanel';
import { useEngagement } from '../engagement/EngagementContext';
import {
    EngagementButtonWrapper,
    IEngagementTargetProps,
} from '../engagement/EngagementTarget';
import { LogoImage } from './LogoImage';

type TopNavProps = {
    showSearch: boolean;
    showBreadcrumbs: boolean;
    showLoginHistoryMenuItemClick?: () => void;
};

export function TopNav(props: TopNavProps): JSX.Element {
    const { showSearch, showBreadcrumbs, showLoginHistoryMenuItemClick } =
        props;

    const [isSearch, setIsSearch] = useState(false);
    const [isProfilePanelOpen, setIsProfilePanelOpen] = useState(false);
    const [isNotificationsPanelOpen, setIsNotificationsPanelOpen] =
        useState(false);
    const [isHelpPanelOpen, setIsHelpPanelOpen] = useState(false);

    const navigate = useNavigate();

    const { width } = useViewport();

    const { i18n } = useLanguage();

    const { headerTheme, changeTheme, currentThemeName } = useThemes();

    const apolloClient = useApolloClient();

    const mobileBreakpoint = 560;

    const {
        userTenants,
        currentTenantId,
        currentTenantCode,
        currentFinancialYearCode,
        currentTeamCode,
        currentMissionId,
        currentUserId,
        currentRoles,
    } = useStateContext();

    const learnMoreContent = useLearnMoreContent(currentTenantCode);
    const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] = useState(false);

    const [currentUserProfileImageSrc, setCurrentUserProfileImageSrc] =
        React.useState<string>(photoService.getImageUrl(currentUserId) || '');

    const authUser = authService.getUser();

    const currentTenant = (userTenants || []).find(
        (ut) => ut?.tenant.id === currentTenantId
    )?.tenant;

    const { isNavBarExpanded } = React.useContext(NavigationContext);

    const { dismissEngagementForTarget } = useEngagement();

    const [user] = React.useState<User | null>(authUser);

    const logoutAsync = async (): Promise<void> => {
        try {
            await apolloClient.cache.reset();
        } finally {
            await authService.logoutAsync();
        }
    };

    const redirectToChangePasswordAsync = async (): Promise<void> => {
        await authService.redirectToChangePasswordAsync();
    };

    const navigateToDefaultMissionAsync = async () => {
        if (currentTenantCode) {
            const url = navigation.getPathForParams({
                tenantCode: currentTenantCode,
                financialYearCode: currentFinancialYearCode,
            });
            navigate(url);
        }
    };

    const handleNavigateClick = (
        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        item?: IContextualMenuItem
    ): boolean => {
        if (item && item.href) {
            navigate(item.href);
            if (ev) {
                ev.preventDefault();
            }
        }
        return true;
    };

    const showProfilePanel = (): void => {
        setIsProfilePanelOpen(true);
    };

    const { data: notificationCountData, refetch } =
        useGetNotificationsCountQuery({
            skip: !currentUserId || !currentTenantId,
            variables: {
                tenantId: currentTenantId || '',
                userId: currentUserId || '',
            },
            pollInterval: 60000, // Every minute
        });

    const notificationsCount = notificationCountData?.notificationsCount || 0;

    const classNames = mergeStyleSets({
        notificationsBadge: {
            ':after': {
                content: `"${notificationsCount}"`,
                display: notificationsCount ? 'inline-block' : 'none',
                textAlign: 'center',
                background: '#de5126',
                padding: 4,
                color: 'white',
                borderRadius: '50%',
                lineHeight: 10,
                position: 'relative',
                fontSize: 10,
                top: -41,
                left: 10,
                minWidth: 10,
            },
        },
    });

    const farItems: (ICommandBarItemProps & IEngagementTargetProps)[] = [];

    if (user) {
        if (!isSearch && showSearch) {
            farItems.push({
                iconProps: {
                    iconName: 'Search',
                },
                onClick: () => {
                    setIsSearch(true);
                },
                key: 'search',
                title: 'Search',
                iconOnly: true,
            });
        }

        if (learnMoreContent.isEnabled && !isSearch) {
            farItems.push({
                iconProps: {
                    iconName: 'MSNVideos',
                },
                onClick: () => {
                    setIsLearnMoreModalOpen(true);
                },
                key: 'LearnMore',
                title: 'Learn More',
                iconOnly: true,
            });
        }

        if (!isSearch || width > mobileBreakpoint) {
            farItems.push({
                iconProps: {
                    iconName: 'Ringer',
                },
                onClick: () => {
                    setIsNotificationsPanelOpen(true);
                },
                key: 'Notifications',
                title: 'Notifications',
                className: classNames.notificationsBadge,
                iconOnly: true,
            });
        }

        if (
            currentRoles.some((r) => r === 'GlobalAdmin') &&
            (!isSearch || width > mobileBreakpoint)
        ) {
            const adminSetupDropDown: ICommandBarItemProps = {
                onRenderMenuIcon: () => null,
                iconProps: {
                    iconName: 'Settings',
                },
                key: 'setting',
                title: 'Settings',
                iconOnly: true,
                subMenuProps: {
                    items: [
                        {
                            key: 'SetupTenants',
                            text: 'Setup Tenants',
                            href: paths.setupTenants,
                            iconProps: {
                                iconName: 'ContactCardSettings',
                            },
                            onClick: handleNavigateClick,
                        },
                        {
                            key: 'SetupUsers',
                            text: 'Setup Users',
                            href: paths.setupUsers,
                            iconProps: {
                                iconName: 'PlayerSettings',
                            },
                            onClick: handleNavigateClick,
                        },
                        {
                            key: 'UsageStats',
                            text: 'Usage Stats',
                            href: paths.usageStats,
                            iconProps: {
                                iconName: 'ExploreData',
                            },
                            onClick: handleNavigateClick,
                        },
                        {
                            key: 'UsageOverview',
                            text: 'Usage Overview',
                            href: paths.usageOverview,
                            iconProps: {
                                iconName: 'ChartSeries',
                            },
                            onClick: handleNavigateClick,
                        },
                    ],
                },
            };

            farItems.push(adminSetupDropDown);
        }

        if (!isSearch || width > mobileBreakpoint) {
            farItems.push({
                iconProps: {
                    iconName: 'Help',
                },
                onClick: () => {
                    setIsHelpPanelOpen(true);
                    dismissEngagementForTarget('HelpIcon');
                },
                key: 'Help',
                title: 'Help',
                iconOnly: true,
                engagementTargetKey: 'HelpIcon',
            });
        }

        const loggedInPersona: IPersonaSharedProps = {
            text:
                user.displayName.toUpperCase() !== 'UNKNOWN'
                    ? user.displayName
                    : user.emailAddress,
            hidePersonaDetails: true,
            showSecondaryText: false,
            imageUrl: currentUserProfileImageSrc,
            tertiaryText: '',
            optionalText: '',
        };

        const persona = (
            <Persona {...loggedInPersona} size={PersonaSize.size24} />
        );

        const userDropDown: ICommandBarItemProps = {
            onRenderMenuIcon: () => null,
            onRenderChildren: (): React.ReactNode => persona,
            key: 'persona',
            iconOnly: false,
            subMenuProps: {
                items: [],
            },
        };

        if (currentRoles.length > 0) {
            const isAdmin = currentRoles.some((r) => r.includes('Admin'));

            userDropDown.subMenuProps?.items.push({
                key: 'roles',
                itemType: ContextualMenuItemType.Header,
                text: currentRoles?.join(', '),
                iconProps: {
                    iconName: isAdmin ? 'Crown' : 'Permissions',
                },
            });
        }

        userDropDown.subMenuProps?.items.push({
            key: 'divider',
            itemType: ContextualMenuItemType.Divider,
        });

        userDropDown.subMenuProps?.items.push({
            key: 'profile',
            name: 'Profile',
            iconProps: {
                iconName: 'EditContact',
            },
            onClick: showProfilePanel,
        });

        if (showLoginHistoryMenuItemClick) {
            userDropDown.subMenuProps?.items.push({
                key: 'accountActivity',
                name: 'Account Activity',
                iconProps: {
                    iconName: 'ComplianceAudit',
                },
                onClick: showLoginHistoryMenuItemClick,
            });
        }

        if (userTenants?.length || 0 > 1) {
            userDropDown.subMenuProps?.items.push({
                key: 'switchTenant',
                name: 'Switch Tenant',
                href: paths.switchTenant,
                iconProps: {
                    iconName: 'Switch',
                },
                onClick: handleNavigateClick,
            });
        }

        const currentLanguage =
            AvailableLanguages.find((l) => l.code === i18n.language) ??
            AvailableLanguages.find((l) => l.isDefault);

        userDropDown.subMenuProps?.items.push({
            key: 'language',
            name: `Language: ${currentLanguage?.text}`,
            iconProps: {
                iconName: 'World',
            },
            subMenuProps: {
                items: AvailableLanguages.map((l) => {
                    return {
                        key: l.code,
                        text: l.text,
                        onClick: () => {
                            i18n.changeLanguage(l.code);
                        },
                        disabled: currentLanguage?.code === l.code,
                    };
                }),
            },
        });

        userDropDown.subMenuProps?.items.push({
            key: 'theme',
            name: `Theme: ${currentThemeName === 'Dark' ? 'Dark' : 'Light'}`,
            iconProps: {
                iconName: 'Color',
            },
            subMenuProps: {
                items: [
                    {
                        key: 'default',
                        text: 'Light',
                        onClick: () => changeTheme('Default'),
                    },
                    {
                        key: 'dark',
                        text: 'Dark',
                        onClick: () => changeTheme('Dark'),
                    },
                ],
            },
        });

        // If this user is not using another identity provider.
        if (!user.idp) {
            userDropDown.subMenuProps?.items.push({
                key: 'changePassword',
                name: 'Change Password',
                iconProps: {
                    iconName: 'PasswordField',
                },
                onClick: () => {
                    redirectToChangePasswordAsync();
                },
            });
        }

        userDropDown.subMenuProps?.items.push({
            key: 'logOut',
            name: 'Log Out',
            iconProps: {
                iconName: 'SignOut',
            },
            onClick: () => {
                logoutAsync();
            },
        });

        farItems.push(userDropDown);
    }

    const handleSearchCancelled = (): void => setIsSearch(false);
    const handleSearchNavigated = (): void => setIsSearch(false);

    const handleRenderAdvance = (): JSX.Element => {
        return (
            <LogoImage
                compact={!isNavBarExpanded}
                style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: 100,
                    maxHeight: 32,
                }}
            />
        );
    };

    const handleNotificationsPanelDismiss = () => {
        setIsNotificationsPanelOpen(false);
        refetch();
    };

    const handleHelpPanelDismiss = () => {
        setIsHelpPanelOpen(false);
    };

    return (
        <React.Fragment>
            <ProfilePanel
                showPanel={isProfilePanelOpen}
                userId={currentUserId}
                onCancel={(): void => setIsProfilePanelOpen(false)}
                onSave={(): void => {
                    setIsProfilePanelOpen(false);
                }}
                onPhotoChanged={(): void => {
                    photoService.refreshImageUrl(currentUserId);

                    setCurrentUserProfileImageSrc(
                        photoService.getImageUrl(currentUserId) || ''
                    );
                }}
            />

            <LearnMoreModal
                isOpen={isLearnMoreModalOpen}
                onDismiss={() => setIsLearnMoreModalOpen(false)}
                tenantCode={currentTenantCode}
            />

            <NotificationsPanel
                showPanel={isNotificationsPanelOpen}
                onDismiss={handleNotificationsPanelDismiss}
            />

            <HelpPanel
                showPanel={isHelpPanelOpen}
                onDismiss={handleHelpPanelDismiss}
            />

            <ThemeProvider theme={headerTheme}>
                <div
                    className="no-print"
                    style={{
                        backgroundColor: headerTheme.palette.white,
                        overflow: 'hidden',
                        height: 44,
                    }}
                >
                    <div
                        style={{
                            float: 'right',
                        }}
                    >
                        <CommandBar
                            items={farItems}
                            onReduceData={() => undefined}
                            buttonAs={EngagementButtonWrapper}
                        />
                    </div>

                    <div
                        style={{
                            float: 'left',
                            width: isNavBarExpanded ? 110 : 56,
                            textAlign: 'center',
                        }}
                    >
                        <CommandBarButton
                            styles={{
                                root: {
                                    padding: 0,
                                    margin: 0,

                                    height: 44,
                                },

                                rootHovered: {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            onClick={navigateToDefaultMissionAsync}
                            onRenderChildren={handleRenderAdvance}
                        />
                    </div>

                    <div
                        style={{
                            marginLeft: isNavBarExpanded ? 124 : 72,
                            marginRight: 245,
                            height: 44,
                        }}
                    >
                        {isSearch && (
                            <Stack
                                horizontal
                                verticalAlign="center"
                                styles={{
                                    root: {
                                        height: 44,
                                    },
                                }}
                                tokens={{ childrenGap: 8 }}
                            >
                                <Stack.Item>
                                    <SearchPicker
                                        onSearchNavigated={
                                            handleSearchNavigated
                                        }
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    {width > mobileBreakpoint && (
                                        <DefaultButton
                                            text="Cancel"
                                            onClick={handleSearchCancelled}
                                        />
                                    )}
                                    {width <= mobileBreakpoint && (
                                        <IconButton
                                            iconProps={{ iconName: 'Cancel' }}
                                            onClick={handleSearchCancelled}
                                        />
                                    )}
                                </Stack.Item>
                            </Stack>
                        )}
                        {!isSearch && showBreadcrumbs && (
                            <Breadcrumbs
                                tenantId={currentTenantId || ''}
                                tenantCode={currentTenantCode || ''}
                                financialYearCode={
                                    currentFinancialYearCode || ''
                                }
                                tenantName={currentTenant?.description || ''}
                                teamCode={currentTeamCode}
                                missionId={currentMissionId}
                            />
                        )}
                    </div>
                </div>
            </ThemeProvider>
        </React.Fragment>
    );
}
