import { useState } from 'react';
import { IContextualMenuItem } from '@fluentui/react';

/// Provides a button with a drop down to toggle the view mode, saving the preference in storage.
export function useViewModeDropDownButton<T extends string>(
    storageKey: string,
    viewModes: {
        [key in T]: {
            text: string;
            iconName: string;
        };
    },
    defaultMode: T
): { button: IContextualMenuItem; activeViewMode: T } {
    const getSavedViewMode = (): T => {
        const storedViewMode = localStorage.getItem(storageKey) as
            | T
            | null
            | undefined;

        if (!storedViewMode) {
            localStorage.setItem(storageKey, defaultMode);
        }

        if (storedViewMode && viewModes[storedViewMode as T]) {
            return storedViewMode;
        }
        return defaultMode;
    };

    const [viewMode, setViewMode] = useState<T>(getSavedViewMode());

    const changeViewMode = (mode: T): void => {
        localStorage.setItem(storageKey, mode);
        setViewMode(mode);
    };

    const currentViewMode = viewModes[viewMode as T];

    return {
        activeViewMode: viewMode,
        button: {
            key: 'ViewMode',
            iconProps: { iconName: currentViewMode.iconName },
            text: currentViewMode.text,
            subMenuProps: {
                items: Object.keys(viewModes).map((key) => {
                    const vm = viewModes[key as T];
                    return {
                        key: key,
                        text: vm.text,
                        iconProps: { iconName: vm.iconName },
                        onClick: () => changeViewMode(key as T),
                    };
                }),
            },
        },
    };
}
