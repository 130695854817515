import * as React from 'react';
import {
    Pivot,
    PivotItem,
    Stack,
    DefaultButton,
    ChoiceGroup,
    IChoiceGroupOption,
} from '@fluentui/react';

import { AreaContainer } from '../MissionBuilder/components/AreaContainer';
import { MissionList } from './components/MissionList';
import { TeamList } from './components/TeamList';
import { useStateContext } from '../../services/contextProvider';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { AdvanceCard } from '../../components/AdvanceCard';
import { authService } from '../../services/auth.service';
import { paths } from '../../services/navigation';
import { TagList } from './components/TagList';
import { useActiveView } from '../../hooks/useActiveView';
import { RollbackPivot } from './components/RollbackPivot';
import { ImportPivot } from './components/ImportPivot';
import { AIImportPivot } from './components/AIImportPivot';
import { useMissionMentor } from '../../hooks/useMissionMentor';

export function Importer(): JSX.Element {
    const {
        currentTenantId,
        currentTenantCode,
        currentFinancialYearCode,
        configuration,
    } = useStateContext();
    const navigate = useNavigate();
    const { sectionKey } = useParams();
    const [message, setMessage] = React.useState<string | null>();
    const [error, setError] = React.useState<string | null>();
    const [selectedMissionIds, setSelectedMissionIds] = React.useState<
        string[]
    >([]);
    const [selectedTeamIds, setSelectedTeamIds] = React.useState<string[]>([]);
    const [selectedTagIds, setSelectedTagIds] = React.useState<string[]>([]);
    const { isEnabled: isMaiEnabled } = useMissionMentor();

    const host = configuration.importerHost;
    const endpoint = configuration.importerEndpoint;
    const code = configuration.importerFnKey;

    type SectionKeys =
        | 'template'
        | 'import'
        | 'rollback'
        | 'connectors'
        | 'aiimport';

    const today = new Date();
    const date = ('0' + today.getDate()).slice(-2);
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const year = today.getFullYear();
    const dateStr = `${year}-${month}-${date}`;

    useActiveView('Imports', 'Imports');

    const handleLinkClick = (item?: PivotItem): void => {
        const sectionKey = (item?.props.itemKey as SectionKeys) || 'template';

        const href = generatePath(paths.importerSection, {
            tenantCode: currentTenantCode?.toLowerCase(),
            financialYearCode: currentFinancialYearCode?.toLowerCase(),
            sectionKey: sectionKey,
        });

        console.log(href);

        navigate(href, { replace: true });
    };

    const downloadExcelClick = async (): Promise<void> => {
        setMessage('Downloading Template');
        setError(null);

        const token = await authService.getTokenAsync();
        const url = `${host}/api/excel_template?endpoint=${endpoint}&tenantId=${currentTenantId}`;
        const body = {
            financialYearCode: currentFinancialYearCode || null,
            divisionCode: null,
            filter_type: filterKey,
            filter_value:
                filterKey == 'team'
                    ? selectedTeamIds
                    : filterKey == 'tag'
                      ? selectedTagIds
                      : selectedMissionIds,
        };

        try {
            const response = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    'x-functions-key': `${code}`,
                },
                method: 'POST',
                body: JSON.stringify(body),
            });
            if (response.ok) {
                setMessage('Excel Template Created');
                const body = await response.arrayBuffer();
                fileDownload(body, `Template ${dateStr}.xlsx`);
            } else {
                try {
                    const body = await response.json();
                    setError(body.message);
                } catch {
                    setError(`${response.status}: ${response.statusText}`);
                }
            }
        } catch (error) {
            console.log(error);
            setMessage(null);
            setError('Failure to connect to importer');
        }
    };

    const downloadJsonClick = async (): Promise<void> => {
        setMessage('Downloading Template');
        setError(null);

        const token = await authService.getTokenAsync();
        const url = `${host}/api/json_template?endpoint=${endpoint}&tenantId=${currentTenantId}`;
        const body = {
            financialYearCode: currentFinancialYearCode || null,
            filter_type: filterKey,
            filter_value:
                filterKey == 'team'
                    ? selectedTeamIds
                    : filterKey == 'tag'
                      ? selectedTagIds
                      : selectedMissionIds,
        };

        try {
            const response = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    'x-functions-key': `${code}`,
                },
                method: 'POST',
                body: JSON.stringify(body),
            });
            if (response.ok) {
                const body = await response.text();
                setMessage('Json Template Created');
                fileDownload(body, `Template ${dateStr}.json`);
            } else {
                setError(`${response.status}: ${response.statusText}`);
            }
        } catch (error) {
            console.log(error);
            setMessage(null);
            setError('Failure to connect to importer');
        }
    };

    type filterTypes = 'mission' | 'team' | 'tag';
    const [filterKey, setFilterKey] = React.useState<filterTypes>('mission');

    return (
        <Stack
            tokens={{
                padding: 0,
                childrenGap: 8,
            }}
        >
            <AreaContainer
                title="Importer"
                isCollapsable={false}
                subTitle="One stop shop for bulk data imports"
            >
                <div style={{ padding: 8 }}>
                    <AdvanceCard>
                        <AdvanceCard.Item>
                            <Pivot
                                selectedKey={sectionKey || 'template'}
                                onLinkClick={handleLinkClick}
                            >
                                <PivotItem
                                    itemKey="template"
                                    headerText="Build Template"
                                >
                                    <Stack
                                        tokens={{
                                            childrenGap: '24',
                                        }}
                                        styles={{
                                            root: { paddingTop: '25px' },
                                        }}
                                    >
                                        <div>
                                            <ChoiceGroup
                                                selectedKey={filterKey}
                                                options={[
                                                    {
                                                        key: 'mission',
                                                        text: 'Mission',
                                                    },
                                                    {
                                                        key: 'team',
                                                        text: 'Team',
                                                    },
                                                    {
                                                        key: 'tag',
                                                        text: 'Tags',
                                                    },
                                                ]}
                                                onChange={(
                                                    _ev?: React.FormEvent<
                                                        | HTMLInputElement
                                                        | HTMLElement
                                                    >,
                                                    option?: IChoiceGroupOption
                                                ): void => {
                                                    if (option && option.key) {
                                                        const key =
                                                            option?.key as filterTypes;
                                                        setFilterKey(key);
                                                    }
                                                }}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <DefaultButton
                                                style={{ marginRight: '10px' }}
                                                iconProps={{
                                                    iconName: 'Download',
                                                }}
                                                text="Download Excel"
                                                onClick={downloadExcelClick}
                                            />
                                            <DefaultButton
                                                iconProps={{
                                                    iconName: 'Download',
                                                }}
                                                text="Download Json"
                                                onClick={downloadJsonClick}
                                            />
                                        </div>
                                        <Stack.Item
                                            styles={{
                                                root: {
                                                    color: 'black',
                                                    margin: '10px 0',
                                                },
                                            }}
                                        >
                                            {message}
                                        </Stack.Item>
                                        <Stack.Item
                                            styles={{
                                                root: {
                                                    color: 'red',
                                                    margin: '10px 0',
                                                },
                                            }}
                                        >
                                            {error}
                                        </Stack.Item>
                                        {filterKey === 'mission' && (
                                            <MissionList
                                                onMissionIdsSelected={(
                                                    missionIds: string[]
                                                ): void => {
                                                    setSelectedMissionIds(
                                                        missionIds
                                                    );
                                                }}
                                            />
                                        )}
                                        {filterKey === 'team' && (
                                            <TeamList
                                                onTeamIdsSelected={(
                                                    teamIds: string[]
                                                ): void => {
                                                    setSelectedTeamIds(teamIds);
                                                }}
                                            />
                                        )}
                                        {filterKey === 'tag' && (
                                            <TagList
                                                onTagIdsSelected={(
                                                    tagIds: string[]
                                                ): void => {
                                                    setSelectedTagIds(tagIds);
                                                }}
                                            />
                                        )}
                                    </Stack>
                                </PivotItem>
                                <PivotItem itemKey="upload" headerText="Import">
                                    <ImportPivot />
                                </PivotItem>
                                <PivotItem
                                    itemKey="rollback"
                                    headerText="Rollback"
                                >
                                    <RollbackPivot />
                                </PivotItem>
                                {/* <PivotItem
                                    itemKey="connectors"
                                    headerText="Connectors"
                                    >
                                    <h1>Connectors</h1>
                                    <div>Hello, World!</div>
                                </PivotItem> */}
                                {isMaiEnabled && (
                                    <PivotItem
                                        itemKey="aiimport"
                                        headerText="AI Import"
                                    >
                                        <AIImportPivot />
                                    </PivotItem>
                                )}
                            </Pivot>
                        </AdvanceCard.Item>
                    </AdvanceCard>
                </div>
            </AreaContainer>
        </Stack>
    );
}
