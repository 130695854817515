import React, { useCallback, useEffect, useState } from 'react';

import {
    Stack,
    Text,
    TextField,
    PanelType,
    IDropdownOption,
    Dropdown,
    Icon,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Toggle,
    ActionButton,
} from '@fluentui/react';

import {
    FrequencyPeriods,
    useUpdateMeasureMutation,
    Measure,
    useGetMeasureQuery,
    MeasureTypes,
    Currency,
    Multipliers,
    StatusTypes,
    refetchGetMissionMeasuresQuery,
    PhaseType,
    useGetMissionQuery,
    GetMeasureQuery,
    GetMissionQuery,
    useGetTagsQuery,
    ChartDisplay,
    ChartType,
    TargetTypes,
    ValueTypes,
} from '../data/types';

import { useStateContext } from '../services/contextProvider';

import { EditPanel } from './shared/EditPanel';

import {
    FrequencyInput,
    StatusTypeInput,
    MeasureTypeInput,
    MultiplierInput,
    DecimalPlacesInput,
    CurrencyInput,
    YellowStatusInput,
    TargetRangeInput,
    IsStatusLimitedInput,
    InputShimmer,
} from './inputs';
import { Access } from '../data/extendedTypes';
import { MeasureCommandBar } from './EditMeasureCommandBar';
import { useClipboard } from 'use-clipboard-copy';
import { useLanguage } from '../services/i18n';
import MeasureGroupDropdown from './MeasureGroupDropdown';
import { useThemes } from '../hooks/useThemes';
import { useRefetch } from '../hooks/useRefetch';

type EditMeasurePanelProps = {
    missionId: string | null;
    measureId: string | null | undefined;
    defaultMeasureGroupId: string | null | undefined;
    showPanel: boolean;
    onCancel: () => void;
    onSave: () => void;
    onDelete: () => void;
    onAddValuesButtonClick: () => void;
    onMeasureOverviewButtonClick: () => void;
    onMeasureLockToggleClick?: () => void;
    hideDelete?: boolean;
};

function showRemoveValuesWarning(a?: MeasureTypes, b?: MeasureTypes) {
    // If they are the same, its cool
    if (a === b) {
        return false;
    }

    // If they are both numeric, its cool.
    return !(
        (a === MeasureTypes.Numeric ||
            a === MeasureTypes.Currency ||
            a === MeasureTypes.Percentage) &&
        (b === MeasureTypes.Numeric ||
            b === MeasureTypes.Currency ||
            b === MeasureTypes.Percentage)
    );
}

export default function EditMeasurePanel(
    props: EditMeasurePanelProps
): JSX.Element {
    const { currentTenantId, currentRoles } = useStateContext();

    const [showDescriptionInput, setShowDescriptionInput] = useState(false);
    const [showShortNameInput, setShowShortNameInput] = useState(false);

    const { t } = useLanguage();
    const { currentTheme } = useThemes();

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin'].includes(r)
    );

    const clipboard = useClipboard({
        copiedTimeout: 3000, // show copy message in milliseconds
    });

    const [measure, setMeasure] = React.useState<Measure>();
    const [missionAccess, setMissionAccess] = React.useState<
        Access | undefined
    >();
    const [isValid, setIsValid] = React.useState(true);
    const [hasSaved, setHasSaved] = React.useState(false);
    const [formErrorMessages, setFormErrorMessages] = React.useState({
        name: '',
    });

    const handleMissionLoaded = (data?: GetMissionQuery) => {
        setMissionAccess(data?.mission?.rights);
    };

    const { data: missionData } = useGetMissionQuery({
        skip: !currentTenantId || !props.missionId || !props.showPanel,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId || '',
        },
    });

    const [loadedMeasureType, setLoadedMeasureType] =
        React.useState<MeasureTypes>();

    // Used for the default value of the inputs
    const [greenRange, setGreenRange] = React.useState<number | null>(null);
    const [yellowRange, setYellowRange] = React.useState<number | null>(null);

    const handleMeasureLoaded = useCallback((data?: GetMeasureQuery) => {
        const loadedMeasure = data?.measure;
        if (loadedMeasure) {
            setLoadedMeasureType(loadedMeasure?.measureType);
            setMissionAccess(loadedMeasure.mission?.rights);
            setGreenRange(loadedMeasure.greenRange);
            setYellowRange(loadedMeasure.yellowRange);
            setMeasure({
                id: loadedMeasure.id,
                missionId: loadedMeasure.missionId,
                measureGroupId: loadedMeasure.measureGroupId,
                name: loadedMeasure.name,
                description: loadedMeasure.description,
                measureType: loadedMeasure.measureType,
                phaseType: loadedMeasure.phaseType,
                currency: loadedMeasure.currency
                    ? {
                          code: loadedMeasure.currency?.code,
                          descr: loadedMeasure.currency?.descr,
                          symbol: loadedMeasure.currency?.symbol,
                      }
                    : null,
                multiplier: loadedMeasure.multiplier,
                decimalPlaces: loadedMeasure.decimalPlaces,
                statusType: loadedMeasure.statusType,
                yellowStart: loadedMeasure.yellowStart,
                greenRange: loadedMeasure.greenRange,
                yellowRange: loadedMeasure.yellowRange,
                isStatusLimited: loadedMeasure.isStatusLimited,
                frequencyNumber: loadedMeasure.frequencyNumber,
                frequencyPeriod: loadedMeasure.frequencyPeriod,
                isLinked: loadedMeasure.isLinked,
                linkedFromMeasureId: loadedMeasure.linkedFromMeasureId,
                tags: loadedMeasure.tags,
                sequence: loadedMeasure.sequence,
                version: loadedMeasure.version,

                previousFYMeasureId: loadedMeasure.previousFYMeasureId,
                fullYearTarget: loadedMeasure.fullYearTarget,
                fullYearString: loadedMeasure.fullYearString,
                isFullYearTarget: loadedMeasure.isFullYearTarget,
                chartDisplay: loadedMeasure.chartDisplay,
                chartType: loadedMeasure.chartType,
                showForecast: loadedMeasure.showForecast,
                showFutureLook: loadedMeasure.showFutureLook,
                isCustom: loadedMeasure.isCustom,
                targetType: loadedMeasure.targetType,
                valueType: loadedMeasure.valueType,
                valueFormula: loadedMeasure.valueFormula,
            });

            setShowDescriptionInput(!!loadedMeasure.description);
            setShowShortNameInput(false);
        }
    }, []);

    const {
        data,
        loading: isLoading,
        refetch: _refetch,
    } = useGetMeasureQuery({
        fetchPolicy: 'network-only',
        skip: !currentTenantId || !props.measureId || !props.showPanel,
        variables: {
            tenantId: currentTenantId || '',
            id: props.measureId || '',
        },
    });

    const [refetch] = useRefetch(_refetch);

    const isLocked = (data?.measure?.tags || []).reduce(
        (value, tag) => value || tag.name === 'Locked',
        false
    );

    const isNumeric =
        measure?.measureType === MeasureTypes.Numeric ||
        measure?.measureType === MeasureTypes.Currency ||
        measure?.measureType === MeasureTypes.Percentage;

    const handleUpdatedCompleted = async () => {
        setHasSaved(true);

        if (props.measureId) {
            // Refetch as change could have affected values, status, etc.
            await refetch();
        }

        await new Promise((res) => setTimeout(res, 1000));

        if (props.onSave) {
            props.onSave();
        }
    };

    const [updateMeasure, { loading: isSaving, error: saveError }] =
        useUpdateMeasureMutation({
            onCompleted: handleUpdatedCompleted,
        });

    const { data: allTags, loading: allTagsLoading } = useGetTagsQuery({
        skip: !currentTenantId || !props.showPanel || !props.missionId,
        variables: {
            tenantId: currentTenantId || '',
            // measureId: props.measureId || '',
        },
    });

    const tagOptions =
        allTags?.tags
            ?.filter(
                (t) =>
                    t.isMeasureTag ||
                    measure?.tags?.some((mt) => mt.id === t.id) // Show any existing tags too so they can keep / deselect
            )
            ?.map((tag) => {
                return {
                    key: tag.id || '',
                    text: tag.name || '',
                    data: { icon: 'TagSolid', colourHex: tag.colourHex },
                };
            }) || [];

    const onRenderOption = (
        option: IDropdownOption | undefined
    ): JSX.Element => {
        if (!option) return <div>Undefined</div>;
        return (
            <div>
                <span style={{ marginLeft: '8px' }}>{option.text}</span>
                {option.data && option.data.icon && (
                    <Icon
                        style={{
                            marginLeft: '8px',
                            color: option.data.colourHex
                                ? `#${option.data.colourHex}`
                                : undefined,
                        }}
                        iconName={option.data.icon}
                        aria-hidden="true"
                        title={option.data.icon}
                    />
                )}
            </div>
        );
    };

    const validate = useCallback((m: Measure | undefined): boolean => {
        const formErrorMessages = {
            name: !m?.name ? 'Please enter a value.' : '',
        };

        const isValid = !formErrorMessages.name;

        setFormErrorMessages(formErrorMessages);
        setIsValid(isValid);

        return isValid;
    }, []);

    const handleUserInput = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void => {
        const element = ev.target as HTMLInputElement;
        const name = element.name;
        if (measure) {
            setMeasure({ ...measure, [name]: newValue });
        }
    };

    useEffect(() => {
        if (props.showPanel) {
            setHasSaved(false);
            setShowDescriptionInput(false);
            if (!props.measureId && missionData) {
                handleMissionLoaded(missionData);
                setGreenRange(0);
                setYellowRange(0);
                setMeasure({
                    id: null,
                    missionId: props.missionId || '',
                    measureGroupId: props.defaultMeasureGroupId || null,
                    name: '',
                    description: '',
                    measureType: MeasureTypes.Numeric,
                    phaseType: PhaseType.PeriodEnd,
                    currency: null,
                    multiplier: Multipliers.None,
                    decimalPlaces: 0,
                    statusType: StatusTypes.MoreThanTarget,
                    yellowStart: 0.9,
                    greenRange: 0,
                    yellowRange: 0,
                    isStatusLimited: true,
                    sequence: 0, // New measure sequence
                    version: '',
                    frequencyNumber: 1,
                    frequencyPeriod: FrequencyPeriods.Month,
                    isLinked: false,
                    linkedFromMeasureId: null,
                    tags: [],

                    previousFYMeasureId: null,
                    fullYearTarget: null,
                    fullYearString: null,
                    isFullYearTarget: true,
                    chartDisplay: ChartDisplay.ByFrequency,
                    chartType: ChartType.Default,
                    showForecast: false,
                    showFutureLook: false,
                    isCustom: false,
                    targetType: TargetTypes.FullYear,
                    valueType: ValueTypes.Simple,
                    valueFormula: null,
                });
            } else if (data) {
                handleMeasureLoaded(data);
            }
        } else {
            setMeasure(undefined);
        }
    }, [
        props.showPanel,
        props.defaultMeasureGroupId,
        props.measureId,
        props.missionId,
        data,
        missionData,
        handleMeasureLoaded,
    ]);

    useEffect(() => {
        if (measure?.id || !isValid) {
            validate(measure);
        }
    }, [measure, isValid, validate]);

    const isReadOnly =
        isLoading ||
        isSaving ||
        !measure ||
        measure.isLinked ||
        (isLocked && !isAdmin);

    const isGroupReadOnly = isLoading || isSaving || !measure;

    const handleGreenRangeChange = useCallback(
        (newValue: number | null): void => {
            const calcYellowRange = Math.max(yellowRange || 0, newValue || 0);
            setGreenRange(newValue);
            setYellowRange(calcYellowRange);
        },
        [yellowRange]
    );

    const handleYellowRangeChange = useCallback(
        (newValue: number | null): void => setYellowRange(newValue),
        []
    );

    const classNames = mergeStyleSets({
        adminArea: {
            backgroundColor: currentTheme.palette.neutralLight,
            marginTop: 8,
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
        container: {
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        columnsContainer: {
            display: 'grid',
            gridGap: 16,
            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
        },
    });

    const formLeft = (
        <Stack tokens={{ childrenGap: 8 }}>
            {(isLoading || measure?.isCustom || !measure?.id) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    {measure && (
                        <FrequencyInput
                            defaultFrequencyPeriod={measure.frequencyPeriod}
                            disabled={isReadOnly}
                            onChange={(
                                newFrequencyPeriod: FrequencyPeriods
                            ): void => {
                                setMeasure({
                                    ...measure,
                                    frequencyPeriod: newFrequencyPeriod,
                                });
                            }}
                        />
                    )}
                </InputShimmer>
            )}

            <InputShimmer isDataLoaded={!isLoading}>
                {measure && (
                    <MeasureTypeInput
                        defaultValue={measure.measureType}
                        errorMessage={undefined}
                        disabled={isReadOnly}
                        onChange={(newValue: MeasureTypes): void =>
                            setMeasure({
                                ...measure,
                                measureType: newValue,
                                currency:
                                    newValue === MeasureTypes.Currency
                                        ? measure.currency
                                        : null,
                            })
                        }
                    />
                )}
            </InputShimmer>

            {measure?.id &&
                showRemoveValuesWarning(
                    loadedMeasureType,
                    measure?.measureType
                ) && (
                    <MessageBar
                        styles={{
                            text: {
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingRight: 16,
                            },
                        }}
                        messageBarType={MessageBarType.warning}
                    >
                        Changing the Measure Type will delete all previous
                        values.
                    </MessageBar>
                )}

            {(measure?.measureType === MeasureTypes.Currency || isLoading) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    {measure && (
                        <CurrencyInput
                            disabled={isReadOnly}
                            defaultValue={measure.currency}
                            errorMessage={undefined}
                            onChange={(newValue?: Currency): void =>
                                setMeasure({
                                    ...measure,
                                    currency: newValue || null,
                                })
                            }
                        />
                    )}
                </InputShimmer>
            )}

            {(measure?.measureType === MeasureTypes.Currency ||
                measure?.measureType === MeasureTypes.Numeric ||
                isLoading) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    {measure && (
                        <MultiplierInput
                            disabled={isReadOnly}
                            defaultValue={measure.multiplier}
                            errorMessage={undefined}
                            onChange={(newValue: Multipliers): void =>
                                setMeasure({
                                    ...measure,
                                    multiplier: newValue,
                                })
                            }
                        />
                    )}
                </InputShimmer>
            )}

            {(isNumeric || isLoading) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    {measure && (
                        <DecimalPlacesInput
                            disabled={isReadOnly}
                            defaultValue={measure.decimalPlaces || 0}
                            onChange={(newValue: number): void =>
                                setMeasure({
                                    ...measure,
                                    decimalPlaces: newValue,
                                })
                            }
                        />
                    )}
                </InputShimmer>
            )}

            {!!props.missionId && !!currentTenantId && (
                <InputShimmer isDataLoaded={!isLoading}>
                    {measure && (
                        <MeasureGroupDropdown
                            defaultSelectedKey={measure.measureGroupId}
                            missionId={props.missionId}
                            tenantId={currentTenantId}
                            disabled={isGroupReadOnly}
                            onChange={(measureGroupId: string | null): void => {
                                setMeasure({
                                    ...measure,
                                    measureGroupId: measureGroupId,
                                });
                            }}
                            label="Group"
                        />
                    )}
                </InputShimmer>
            )}
        </Stack>
    );

    const formRight = (
        <Stack tokens={{ childrenGap: 8 }}>
            {(isNumeric || isLoading) && (
                <InputShimmer isDataLoaded={!isLoading} inputHeight={102}>
                    {measure && (
                        <StatusTypeInput
                            disabled={isReadOnly}
                            value={measure.statusType}
                            onChange={(newValue: StatusTypes): void =>
                                setMeasure({
                                    ...measure,
                                    statusType: newValue,
                                })
                            }
                        />
                    )}
                </InputShimmer>
            )}

            {isLoading ||
                (isNumeric &&
                    measure.statusType !== StatusTypes.TargetExact && (
                        <InputShimmer
                            isDataLoaded={!isLoading}
                            inputHeight={28}
                        >
                            {measure && (
                                <YellowStatusInput
                                    disabled={isReadOnly}
                                    defaultValue={measure.yellowStart}
                                    onChange={(newValue: number): void =>
                                        setMeasure({
                                            ...measure,
                                            yellowStart: newValue,
                                        })
                                    }
                                />
                            )}
                        </InputShimmer>
                    ))}

            {(isLoading ||
                (measure?.statusType !== StatusTypes.TargetExact &&
                    isNumeric)) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    {measure && (
                        <IsStatusLimitedInput
                            disabled={isReadOnly}
                            defaultValue={measure.isStatusLimited || false}
                            onChange={(newValue: boolean): void =>
                                setMeasure({
                                    ...measure,
                                    isStatusLimited: newValue,
                                })
                            }
                        />
                    )}
                </InputShimmer>
            )}

            {(isLoading || measure?.statusType === StatusTypes.TargetExact) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    {measure && (
                        <TargetRangeInput
                            disabled={isReadOnly}
                            label="Green Range +/-"
                            defaultValue={greenRange}
                            measureType={measure.measureType}
                            multiplier={measure.multiplier}
                            currencySymbol={measure.currency?.symbol}
                            decimalPlaces={measure.decimalPlaces}
                            onChange={handleGreenRangeChange}
                        />
                    )}
                </InputShimmer>
            )}

            {(isLoading || measure?.statusType === StatusTypes.TargetExact) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    {measure && (
                        <TargetRangeInput
                            disabled={isReadOnly}
                            label="Yellow Range +/-"
                            defaultValue={yellowRange}
                            measureType={measure.measureType}
                            multiplier={measure.multiplier}
                            currencySymbol={measure.currency?.symbol}
                            decimalPlaces={measure.decimalPlaces}
                            onChange={handleYellowRangeChange}
                            minValue={greenRange || 0}
                        />
                    )}
                </InputShimmer>
            )}

            {isAdmin && (
                <InputShimmer isDataLoaded={!isLoading} inputHeight={150}>
                    <div className={classNames.adminArea}>
                        <Text variant="mediumPlus">Admin Options</Text>

                        {tagOptions && tagOptions.length > 0 && (
                            <InputShimmer
                                isDataLoaded={!isLoading && !allTagsLoading}
                            >
                                <Dropdown
                                    placeholder="Select tags"
                                    label="Tags"
                                    options={tagOptions}
                                    selectedKeys={
                                        measure?.tags
                                            .map((t) => t.id)
                                            .filter(
                                                (x): x is string => x !== null
                                            ) || []
                                    }
                                    multiSelect
                                    styles={{
                                        dropdown: { width: '100%' },
                                    }}
                                    onRenderOption={onRenderOption}
                                    onChange={(
                                        _event: React.FormEvent<HTMLDivElement>,
                                        item: IDropdownOption | undefined
                                    ): void => {
                                        if (item && measure) {
                                            const selectedTag =
                                                allTags?.tags?.find(
                                                    (t) => t.id == item.key
                                                );
                                            if (selectedTag) {
                                                const tags = item.selected
                                                    ? [
                                                          ...measure.tags,
                                                          selectedTag,
                                                      ]
                                                    : measure.tags.filter(
                                                          (t) =>
                                                              t.id !== item.key
                                                      );
                                                setMeasure({
                                                    ...measure,
                                                    tags,
                                                });
                                            }
                                        }
                                    }}
                                />
                            </InputShimmer>
                        )}

                        <InputShimmer
                            isDataLoaded={!isLoading}
                            inputHeight={120}
                        >
                            {measure && (
                                <TextField
                                    label="Value Formula"
                                    multiline
                                    autoAdjustHeight
                                    name="valueFormula"
                                    value={measure.valueFormula || ''}
                                    onChange={handleUserInput}
                                    disabled={isReadOnly}
                                />
                            )}
                        </InputShimmer>

                        {currentRoles?.some((r) => r === 'GlobalAdmin') && (
                            <Toggle
                                label="Legacy measure of success"
                                checked={measure?.isCustom || false}
                                onChange={(_ev, isChecked?: boolean) => {
                                    if (measure) {
                                        setMeasure({
                                            ...measure,
                                            isCustom: isChecked || false,
                                        });
                                    }
                                }}
                            />
                        )}
                    </div>
                </InputShimmer>
            )}
        </Stack>
    );

    const handleUpdateClick = async (): Promise<void> => {
        const isValid = validate(measure);
        setHasSaved(false);
        if (isValid) {
            const input = {
                ...measure,
                greenRange: greenRange || 0,
                yellowRange: Math.max(yellowRange || 0, greenRange || 0), // force yellow range to be greater or equal to green
            } as Measure;

            const variables = {
                tenantId: currentTenantId || '',
                input: input,
            };
            if (input.id) {
                await updateMeasure({
                    variables: variables,
                });
            } else {
                await updateMeasure({
                    variables: variables,
                    refetchQueries: [
                        refetchGetMissionMeasuresQuery({
                            tenantId: currentTenantId || '',
                            missionId: props.missionId,
                        }),
                    ],
                });
            }
        }
    };

    const handleCopyMeasureIdButtonClick = () =>
        clipboard.copy(measure?.id || '');

    const commandBar = measure?.id ? (
        <MeasureCommandBar
            measureId={props.measureId}
            activeButton="Edit"
            missionAccess={missionAccess}
            isLinked={measure.isLinked}
            isCustom={measure.isCustom}
            onOverviewButtonClick={props.onMeasureOverviewButtonClick}
            onAddValuesButtonClick={props.onAddValuesButtonClick}
            onCopyMeasureIdButtonClick={handleCopyMeasureIdButtonClick}
            onMeasureLockToggleClick={props.onMeasureLockToggleClick}
            onDeleted={props.onDelete}
            isLocked={isLocked}
            hideDelete={props.hideDelete}
        />
    ) : undefined;

    return (
        <EditPanel
            activeViewName={'MeasureEdit'}
            onDismiss={props.onCancel}
            showPanel={props.showPanel}
            panelType={PanelType.medium}
            headerText={t('measure-of-success.measure-of-success_one')}
            isSaving={isSaving}
            isValid={isValid && (missionAccess?.write || false)}
            hasSaved={hasSaved}
            saveErrorMessage={saveError ? saveError.message : null}
            onUpdateClick={handleUpdateClick}
            commandBar={commandBar}
        >
            <div className={classNames.container}>
                <InputShimmer isDataLoaded={!isLoading} inputHeight={60}>
                    {measure && (
                        <TextField
                            label="Name"
                            required
                            name="name"
                            value={measure.name || ''}
                            onChange={handleUserInput}
                            errorMessage={formErrorMessages.name}
                            disabled={isReadOnly}
                        />
                    )}

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 8,
                        }}
                    >
                        {/* {!showShortNameInput && (
                            <ActionButton
                                onClick={() => setShowShortNameInput(true)}
                                iconProps={{ iconName: 'AddTo' }}
                                text="Add Short Name"
                            />
                        )} */}
                        {!showDescriptionInput && (
                            <ActionButton
                                onClick={() => setShowDescriptionInput(true)}
                                iconProps={{ iconName: 'AddTo' }}
                                text="Add Description"
                            />
                        )}
                    </div>
                </InputShimmer>

                {showShortNameInput && (
                    <InputShimmer isDataLoaded={!isLoading} inputHeight={120}>
                        {measure && (
                            <TextField
                                label="Short Name"
                                autoAdjustHeight
                                name="shortname"
                                onChange={handleUserInput}
                                disabled={isReadOnly}
                            />
                        )}
                    </InputShimmer>
                )}

                {showDescriptionInput && (
                    <InputShimmer isDataLoaded={!isLoading} inputHeight={120}>
                        {measure && (
                            <TextField
                                label="Description"
                                multiline
                                autoAdjustHeight
                                name="description"
                                value={measure.description || ''}
                                onChange={handleUserInput}
                                disabled={isReadOnly}
                            />
                        )}
                    </InputShimmer>
                )}

                <div className={classNames.columnsContainer}>
                    <div>{formLeft}</div>
                    <div>{formRight}</div>
                </div>
            </div>
        </EditPanel>
    );
}
