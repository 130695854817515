import { useStateContext } from '../../../services/contextProvider';
import {
    MissionFilterType,
    ContributorFilterType,
    RightsFilterType,
} from '../components/UserFilterBar';

type UserType = {
    id: string | null;
    userRoles: {
        name: string;
        tenantId: string | null;
    }[];
};

export function useGetUserTypes(
    missions: MissionFilterType[] = [],
    contributors: ContributorFilterType[] = [],
    rights: RightsFilterType[] = []
): {
    isUserContributor: (user: UserType) => boolean;
    isUserMissionOwner: (user: UserType) => boolean;
    isClientAdmin: (user: UserType) => boolean;
    isGlobalAdmin: (user: UserType) => boolean;
    hasAssignedRights: (user: UserType) => {
        read: boolean;
        write: boolean;
    };
} {
    const { currentTenantId } = useStateContext();

    return {
        isUserContributor: (user: UserType) =>
            contributors.some((c) => c.userId === user.id),
        isUserMissionOwner: (user: UserType) =>
            missions.some((c) => c.userId === user.id),
        isClientAdmin: (user: UserType) =>
            user.userRoles.some(
                (r) =>
                    r.name === 'ClientAdmin' && r.tenantId === currentTenantId
            ),
        isGlobalAdmin: (user: UserType) =>
            user.userRoles.some((r) => r.name === 'GlobalAdmin'),
        hasAssignedRights: (user: UserType) => ({
            read: rights.some((r) => r.read && r.userId === user.id),
            write: rights.some(
                (r) => (r.write || r.import) && r.userId === user.id
            ),
        }),
    };
}
