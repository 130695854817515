import React, { useCallback, useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import {
    Stack,
    mergeStyleSets,
    ActionButton,
    Label,
    DefaultButton,
    PrimaryButton,
    Text,
    Dialog,
    DialogFooter,
    DialogType,
    Spinner,
    SpinnerSize,
    MessageBar,
    MessageBarType,
    useTheme,
    Checkbox,
} from '@fluentui/react';
import dayjs from 'dayjs';

import {
    GetStatusReportQuery,
    StatusReport,
    useDeleteStatusReportMutation,
    useGetStatusReportQuery,
    useUpdateStatusReportFactMutation,
    useUpdateStatusReportMutation,
} from '../../data/types';
import { useStateContext } from '../../services/contextProvider';
import {
    AreaContainer,
    AreaContainerButton,
} from '../MissionBuilder/components/AreaContainer';
import StatusReportGrid from './components/StatusReportGrid';
import uniq from 'lodash/uniq';
import debounce from 'lodash/debounce';
import { StatusReportEditorCard } from './components/StatusReportEditorCard';
import { StatusReportAddFactPanel } from './components/StatusReportAddFactPanel';
import DeleteModal from '../../components/shared/DeleteModal';
import { paths } from '../../services/navigation';
import { statusReportExport } from '../../services/exporter';
import { useViewport } from '../../hooks/useViewport';
import { StatusReportPublishDialog } from './StatusReportPublishDialog';
import { ErrorMessageBar } from '../../components/shared/ErrorMessageBar';
import { useActiveView } from '../../hooks/useActiveView';
import { StatusReportAsOfDatePicker } from './components/StatusReportAsOfDatePicker';
import { StatusReportDataChangedIndicator } from './components/StatusReportDataChangedIndicator';
import { EditPanels, PanelTypes } from '../../components/EditPanels';
import { sorters } from '../../data/sorters';
import { StatusReportTitle } from './StatusReportTitle';

type ChangeTracker = {
    input: StatusReport;
    reportChanged: boolean;
    factIdsChanged: string[];
    isSaving: boolean;
};

function StatusReportScene(): JSX.Element {
    const params = useParams();

    const [docTitle, setDocTitle] = useState<string | undefined>();
    useActiveView('StatusReport', docTitle);

    const currentTheme = useTheme();

    const { statusReportId } = params;

    const { width } = useViewport();
    const breakpoint = 620;
    const isMobileMode = width < breakpoint;

    const { currentTenantId, configuration, currentRoles } = useStateContext();

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin', 'Developer'].includes(r)
    );

    const changeTracker = useRef<ChangeTracker>();

    const [isExporting, setIsExporting] = useState(false);

    const [activePanel, setActivePanel] = useState<PanelTypes>(null);
    const [activeMeasureId, setActiveMeasureId] = useState<string | null>();
    const [activeTaskId, setActiveTaskId] = useState<string | null>();

    // For the add action list to next 30 functionality
    const [actionList, setActionList] = useState<string[]>([]);

    const navigate = useNavigate();

    const { data, loading, refetch } = useGetStatusReportQuery({
        skip: !statusReportId || !currentTenantId,
        fetchPolicy: 'network-only',
        variables: {
            tenantId: currentTenantId || '',
            statusReportId: statusReportId || '',
        },
        onCompleted: (d) => {
            if (!changeTracker.current) {
                resetChangeTracker(d.statusReport);
            }

            const isNew =
                !d.statusReport?.utcUpdated ||
                dayjs(d.statusReport?.utcUpdated).diff(
                    dayjs(d.statusReport?.utcCreated),
                    'seconds'
                ) < 1;

            // Display the fact panel once on a virgin report
            if (
                isNew &&
                d.statusReport?.facts.length === 0 &&
                d.statusReport.mission?.rights.write &&
                !d.statusReport.utcCompletedDate &&
                !hasForcedOpenedFactPanel
            ) {
                // Its new if the report hasn't been updated or the update date matches the creation date
                setShowAddFactsPanel(true);
                setHasForcedOpenedFactPanel(true);
            }
        },
    });

    const [hasForcedOpenedFactPanel, setHasForcedOpenedFactPanel] =
        useState(false);
    const [showAddFactsPanel, setShowAddFactsPanel] = useState(false);

    const getInput = (
        statusReport: NonNullable<GetStatusReportQuery['statusReport']>
    ): StatusReport => {
        const r = statusReport;

        return {
            id: r.id,
            missionId: r.missionId,
            title: r.title,
            reportDate: r.reportDate,
            utcCompletedDate: r.utcCompletedDate,
            utcDataDate: r.utcDataDate,
            summaryText: r.summaryText,
            lastPeriodText: r.lastPeriodText,
            nextPeriodText: r.nextPeriodText,
            risksAndOpportunitiesText: r.risksAndOpportunitiesText,
            supportText: r.supportText,
            version: r.version,
            facts: r.facts
                .slice()
                .sort(sorters.sequenceSorter)
                .map((f) => {
                    return {
                        id: f.id,
                        measureId: f.measureId,
                        taskId: f.taskId,
                        actionText: f.actionText,
                        soWhatText: f.soWhatText,
                        factText: f.factText,
                        insightText: f.insightText,
                        isIncluded: f.isIncluded,
                        sequence: f.sequence,
                        version: f.version,
                    };
                }),
        };
    };

    const saveAsync = async (
        tenantId: string | undefined,
        input: StatusReport,
        saveReport: boolean,
        saveFacts: string[]
    ): Promise<void> => {
        if (!tenantId) {
            return;
        }
        if (saveReport) {
            const result = await updateStatusReport({
                variables: {
                    tenantId: tenantId,
                    input: { ...input, facts: [] }, // Facts updated later
                    notifyUserIds: [],
                },
            });

            const newVersion = result.data?.statusReportUpdate.version;

            await updateInput(
                (ct) => ({
                    ...ct,
                    input: {
                        ...ct.input,
                        version: newVersion || null,
                    },
                }),
                true
            );
        }

        for (const fid of saveFacts) {
            const factInput = input?.facts.find((fi) => fi.id === fid);

            if (factInput?.id && input?.id) {
                const result = await updateStatusReportFact({
                    variables: {
                        tenantId: tenantId,
                        statusReportId: input?.id,
                        fact: factInput,
                    },
                });

                await updateFactVersion(
                    factInput.id,
                    result.data?.statusReportFactUpdate.version || null
                );
            }
        }
    };

    const debouncedSave = debounce(async (tenantId: string | undefined) => {
        if (!changeTracker.current) {
            return;
        }

        // If its saving, wait.
        if (changeTracker.current.isSaving) {
            debouncedSave(tenantId);

            return;
        }

        // Mark as saving.
        changeTracker.current.isSaving = true;

        // Work out what we want to save this time.
        const saveReport = changeTracker.current.reportChanged;
        const saveFacts = changeTracker.current.factIdsChanged.slice();

        // Clear the changes for the next save
        changeTracker.current.reportChanged = false;
        changeTracker.current.factIdsChanged = [];

        await saveAsync(
            tenantId,
            changeTracker.current.input,
            saveReport,
            saveFacts
        );

        // No longer saving
        changeTracker.current.isSaving = false;

        return;
    }, 2000);

    useEffect(() => {
        return () => {
            // Save on cleanup
            debouncedSave.flush();
        };
    }, [debouncedSave]);

    const [updateStatusReport, { loading: isUpdating, error: updateError }] =
        useUpdateStatusReportMutation();

    const [
        updateStatusReportFact,
        { loading: isUpdatingFact, error: updateFactError },
    ] = useUpdateStatusReportFactMutation();

    //const [refreshData] = useStatusReportDataRefreshMutation();

    const [deleteStatusReport, { loading: isDeleting, error: deleteError }] =
        useDeleteStatusReportMutation();

    const hasError = !!updateError || !!updateFactError;

    const errorMessageBarContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (hasError) {
            errorMessageBarContainer.current?.scrollIntoView();
        }
    }, [hasError]);

    const classNames = mergeStyleSets({
        bottomBoxes: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            '@media(max-width: 600px)': {
                gridTemplateColumns: '1fr',
            },
            gridAutoRows: 'minmax(auto, auto)',
            gridGap: 16,
            gridAutoFlow: 'dense',
        },
        bottomBox: {
            padding: 0,
            gridColumnEnd: 'span 1',
            gridRowEnd: 'span 1',
        },
    });

    const statusReport = data?.statusReport;
    const mission = statusReport?.mission;
    const isReadOnly =
        !mission?.rights.write ||
        statusReport?.utcCompletedDate !== null ||
        hasError;
    const includedFacts =
        statusReport?.facts?.filter((f) => f.isIncluded) || [];

    useEffect(() => {
        if (mission) {
            setDocTitle(`${mission?.owner} (${mission?.title}) Status Report`);
        }
    }, [mission]);

    const updateInput = useCallback(
        async (
            func: (r: ChangeTracker) => ChangeTracker,
            skipSave?: boolean
        ) => {
            const tracker =
                changeTracker.current ||
                (data?.statusReport
                    ? {
                          input: getInput(data.statusReport),
                          reportChanged: false,
                          factIdsChanged: [],
                          isSaving: false,
                      }
                    : null);

            if (tracker) {
                const updated = func(tracker);
                changeTracker.current = updated;
                if (!skipSave) {
                    await debouncedSave(currentTenantId);
                }
            }
        },
        [currentTenantId, data?.statusReport, debouncedSave]
    );

    const handleSummaryTextChanged = useCallback(
        (value: string) => {
            updateInput((ct) => ({
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    summaryText: value,
                },
            }));
        },
        [updateInput]
    );

    const handleLastPeriodTextChanged = useCallback(
        (value: string) => {
            updateInput((ct) => ({
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    lastPeriodText: value,
                },
            }));
        },
        [updateInput]
    );

    const handleNextPeriodTextChanged = useCallback(
        (value: string) => {
            updateInput((ct) => ({
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    nextPeriodText: value,
                },
            }));
        },
        [updateInput]
    );

    const handleSupportTextChanged = useCallback(
        (value: string) => {
            updateInput((ct) => ({
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    supportText: value,
                },
            }));
        },
        [updateInput]
    );

    const handleRisksAndOpportunitiesTextChanged = useCallback(
        (value: string) => {
            updateInput((ct) => ({
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    risksAndOpportunitiesText: value,
                },
            }));
        },
        [updateInput]
    );

    const updateFactVersion = async (
        factId: string,
        version: string | null
    ) => {
        await updateInput((tracker) => {
            const sr = tracker.input;
            const existing = sr?.facts?.find((f) => f.id === factId);
            if (sr?.id && existing) {
                return {
                    ...tracker,
                    input: {
                        ...sr,
                        facts: [
                            ...(sr?.facts || []).filter(
                                (f) => f.id !== existing.id
                            ),
                            {
                                ...existing,
                                version: version,
                            },
                        ],
                    },
                };
            } else {
                return tracker;
            }
        }, true);
    };

    const onFactChanged = useCallback(
        async (fact: {
            id: string;
            factText: string | null;
            soWhatText: string | null;
            insightText: string | null;
            actionText: string | null;
            sequence: number;
        }) => {
            if (!fact.id) {
                return;
            }

            await updateInput((tracker) => {
                const sr = tracker.input;
                const existing = sr?.facts?.find((f) => f.id === fact.id);
                if (sr?.id && existing) {
                    const updatedFacts = [
                        ...(sr?.facts || []).filter(
                            (f) => f.id !== existing.id
                        ),
                        {
                            ...existing,
                            factText: fact.factText,
                            soWhatText: fact.soWhatText,
                            insightText: fact.insightText,
                            actionText: fact.actionText,
                            sequence: fact.sequence,
                        },
                    ];

                    setActionList(
                        updatedFacts
                            .slice()
                            .sort(sorters.sequenceSorter)
                            .filter((f) => f.actionText?.trim() && f.isIncluded)
                            .map((f) => f.actionText?.trim() || '') || []
                    );

                    return {
                        ...tracker,
                        input: {
                            ...sr,
                            facts: updatedFacts,
                        },
                        factIdsChanged: uniq([
                            ...tracker.factIdsChanged,
                            fact.id,
                        ]),
                    };
                } else {
                    return tracker;
                }
            });
        },
        [updateInput]
    );

    const onFactRemoved = async (factId: string) => {
        await updateInput((tracker) => {
            const sr = tracker.input;
            const existing = sr?.facts?.find((f) => f.id === factId);
            if (sr?.id && existing) {
                const updatedFacts = [
                    ...(sr?.facts || []).filter((f) => f.id !== existing.id),
                    {
                        ...existing,
                        isIncluded: false,
                    },
                ];
                return {
                    ...tracker,
                    input: {
                        ...sr,
                        facts: updatedFacts,
                    },
                    factIdsChanged: uniq([...tracker.factIdsChanged, factId]),
                };
            } else {
                return tracker;
            }
        });
        await debouncedSave.flush();
    };

    const resetChangeTracker = (
        statusReport: GetStatusReportQuery['statusReport']
    ) => {
        if (statusReport) {
            changeTracker.current = {
                input: getInput(statusReport),
                factIdsChanged: [],
                reportChanged: false,
                isSaving: false,
            };

            setActionList(
                statusReport.facts
                    .slice()
                    .sort(sorters.sequenceSorter)
                    .filter((f) => f.actionText?.trim() && f.isIncluded)
                    .map((f) => f.actionText?.trim() || '')
            );
        }
    };

    const handleAddFactsPanelDismiss = async () => {
        setShowAddFactsPanel(false);
        const result = await refetch();
        resetChangeTracker(result.data.statusReport);
    };

    const handleAddFactsButtonClick = async () => {
        await debouncedSave.flush();
        setShowAddFactsPanel(true);
    };

    const [hideSubmitDialog, setHideSubmitDialog] = useState(true);
    const [hideUnsubmitDialog, setHideUnsubmitDialog] = useState(true);

    const handleSubmitButtonClick = async () => {
        await debouncedSave.flush();
        setHideSubmitDialog(false);
    };

    const handleSubmitDialogDismiss = () => setHideSubmitDialog(true);
    const handleUnsubmitButtonClick = () => setHideUnsubmitDialog(false);
    const handleUnsubmitDialogDismiss = () => setHideUnsubmitDialog(true);

    const handleConfirmSubmitClick = async (
        title: string,
        reportDate: string | null,
        notifyUserIds: string[]
    ) => {
        await debouncedSave.flush();

        await updateInput((ct) => {
            return {
                ...ct,
                input: {
                    ...ct.input,
                    title: title,
                    reportDate: reportDate,
                    utcCompletedDate: dayjs.utc().format(),
                },
            };
        }, true);

        if (currentTenantId && changeTracker.current?.input) {
            const result = await updateStatusReport({
                variables: {
                    tenantId: currentTenantId,
                    input: { ...changeTracker.current.input, facts: [] },
                    notifyUserIds: notifyUserIds,
                },
            });

            const newVersion = result.data?.statusReportUpdate.version;

            if (newVersion) {
                changeTracker.current.input.version = newVersion;
            }

            changeTracker.current.reportChanged = false;
        }

        setHideSubmitDialog(true);
    };

    const handleConfirmUnsubmitClick = async (getLatestValues: boolean) => {
        await updateInput((ct) => {
            return {
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    utcCompletedDate: null,
                    utcDataDate: getLatestValues
                        ? null
                        : (ct.input.utcDataDate ?? ct.input.utcCompletedDate),
                },
            };
        });
        await debouncedSave.flush();
        setHideUnsubmitDialog(true);
    };

    const handleRefreshDataButtonClick = async () => {
        await updateInput((ct) => {
            return {
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    utcDataDate: null,
                },
            };
        });
        await debouncedSave.flush();
        setHideUnsubmitDialog(true);
    };

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const handleDeleteButtonClick = async () => {
        await debouncedSave.flush();
        setIsDeleteModalOpen(true);
    };
    const handleDismissDeleteModal = () => setIsDeleteModalOpen(false);
    const deleteAction = async () => {
        if (currentTenantId && statusReportId) {
            await debouncedSave.flush();
            await deleteStatusReport({
                variables: {
                    tenantId: currentTenantId,
                    statusReportId: statusReportId,
                    restore: false,
                },
            });
        }

        setIsDeleteModalOpen(false);

        const href = generatePath(paths.statusReports, params);
        navigate(href);
    };

    const handleReportDateChanged = async (reportDate?: string | null) => {
        if (!reportDate) {
            return;
        }

        await updateInput((ct) => {
            return {
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    reportDate: reportDate,
                },
            };
        });

        await debouncedSave.flush();
    };

    const handleTitleUpdated = async (title?: string | null) => {
        await updateInput((ct) => {
            return {
                ...ct,
                reportChanged: true,
                input: {
                    ...ct.input,
                    title: title || '',
                },
            };
        });

        await debouncedSave.flush();
    };

    const handleActivePanelChanged = useCallback(
        (panelType: PanelTypes): void => setActivePanel(panelType),
        []
    );

    const onActiveTaskChanged = useCallback(
        (taskId: string | null) => setActiveTaskId(taskId),
        []
    );

    const handleMeasureNavigate = useCallback((measureId: string) => {
        setActiveTaskId(null);
        setActiveMeasureId(measureId);
        setActivePanel('measure-overview');
    }, []);

    const handleTaskNavigate = useCallback(
        (taskId: string) => {
            setActiveMeasureId(null);
            setActiveTaskId(taskId);
            setActivePanel(isReadOnly ? 'task-alignment' : 'edit-task');
        },
        [isReadOnly]
    );

    const handleExportButtonClick = async () => {
        try {
            setIsExporting(true);
            await statusReportExport(
                configuration,
                params.statusReportId,
                currentTenantId,
                statusReport?.mission?.owner
            );
        } catch (e) {
            console.log(e);
        } finally {
            setIsExporting(false);
        }
    };

    const handlePresentButtonClick = () => {
        debouncedSave.flush();
        const href = generatePath(paths.presentStatusReport, {
            ...params,
            presentation: 'status-report',
        });
        navigate(href);
    };

    const buttons: AreaContainerButton[] = [];

    if (statusReport?.mission?.rights?.export) {
        buttons.push({
            key: 'Export',
            text: 'Export',
            iconProps: {
                iconName: 'PowerPointDocument',
            },
            disabled: isExporting,
            onClick: () => {
                handleExportButtonClick();
            },
            onRenderIcon: (_props, defaultRenderer) => {
                if (isExporting) {
                    return <Spinner size={SpinnerSize.small} />;
                }

                return defaultRenderer ? defaultRenderer() : null;
            },
        });
    }

    buttons.push({
        key: 'Present',
        text: 'Present',
        iconProps: {
            iconName: 'Presentation',
        },
        onClick: handlePresentButtonClick,
    });

    if (!statusReport?.utcCompletedDate && mission?.rights.write) {
        buttons.push({
            key: 'PublishReport',
            text: 'Publish',
            iconProps: {
                iconName: 'PublishContent',
            },
            onClick: () => {
                handleSubmitButtonClick();
            },
        });
    }

    if (statusReport?.utcCompletedDate && mission?.rights.write) {
        const disabled =
            !statusReport.mission?.team?.division?.canUnpublishReport &&
            !isAdmin;

        buttons.push({
            key: 'UnpublishReport',
            text: 'Unpublish',
            iconProps: {
                iconName: 'UnpublishContent',
            },
            disabled: disabled,
            title: disabled
                ? 'Only an admin can unpublish this report.'
                : undefined,
            onClick: handleUnsubmitButtonClick,
        });
    }

    if (mission?.rights.write && !statusReport?.utcCompletedDate) {
        buttons.push({
            key: 'Delete',
            text: 'Delete',
            iconOnly: true,
            iconProps: {
                iconName: 'Delete',
            },
            onClick: () => {
                handleDeleteButtonClick();
            },
        });
    }

    const leaderMission = mission?.team?.leaderMission;
    const teamLeader =
        leaderMission?.userId &&
        leaderMission?.username &&
        leaderMission?.userId !== mission?.userId
            ? {
                  userId: leaderMission?.userId,
                  displayName: leaderMission?.username,
              }
            : null;

    if (!loading && !mission?.rights.read) {
        return (
            <MessageBar
                messageBarType={MessageBarType.warning}
                messageBarIconProps={{
                    iconName: 'AlertSolid',
                }}
                isMultiline={true}
            >
                <span>You do not have access to view this mission</span>
            </MessageBar>
        );
    }

    return (
        <React.Fragment>
            <EditPanels
                activePanel={activePanel}
                missionId={data?.statusReport?.missionId}
                measureId={activeMeasureId}
                taskId={activeTaskId}
                teamId={mission?.team?.id}
                onActivePanelChanged={handleActivePanelChanged}
                onActiveTaskChanged={onActiveTaskChanged}
                hideDelete
            />

            <DeleteModal
                activeViewName="StatusReportDelete"
                isOpen={isDeleteModalOpen}
                onDismiss={handleDismissDeleteModal}
                isDeleting={isDeleting}
                deleteAction={deleteAction}
                error={deleteError}
                message="Are you sure you want to delete this status report?"
            />

            {!!statusReport && !!mission && (
                <StatusReportPublishDialog
                    hidden={hideSubmitDialog}
                    onDismiss={handleSubmitDialogDismiss}
                    onConfirm={handleConfirmSubmitClick}
                    disabled={isUpdating || isUpdatingFact || loading}
                    teamLeader={teamLeader}
                    mission={mission}
                    reportDate={statusReport.reportDate}
                    title={statusReport.title}
                />
            )}

            <StatusReportUnPublishDialog
                hidden={hideUnsubmitDialog}
                onDismiss={handleUnsubmitDialogDismiss}
                onConfirm={handleConfirmUnsubmitClick}
                disabled={isUpdating || isUpdatingFact || loading}
            />

            {currentTenantId && statusReport?.id && mission?.id && (
                <StatusReportAddFactPanel
                    utcCompletedDate={statusReport.utcCompletedDate}
                    utcDataDate={statusReport.utcDataDate}
                    showPanel={showAddFactsPanel}
                    onDismiss={handleAddFactsPanelDismiss}
                    missionId={mission.id}
                    statusReport={statusReport}
                    tenantId={currentTenantId}
                    selectedFacts={includedFacts}
                />
            )}

            {!loading && (
                <AreaContainer
                    title={
                        <StatusReportTitle
                            title={statusReport?.title}
                            isReadOnly={isReadOnly}
                            onTitleUpdated={handleTitleUpdated}
                        />
                    }
                    isCollapsable={false}
                    subTitle={mission?.missionStatement}
                    commandBarButtons={buttons}
                >
                    {!loading && (
                        <Stack tokens={{ childrenGap: 16, padding: 8 }}>
                            <Stack
                                horizontal={!isMobileMode}
                                horizontalAlign="stretch"
                            >
                                <Stack.Item grow align="end">
                                    {!!statusReport?.reportDate &&
                                        isReadOnly && (
                                            <Stack
                                                horizontal
                                                tokens={{ childrenGap: 16 }}
                                            >
                                                <Label>Report Date: </Label>
                                                <Label>
                                                    {dayjs
                                                        .utc(
                                                            statusReport?.reportDate
                                                        )
                                                        .format('DD MMM YYYY')}
                                                </Label>
                                            </Stack>
                                        )}

                                    {!!statusReport?.reportDate &&
                                        !isReadOnly && (
                                            <div style={{ maxWidth: 200 }}>
                                                <StatusReportAsOfDatePicker
                                                    value={
                                                        changeTracker.current
                                                            ?.input
                                                            .reportDate ||
                                                        dayjs
                                                            .utc()
                                                            .toISOString()
                                                    }
                                                    mission={
                                                        statusReport?.mission
                                                    }
                                                    onSelectDate={
                                                        handleReportDateChanged
                                                    }
                                                />
                                            </div>
                                        )}
                                </Stack.Item>

                                <Stack.Item align="end">
                                    <Stack
                                        tokens={{ childrenGap: 4 }}
                                        styles={{
                                            root: { textAlign: 'right' },
                                        }}
                                    >
                                        {!statusReport?.utcCompletedDate && (
                                            <span
                                                style={{
                                                    fontSize: '1em',
                                                    fontWeight: 'bold',
                                                    color: currentTheme
                                                        .semanticColors
                                                        .severeWarningIcon,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Draft
                                            </span>
                                        )}

                                        {!statusReport?.utcCompletedDate &&
                                            statusReport?.utcUpdated && (
                                                <Text variant="small">
                                                    Last saved:{' '}
                                                    {dayjs
                                                        .utc(
                                                            statusReport?.utcUpdated
                                                        )
                                                        .tz(dayjs.tz.guess())
                                                        .format(
                                                            'DD MMM YYYY HH:mm (z)'
                                                        )}
                                                </Text>
                                            )}

                                        {statusReport?.utcCompletedDate && (
                                            <Text variant="small">
                                                This report was published on{' '}
                                                {dayjs
                                                    .utc(
                                                        statusReport?.utcCompletedDate
                                                    )
                                                    .tz(dayjs.tz.guess())
                                                    .format(
                                                        'DD MMM YYYY HH:mm (z)'
                                                    )}
                                            </Text>
                                        )}

                                        {statusReport?.utcDataDate &&
                                            statusReport?.utcCompletedDate !==
                                                statusReport?.utcDataDate && (
                                                <Text variant="small">
                                                    Fact values are as of{' '}
                                                    {dayjs
                                                        .utc(
                                                            statusReport?.utcDataDate
                                                        )
                                                        .tz(dayjs.tz.guess())
                                                        .format(
                                                            'DD MMM YYYY HH:mm (z)'
                                                        )}
                                                </Text>
                                            )}

                                        {!statusReport?.utcCompletedDate &&
                                            statusReport?.utcDataDate &&
                                            !isReadOnly &&
                                            currentTenantId && (
                                                <Stack.Item>
                                                    <StatusReportDataChangedIndicator
                                                        tenantId={
                                                            currentTenantId
                                                        }
                                                        missionId={
                                                            statusReport.missionId
                                                        }
                                                        utcDataDate={
                                                            statusReport.utcDataDate
                                                        }
                                                        onRefreshDataButtonClick={
                                                            handleRefreshDataButtonClick
                                                        }
                                                    />
                                                </Stack.Item>
                                            )}
                                    </Stack>
                                </Stack.Item>
                            </Stack>

                            {(!!updateError || !!updateFactError) && (
                                <div ref={errorMessageBarContainer}>
                                    <ErrorMessageBar
                                        error={updateError ?? updateFactError}
                                    />
                                </div>
                            )}
                            {statusReport && (
                                <StatusReportEditorCard
                                    title="Executive Summary"
                                    iconName="DietPlanNotebook"
                                    defaultValue={statusReport.summaryText}
                                    onChange={handleSummaryTextChanged}
                                    readOnly={isReadOnly}
                                    canExpandCollapse={true}
                                />
                            )}

                            {currentTenantId &&
                                statusReport &&
                                mission?.id &&
                                mission?.team?.division?.financialYear && (
                                    <StatusReportGrid
                                        tenantId={currentTenantId}
                                        fyStartDate={
                                            mission?.team?.division
                                                ?.financialYear.startDate
                                        }
                                        missionId={mission?.id}
                                        utcDataDate={statusReport.utcDataDate}
                                        utcCompletedDate={
                                            statusReport.utcCompletedDate
                                        }
                                        facts={statusReport.facts}
                                        onFactChanged={onFactChanged}
                                        onFactRemoved={onFactRemoved}
                                        missionAccess={mission.rights}
                                        isReadOnly={isReadOnly}
                                        onMeasureNavigate={
                                            handleMeasureNavigate
                                        }
                                        onTaskNavigate={handleTaskNavigate}
                                    />
                                )}

                            {!isReadOnly && (
                                <Stack.Item align="start">
                                    <ActionButton
                                        iconProps={{ iconName: 'Add' }}
                                        onClick={handleAddFactsButtonClick}
                                    >
                                        Add fact...
                                    </ActionButton>
                                </Stack.Item>
                            )}

                            <div className={classNames.bottomBoxes}>
                                <div className={classNames.bottomBox}>
                                    {statusReport && (
                                        <StatusReportEditorCard
                                            title="Last Period"
                                            iconName="Rewind"
                                            defaultValue={
                                                statusReport.lastPeriodText
                                            }
                                            onChange={
                                                handleLastPeriodTextChanged
                                            }
                                            readOnly={isReadOnly}
                                        />
                                    )}
                                </div>
                                <div className={classNames.bottomBox}>
                                    {statusReport && (
                                        <StatusReportEditorCard
                                            title="Next Period"
                                            iconName="FastForward"
                                            defaultValue={
                                                statusReport.nextPeriodText
                                            }
                                            onChange={
                                                handleNextPeriodTextChanged
                                            }
                                            readOnly={isReadOnly}
                                            insertButton={{
                                                iconName: 'DoubleDownArrow',
                                                text: 'Insert actions from above',
                                                listToInsert: actionList,
                                                disabled:
                                                    actionList.length === 0,
                                            }}
                                        />
                                    )}
                                </div>
                                <div className={classNames.bottomBox}>
                                    {statusReport && (
                                        <StatusReportEditorCard
                                            title="Risks / Opportunities"
                                            iconName="ReportWarning"
                                            defaultValue={
                                                statusReport.risksAndOpportunitiesText
                                            }
                                            onChange={
                                                handleRisksAndOpportunitiesTextChanged
                                            }
                                            readOnly={isReadOnly}
                                        />
                                    )}
                                </div>
                                <div className={classNames.bottomBox}>
                                    {statusReport && (
                                        <StatusReportEditorCard
                                            title="Support"
                                            iconName="OfficeChat"
                                            defaultValue={
                                                statusReport?.supportText
                                            }
                                            onChange={handleSupportTextChanged}
                                            readOnly={isReadOnly}
                                        />
                                    )}
                                </div>
                            </div>
                        </Stack>
                    )}
                </AreaContainer>
            )}
        </React.Fragment>
    );
}

export default StatusReportScene;

export function StatusReportUnPublishDialog(props: {
    hidden: boolean;
    onDismiss: () => void;
    onConfirm: (getLatestValues: boolean) => void;
    disabled: boolean;
}): JSX.Element {
    const [isGetLatestValuesChecked, setIsGetLatestValuesChecked] =
        useState(false);

    const onConfirmButtonButtonClick = () => {
        props.onConfirm(isGetLatestValuesChecked);
    };

    return (
        <Dialog
            hidden={props.hidden}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Confirm Unpublish',
                closeButtonAriaLabel: 'Close',
                subText: 'Do you want to mark this report as a draft?',
            }}
        >
            <Checkbox
                checked={isGetLatestValuesChecked}
                label="Update measures of success and task facts with the latest values"
                onChange={(_ev, checked) => {
                    setIsGetLatestValuesChecked(!!checked);
                }}
            />

            <DialogFooter>
                <PrimaryButton
                    onClick={onConfirmButtonButtonClick}
                    text="Confirm Unpublish"
                    disabled={props.disabled}
                />
                <DefaultButton onClick={props.onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
}
