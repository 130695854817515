import dayjs from 'dayjs';
import { ReportPeriodTypes } from '../../../data/types';
import { getQuarter } from '../../Measure/utils/measureUtils';

export function useTemplateReportPeriods(
    fyStartDate: string | null | undefined,
    fyEndDate: string | null | undefined
): {
    getPeriods(
        reportPeriodType: ReportPeriodTypes | null | undefined
    ): { text: string; period: number }[];
    formatPeriod(
        reportPeriodType: ReportPeriodTypes | null | undefined,
        reportPeriod: number | null | undefined
    ): string;
} {
    return {
        formatPeriod: (
            reportPeriodType: ReportPeriodTypes | null | undefined,
            reportPeriod: number | null | undefined
        ) => {
            const prefix =
                reportPeriodType === ReportPeriodTypes.Quarter
                    ? 'Q'
                    : reportPeriodType === ReportPeriodTypes.Half
                      ? 'H'
                      : reportPeriodType === ReportPeriodTypes.Month
                        ? 'M'
                        : '';
            return `${prefix}${reportPeriod}`;
        },
        getPeriods: (reportPeriodType: ReportPeriodTypes | null) => {
            if (!fyStartDate || !reportPeriodType) {
                return [];
            }

            const periods: { text: string; period: number }[] = [];

            let currentDate = dayjs().isAfter(fyEndDate)
                ? dayjs(fyEndDate)
                : dayjs();

            if (dayjs(currentDate).isBefore(fyStartDate)) {
                currentDate = dayjs(fyStartDate);
            }

            switch (reportPeriodType) {
                case ReportPeriodTypes.Month:
                    // TODO
                    break;
                case ReportPeriodTypes.Quarter: {
                    const quarter = getQuarter(fyStartDate, currentDate);
                    if (quarter) {
                        periods.push({
                            period: quarter,
                            text: `This quarter - Q${quarter}`,
                        });
                    }
                    if (quarter > 1) {
                        periods.push({
                            period: quarter - 1,
                            text: `Last quarter - Q${quarter - 1}`,
                        });
                    }
                    break;
                }
                case ReportPeriodTypes.Half:
                    // TODO
                    break;
                default:
                    break;
            }

            return periods;
        },
    };
}
