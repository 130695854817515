import * as React from 'react';
import { SetupPageContainer } from './components/SetupPageContainer';
import {
    Stack,
    DefaultButton,
    ScrollablePane,
    ScrollbarVisibility,
} from '@fluentui/react';
import { TenantEditPanel } from './components/TenantEditPanel';
import { TenantDetailList } from './components/TenantDetailList';
import { useGetTenantsQuery } from '../../data/types';
import { AdvanceCard } from '../../components/AdvanceCard';

export function Tenants(): JSX.Element {
    const { data, loading, refetch } = useGetTenantsQuery();

    const [showAddPanel, setShowAddPanel] = React.useState<boolean>(false);
    const [selectedTenant, setSelectedTenant] = React.useState<{
        id: string | null;
        code: string | null;
        description: string | null;
        isEnabled: boolean;
        isAnalyticsEnabled: boolean;
    } | null>();

    const tenants = data?.tenants;

    return (
        <SetupPageContainer>
            <Stack horizontal tokens={{ padding: 0, childrenGap: 'm' }}>
                <Stack.Item grow>
                    <span />
                </Stack.Item>
                <Stack.Item align="end">
                    <DefaultButton
                        text="New tenant"
                        iconProps={{ iconName: 'Add' }}
                        onClick={(): void => {
                            setSelectedTenant(null);
                            setShowAddPanel(true);
                        }}
                    />
                </Stack.Item>
            </Stack>

            <div className="content-container">
                <AdvanceCard
                    style={{
                        height: '100%',
                    }}
                >
                    <ScrollablePane
                        scrollbarVisibility={ScrollbarVisibility.always}
                    >
                        <TenantDetailList
                            tenants={tenants || []}
                            isDataLoaded={!loading}
                            onItemEdit={(selectedId: string): void => {
                                setSelectedTenant(
                                    (tenants || []).find(
                                        (t) => t.id === selectedId
                                    )
                                );
                                setShowAddPanel(true);
                            }}
                        />
                    </ScrollablePane>
                </AdvanceCard>
            </div>

            <TenantEditPanel
                showPanel={showAddPanel}
                tenant={selectedTenant}
                key={
                    `EditTenantPanel_${selectedTenant?.id}` ||
                    'EditTenantPanel_New'
                }
                onUpdate={(): void => {
                    setShowAddPanel(false);
                }}
                onCreate={(): void => {
                    setShowAddPanel(false);
                    refetch();
                }}
                onCancel={(): void => {
                    setShowAddPanel(false);
                }}
                onDelete={() => {
                    setShowAddPanel(false);
                    refetch();
                }}
            />
        </SetupPageContainer>
    );
}
