import { useEffect } from 'react';

export enum ActiveViews {
    Contributor,
    ContributorDelete,
    ContributorEdit,
    DependencyActions,
    DivisionEdit,
    Error,
    FinancialYearEdit,
    HelpContent,
    Home,
    Imports,
    MissionBuilder,
    MissionDelete,
    MissionEdit,
    MissionGrouping,
    MissionPresentation,
    MissionNew,
    Measure,
    MeasureAttachments,
    MeasureAttachmentDelete,
    MeasureComments,
    MeasureCommentDelete,
    MeasureCommentEdit,
    MeasureDelete,
    MeasureEdit,
    MeasureGrouping,
    MeasureOverview,
    MeasureValueDelete,
    MeasureValues,
    Notifications,
    PrivacyPolicy,
    Reports,
    ResourceDelete,
    ResourceEdit,
    Setup,
    StatusReport,
    StatusReportDelete,
    StatusReportFactNew,
    StatusReportFactDelete,
    StatusReportCreate,
    StatusReportPresentation,
    StatusReports,
    SwitchTenant,
    TagEdit,
    TagDelete,
    TaskAlignment,
    TaskAttachments,
    TaskAttachmentDelete,
    TaskComments,
    TaskCommentDelete,
    TaskCommentEdit,
    TaskDelete,
    TaskEdit,
    TaskCategoryDelete,
    TaskCategoryEdit,
    TaskHistory,
    TaskMap,
    Team,
    TeamAccess,
    TeamDelete,
    TeamEdit,
    TemplateReport,
    TemplateReportDelete,
    TemplateReportCustomTableRowDelete,
    TemplateReportPresentation,
    TenantDelete,
    TenantEdit,
    TermsOfUse,
    UserAccess,
    UserDelete,
    UserEdit,
    UserProfile,
}

export function useActiveView(
    activeView: keyof typeof ActiveViews | null | undefined,
    title?: string | null | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    additionalProperties?: {
        [key: string]: string | number | boolean | undefined;
    }
): void {
    // useEffect(() => {
    //     let prevView: string | undefined = undefined;

    //     if (activeView) {
    //         const wfxWindow = (window as WhatfixWindow) || { wfx: {} };
    //         prevView = wfxWindow.wfx?.activeView || undefined;
    //         wfxWindow.wfx = {
    //             ...(wfxWindow.wfx || {}),
    //             activeView: activeView.toString(),
    //         };
    //     }
    //     return () => {
    //         if (prevView) {
    //             const wfxWindow = (window as WhatfixWindow) || { wfx: {} };
    //             wfxWindow.wfx = {
    //                 ...(wfxWindow.wfx || {}),
    //                 activeView: prevView,
    //             };
    //         }
    //     };
    // }, [activeView]);

    // useEffect(() => {
    //     if (activeView) {
    //         const wfxWindow = (window as WhatfixWindow) || { wfx: {} };
    //         wfxWindow.wfx = {
    //             userId: wfxWindow.wfx?.userId,
    //             userRoles: wfxWindow.wfx?.userRoles,
    //             tenantCode: wfxWindow.wfx?.tenantCode,
    //             financialYearCode: wfxWindow.wfx?.financialYearCode,
    //             teamCode: wfxWindow.wfx?.teamCode,
    //             activeView: wfxWindow.wfx?.activeView,
    //             isNewUser: wfxWindow.wfx?.isNewUser,
    //             isMobile: wfxWindow.wfx?.isMobile,
    //             isMissionOwner: wfxWindow.wfx?.isMissionOwner,
    //             isTeamLeader: wfxWindow.wfx?.isTeamLeader,
    //             isGpUser: wfxWindow.wfx?.isGpUser,
    //             themePrimaryColourHex: wfxWindow.wfx?.themePrimaryColourHex,
    //             ...additionalProperties,
    //         };
    //     }
    // }, [additionalProperties, activeView]);

    useEffect(() => {
        const prevTitle = document.title;
        if (title) {
            document.title = `Advance - ${title}`;
        }
        return () => {
            document.title = prevTitle;
        };
    }, [title]);
}
