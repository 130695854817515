import React, { CSSProperties } from 'react';
import { Label, Link, Stack, Text } from '@fluentui/react';
import { GetTasksForStatusReportQuery } from '../../../data/types';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import { TaskCategoryLabel } from '../../../components/TaskCategoryLabel';
import { useThemes } from '../../../hooks/useThemes';
import dayjs from 'dayjs';

export function StatusReportGridTaskDetail(props: {
    task: ExtractQueryArrayType<GetTasksForStatusReportQuery, ['tasks']> | null;
    allowNavigation: boolean;
    onNavigate: () => void;
}): JSX.Element {
    const { currentTheme } = useThemes();

    const { task } = props;

    const detailToken = { childrenGap: 4 };

    const formatter = new Intl.NumberFormat(undefined, {
        style: 'percent',
        maximumFractionDigits: 1,
    });

    const formattedPercentage = formatter.format(task?.percentComplete ?? 0);

    const itemStyle: CSSProperties = {
        paddingLeft: 8,
        borderLeftStyle: 'solid',
        borderLeftWidth: 4,
        borderLeftColor: task?.taskCategory
            ? `#${task?.taskCategory.colourHex}`
            : currentTheme.palette.neutralLighter,
        textDecoration: task?.utcDeleted ? 'line-through' : undefined,
    };

    return (
        <Stack tokens={{ childrenGap: 4 }}>
            <Label>
                Specified Task{' '}
                {!task || task.utcDeleted
                    ? `(Deleted ${dayjs(task?.utcDeleted).format(
                          'DD MMM YYYY'
                      )})`
                    : ''}
            </Label>

            <Stack style={itemStyle} tokens={{ childrenGap: 2 }}>
                {task && !task.utcDeleted && (
                    <TaskCategoryLabel {...task?.taskCategory} />
                )}

                <Text
                    variant="mediumPlus"
                    block
                    styles={{
                        root: {
                            textDecoration: task?.utcDeleted
                                ? 'line-through'
                                : undefined,
                        },
                    }}
                >
                    {props.allowNavigation && !task?.utcDeleted ? (
                        <Link onClick={props.onNavigate}>{task?.name}</Link>
                    ) : (
                        task?.name
                    )}

                    {!task && (
                        <span style={{ fontStyle: 'italic' }}>
                            This task has been deleted
                        </span>
                    )}
                </Text>

                {task && (
                    <Stack horizontal tokens={detailToken}>
                        <Stack.Item>
                            <Text variant="smallPlus">Completion:</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="small">{formattedPercentage}</Text>
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
}
