import React, { useCallback } from 'react';

import {
    IBreadcrumbStyles,
    IBreadcrumbItem,
    Breadcrumb,
    Shimmer,
    IContextualMenuProps,
    IShimmerStyles,
} from '@fluentui/react';
import { useBreadcrumbItems } from './useBreadcrumbItems';
import { BreadcrumbButton } from './BreadcrumbButton';
import { BreadcrumbOverflowButton } from './BreadcrumbOverflowButton';

export interface AdvanceBreadcrumb extends IBreadcrumbItem {
    menuProps: IContextualMenuProps;
}

type BreadcrumbsProps = {
    tenantId: string;
    financialYearCode: string;
    tenantCode: string;
    tenantName: string;
    teamCode: string | undefined;
    missionId: string | undefined;
};

function Breadcrumbs(props: BreadcrumbsProps): JSX.Element {
    const { items, loading } = useBreadcrumbItems(props);

    const onRenderItem = useCallback(
        (item: IBreadcrumbItem | undefined): JSX.Element => {
            return <BreadcrumbButton breadcrumb={item as AdvanceBreadcrumb} />;
        },
        []
    );

    const shimmerStyles: Partial<IShimmerStyles> = {
        shimmerWrapper: {
            marginTop: 14,
        },
    };

    const breadcrumbStyle: Partial<IBreadcrumbStyles> = {
        root: {
            margin: 0,
            padding: 0,
            height: 44,
        },
        chevron: {
            padding: 8,
        },
        item: {
            margin: 0,
            padding: 0,
        },
        overflowButton: {
            height: 44,
        },
    };

    return (
        <Shimmer
            height="40"
            width="80%"
            styles={shimmerStyles}
            isDataLoaded={!loading && items.length > 0}
        >
            <Breadcrumb
                onRenderItem={onRenderItem}
                overflowButtonAs={(props) => (
                    <BreadcrumbOverflowButton {...props} allItems={items} />
                )}
                items={items}
                styles={breadcrumbStyle}
            />
        </Shimmer>
    );
}

export default Breadcrumbs;
