import React, { useMemo } from 'react';
import {
    TextField,
    Label,
    Text,
    ITextFieldProps,
    Stack,
} from '@fluentui/react';

import debounce from 'lodash/debounce';
import { StatusReportGridPreviousReportButton } from './StatusReportGridPreviousReportButton';

export function StatusReportGridTextBox(props: {
    label?: string;
    placeholder?: string;
    defaultValue?: string;
    onChange: (newValue: string) => void;
    isReadOnly: boolean;
    previousText?: string | null;
    previousStatusReport?: {
        id: string | null;
        reportDate: string | null;
        title: string | null;
    } | null;
}): JSX.Element {
    const { onChange } = props;

    const debouncedChange = useMemo(
        () =>
            debounce(
                (_ev: React.FormEvent, newValue?: string | undefined) =>
                    onChange(newValue || ''),
                1000
            ),
        [onChange]
    );

    const handleRenderLabel = (
        textFieldProps?: ITextFieldProps,
        defaultRender?: (props?: ITextFieldProps) => JSX.Element | null
    ) => {
        return (
            <Stack horizontal verticalAlign="center">
                <Stack.Item grow>
                    {defaultRender && defaultRender(textFieldProps)}
                </Stack.Item>
                <StatusReportGridPreviousReportButton {...props} />
            </Stack>
        );
    };

    if (props.isReadOnly) {
        return (
            <div>
                <Label>{props.label}</Label>
                <Text variant="medium" style={{ whiteSpace: 'pre-wrap' }}>
                    {props.defaultValue}
                </Text>
            </div>
        );
    } else {
        return (
            <TextField
                styles={{
                    field: {
                        padding: props.isReadOnly ? 0 : undefined,
                    },
                }}
                multiline
                autoAdjustHeight
                rows={2}
                onRenderLabel={handleRenderLabel}
                label={props.label}
                placeholder={props.isReadOnly ? undefined : props.placeholder}
                defaultValue={props.defaultValue}
                borderless={props.isReadOnly}
                readOnly={props.isReadOnly}
                onChange={debouncedChange}
            />
        );
    }
}
