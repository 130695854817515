import React, { useEffect, useRef } from 'react';
import { mergeStyleSets } from '@fluentui/react';
import { AdvanceCard } from '../../../components/AdvanceCard';
import { TemplateReportMeasureListElement } from './TemplateReportMeasureListElement';
import { TemplateReportTaskTableElement } from './TemplateReportTaskTableElement';
import { TemplateReportRichTextElement } from './TemplateReportRichTextElement';
import { TemplateReportElementType } from '../TemplateReport';
import { TemplateReportCustomTableElement } from './TemplateReportCustomTableElement';
import {
    ReportPeriodTypes,
    TemplateReportElementTypes,
    useUpdateTemplateReportElementMutation,
} from '../../../data/types';
import debounce from 'lodash/debounce';
import { useStateContext } from '../../../services/contextProvider';
import { TemplateReportLabelElement } from './TemplateReportLabelElement';
import { TemplateReportImageElement } from './TemplateReportImageElement';
import { TemplateReportTaskBulletListElement } from './TemplateReportTaskBulletListElement';
import { TemplateReportMeasureTableElement } from './TemplateReportMeasureTableElement';

export type TemplateReportElementProps = {
    missionId: string;
    templateReportId: string;
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    reportPeriodType: ReportPeriodTypes | null;
    reportPeriod: number | null;
    fyStartDate: string | null | undefined;
    element: TemplateReportElementType;
    sourceElement: TemplateReportElementType | null;
    isReadOnly: boolean;
    onElementUpdateError: (error: Error | null | undefined) => void;
    onMeasureNavigate: ((measureId: string) => void) | null;
    onTaskNavigate: ((measureId: string) => void) | null;
};

export function TemplateReportElement(
    props: TemplateReportElementProps
): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { element } = props;

    const changeTracker = useRef<{
        saveState: 'NOT_SAVED' | 'SAVING' | 'SAVED';
        version: string | null;
    }>();

    const [updateElementMutation] = useUpdateTemplateReportElementMutation({
        onError: props.onElementUpdateError,
    });

    const debouncedSave = debounce(
        async (
            tenantId: string | undefined,
            updatedElement: TemplateReportElementType
        ) => {
            if (!changeTracker.current) {
                return;
            }

            if (changeTracker.current.saveState === 'SAVING') {
                debouncedSave(tenantId, updatedElement);
                return;
            }

            changeTracker.current.saveState = 'SAVING';

            const result = await updateElementMutation({
                variables: {
                    tenantId: tenantId || '',
                    input: {
                        id: updatedElement.id,
                        sectionId: updatedElement.sectionId,
                        attachmentId: updatedElement.attachmentId,
                        textContent: updatedElement.textContent,
                        groupId: updatedElement.groupId,
                        sourceTemplateReportSectionElementId:
                            updatedElement.sourceTemplateReportSectionElementId,
                        layoutGrow: updatedElement.layoutGrow,
                        sequence: updatedElement.sequence,
                        title: updatedElement.title,
                        type: updatedElement.type,
                        measureLinks: updatedElement.measureLinks,
                        taskLinks: updatedElement.taskLinks,
                        columns: updatedElement.columns,
                        version: changeTracker.current.version,
                    },
                },
            });

            changeTracker.current.saveState = 'SAVED';
            changeTracker.current.version =
                result.data?.templateReportElementUpdate.version || '';

            return;
        },
        2000
    );

    useEffect(() => {
        if (!changeTracker.current) {
            changeTracker.current = {
                saveState: 'NOT_SAVED',
                version: props.element.version,
            };
        }
    }, [props.element.version]);

    useEffect(() => {
        return () => {
            debouncedSave.flush();
        };
    }, [debouncedSave]);

    const handleElementChanged = (
        updated: TemplateReportElementType,
        forceImmediateSave: boolean
    ) => {
        debouncedSave(currentTenantId, updated);
        if (forceImmediateSave) {
            debouncedSave.flush();
        }
    };

    const classNames = mergeStyleSets({
        elementContainer: {
            flexGrow: element.layoutGrow,
            flexBasis: 0,
            display: 'flex',
            alignItems: 'stretch',

            minWidth: 0,
        },
        title: {
            margin: 0,
            paddingBottom: 4,
        },
    });

    return (
        <div className={classNames.elementContainer}>
            {element.type === TemplateReportElementTypes.Markdown ? (
                <TemplateReportRichTextElement
                    {...props}
                    onElementChanged={handleElementChanged}
                />
            ) : element.type === TemplateReportElementTypes.MeasureList ? (
                <TemplateReportMeasureListElement
                    {...props}
                    onElementChanged={handleElementChanged}
                />
            ) : element.type === TemplateReportElementTypes.MeasureTable ? (
                <TemplateReportMeasureTableElement {...props} />
            ) : element.type === TemplateReportElementTypes.TaskTable ? (
                <TemplateReportTaskTableElement
                    {...props}
                    onElementChanged={handleElementChanged}
                />
            ) : element.type === TemplateReportElementTypes.TaskBulletList ? (
                <TemplateReportTaskBulletListElement
                    {...props}
                    onElementChanged={handleElementChanged}
                />
            ) : element.type === TemplateReportElementTypes.CustomTable ? (
                <TemplateReportCustomTableElement
                    {...props}
                    onElementChanged={handleElementChanged}
                />
            ) : element.type === TemplateReportElementTypes.Label ? (
                <TemplateReportLabelElement {...props} />
            ) : element.type === TemplateReportElementTypes.Image ? (
                <TemplateReportImageElement
                    {...props}
                    onElementChanged={handleElementChanged}
                />
            ) : (
                <AdvanceCard>
                    <AdvanceCard.Item>{element.type}</AdvanceCard.Item>
                </AdvanceCard>
            )}
        </div>
    );
}
