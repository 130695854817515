import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    useGetMeasuresForStatusReportLazyQuery,
    useGetTasksForStatusReportLazyQuery,
} from '../../../data/types';
import { Access } from '../../../data/extendedTypes';
import DeleteModal from '../../../components/shared/DeleteModal';
import { SortableContainer } from '../../../components/SortableContainer';
import { StatusReportSortableItemContainer } from './StatusReportSortableItemContainer';

type FactInput = {
    id: string;
    factText: string | null;
    soWhatText: string | null;
    insightText: string | null;
    actionText: string | null;
    sequence: number;
};

function StatusReportGrid(props: {
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    tenantId: string;
    missionId: string;
    fyStartDate: string;
    facts: {
        id: string | null;
        measureId: string | null;
        taskId: string | null;
        factText: string | null;
        soWhatText: string | null;
        insightText: string | null;
        actionText: string | null;
        sequence: number;
        isIncluded: boolean;
    }[];
    onFactChanged: (fact: FactInput) => void;
    onFactRemoved: (factId: string) => void;
    onMeasureNavigate: (measureId: string) => void;
    onTaskNavigate: (taskId: string) => void;
    missionAccess: Access;
    isReadOnly: boolean;
}): JSX.Element {
    const { onFactChanged } = props;

    const factInputs = useRef<FactInput[]>([]);

    const [isConfirmingDeleteForFactId, setIsConfirmingDeleteForFactId] =
        useState<string | null>(null);

    const [loadMeasures, { data: measureData, loading: measuresLoading }] =
        useGetMeasuresForStatusReportLazyQuery();

    const [loadTasks, { data: taskData, loading: tasksLoading }] =
        useGetTasksForStatusReportLazyQuery();

    const factSorter = (
        t1: { id: string | null; sequence: number } | null,
        t2: { id: string | null; sequence: number } | null
    ): number => {
        // If there are inputs, they may have nore recent data that isn't save so use that for ordering.
        const fi1 = factInputs.current.find((f) => f.id === t1?.id);
        const fi2 = factInputs.current.find((f) => f.id === t2?.id);

        const n1 =
            fi1?.sequence !== undefined ? fi1?.sequence : t1?.sequence || 0;
        const n2 =
            fi2?.sequence !== undefined ? fi2?.sequence : t2?.sequence || 0;

        if (n1 > n2) {
            return 1;
        }

        if (n1 < n2) {
            return -1;
        }

        return 0;
    };

    const facts = useMemo(
        () => props.facts.filter((f) => f.isIncluded).sort(factSorter),
        [props.facts]
    );

    useEffect(() => {
        const forDateTime = props.utcDataDate || props.utcCompletedDate;

        loadMeasures({
            // We don't want the historic results polluting the cache
            fetchPolicy: !forDateTime ? 'cache-and-network' : 'no-cache',
            variables: {
                tenantId: props.tenantId,
                missionId: props.missionId,
                forDateTime: forDateTime,
                includeDeleted: true,
            },
        });
        loadTasks({
            // We don't want the historic results polluting the cache
            fetchPolicy: !forDateTime ? 'cache-and-network' : 'no-cache',
            variables: {
                tenantId: props.tenantId,
                missionId: props.missionId,
                forDateTime: forDateTime,
                includeDeleted: true,
            },
        });
    }, [
        props.utcDataDate,
        props.utcCompletedDate,
        props.missionId,
        props.tenantId,
        loadMeasures,
        loadTasks,
    ]);

    const updateFactInput = useCallback(
        (factId: string | null, func: (r: FactInput) => FactInput) => {
            if (!factId) {
                return;
            }

            const factInput: FactInput = {
                ...(factInputs.current.find((f) => f.id === factId) ||
                    facts.find((f) => f.id === factId) ||
                    ({} as FactInput)),
                id: factId || '',
            };

            const updatedInput = func(factInput);

            factInputs.current = [
                ...(factInputs.current
                    ? factInputs.current.filter((f) => f.id !== factId)
                    : []),
                updatedInput,
            ];

            onFactChanged(updatedInput);
        },
        [onFactChanged, facts]
    );

    const factTextChanged = useCallback(
        (factId: string | null, newValue: string) => {
            updateFactInput(factId, (f) => ({
                ...f,
                factText: newValue,
            }));
        },
        [updateFactInput]
    );

    const soWhatTextChanged = useCallback(
        (factId: string | null, newValue: string) => {
            updateFactInput(factId, (f) => ({
                ...f,
                soWhatText: newValue,
            }));
        },
        [updateFactInput]
    );

    const insightTextChanged = useCallback(
        (factId: string | null, newValue: string) => {
            updateFactInput(factId, (f) => ({
                ...f,
                insightText: newValue,
            }));
        },
        [updateFactInput]
    );

    const actionTextChanged = useCallback(
        (factId: string | null, newValue: string) => {
            updateFactInput(factId, (f) => ({
                ...f,
                actionText: newValue,
            }));
        },
        [updateFactInput]
    );

    const handleRemoveFactButtonClick = (factId: string | null) => {
        setIsConfirmingDeleteForFactId(factId);
    };

    const handleDismissDeleteFactModal = () => {
        setIsConfirmingDeleteForFactId(null);
    };

    const deleteFactAction = () => {
        if (isConfirmingDeleteForFactId) {
            props.onFactRemoved(isConfirmingDeleteForFactId);
        }
        setIsConfirmingDeleteForFactId(null);
    };

    const [activeDragItemId, setActiveDragItemId] = useState<string | null>();

    const handleFactDragging = (factId: string | null) =>
        setActiveDragItemId(factId);

    const handleFactDropped = (factId: string | null, newIndex: number) => {
        if (!factId) {
            return;
        }

        const movedItem = facts.find((m) => m.id === factId);
        const remainingItems = facts.filter(
            (f) => f.id !== factId && f.isIncluded
        );

        const reorderedItems = [
            ...remainingItems.slice(0, newIndex),
            movedItem,
            ...remainingItems.slice(newIndex),
        ];

        let index = 0;
        for (const fact of reorderedItems) {
            if (fact && fact?.sequence !== index) {
                updateFactInput(fact.id, (f) => ({
                    ...f,
                    sequence: index,
                }));
            }
            index++;
        }
    };

    return (
        <React.Fragment>
            <SortableContainer
                ids={facts.map((f) => f.id || '')}
                onDragging={handleFactDragging}
                onDropped={handleFactDropped}
            >
                {facts.map((f) => {
                    const measure = f.measureId
                        ? measureData?.measures?.find(
                              (m) => m.id === f.measureId
                          )
                        : null;

                    const task = f.taskId
                        ? taskData?.tasks?.find((t) => t.id === f.taskId)
                        : null;

                    return (
                        <StatusReportSortableItemContainer
                            key={f.id}
                            fact={f}
                            task={task || null}
                            measure={measure || null}
                            tasks={taskData?.tasks}
                            measures={measureData?.measures}
                            fyStartDate={props.fyStartDate}
                            utcDataDate={props.utcDataDate}
                            utcCompletedDate={props.utcCompletedDate}
                            missionAccess={props.missionAccess}
                            isLoading={measuresLoading || tasksLoading}
                            isReadOnly={props.isReadOnly}
                            onFactTextChanged={(newValue) =>
                                factTextChanged(f.id, newValue || '')
                            }
                            onSoWhatTextChanged={(newValue) =>
                                soWhatTextChanged(f.id, newValue || '')
                            }
                            onInsightTextChanged={(newValue) =>
                                insightTextChanged(f.id, newValue || '')
                            }
                            onActionTextChanged={(newValue) =>
                                actionTextChanged(f.id, newValue || '')
                            }
                            onRemoveFactButtonClick={() =>
                                handleRemoveFactButtonClick(f.id)
                            }
                            isActive={activeDragItemId === f.id}
                            onMeasureNavigate={props.onMeasureNavigate}
                            onTaskNavigate={props.onTaskNavigate}
                        />
                    );
                })}
            </SortableContainer>

            <DeleteModal
                activeViewName="StatusReportFactDelete"
                isOpen={isConfirmingDeleteForFactId !== null}
                onDismiss={handleDismissDeleteFactModal}
                isDeleting={false}
                deleteAction={deleteFactAction}
                error={null}
                message="Are you sure you want to remove this fact from the report?"
                primaryButtonText="Confirm"
            />
        </React.Fragment>
    );
}

export default memo(StatusReportGrid);

//export default StatusReportGrid;
