import React from 'react';
import { Label, Link, Stack, Text } from '@fluentui/react';
import { GetMeasuresForStatusReportQuery } from '../../../data/types';
import {
    ExtractQueryArrayType,
    MeasureSeriesNames,
} from '../../../data/extendedTypes';
import MeasureArrow from '../../../components/MeasureArrow';
import dayjs from 'dayjs';

export function StatusReportGridMeasureDetail(props: {
    measure: ExtractQueryArrayType<
        GetMeasuresForStatusReportQuery,
        ['measures']
    > | null;
    allowNavigation: boolean;
    onNavigate: () => void;
    isHistoric: boolean;
    hideLabel?: boolean;
}): JSX.Element {
    const { measure } = props;

    const detailToken = { childrenGap: 16 };

    const lastAsOf = props.isHistoric
        ? measure?.asOfForDate
        : measure?.lastAsOf;

    return (
        <Stack tokens={{ childrenGap: 4 }}>
            {!props.hideLabel && (
                <Label>
                    Measure of Success{' '}
                    {!measure || measure.utcDeleted
                        ? `(Deleted ${dayjs(measure?.utcDeleted).format(
                              'DD MMM YYYY'
                          )})`
                        : ''}
                </Label>
            )}

            <Text
                variant="mediumPlus"
                block
                styles={{
                    root: {
                        textDecoration: measure?.utcDeleted
                            ? 'line-through'
                            : undefined,
                    },
                }}
            >
                {props.allowNavigation && !measure?.utcDeleted ? (
                    <Link onClick={props.onNavigate}>{measure?.name}</Link>
                ) : (
                    measure?.name
                )}
            </Text>

            {measure && (
                <Stack
                    horizontal
                    verticalAlign="center"
                    tokens={detailToken}
                    styles={{
                        root: {
                            textDecoration: measure?.utcDeleted
                                ? 'line-through'
                                : undefined,
                        },
                    }}
                >
                    <Stack.Item>
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 4 }}
                            verticalAlign="center"
                        >
                            <Text variant="smallPlus">Target:</Text>
                            <Text
                                variant="small"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                {
                                    lastAsOf?.values?.find(
                                        (v) =>
                                            v.seriesType?.name ===
                                            MeasureSeriesNames.Target
                                    )?.formatStr
                                }
                            </Text>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 4 }}
                            verticalAlign="center"
                        >
                            <Text variant="smallPlus">Actual:</Text>
                            <Text
                                variant="small"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                {
                                    lastAsOf?.values?.find(
                                        (v) =>
                                            v.seriesType?.name ===
                                            MeasureSeriesNames.Actual
                                    )?.formatStr
                                }
                            </Text>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 4 }}
                            verticalAlign="center"
                        >
                            <Text variant="smallPlus">Status:</Text>
                            <Stack.Item
                                align="start"
                                verticalFill={true}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                <MeasureArrow
                                    isStatusLimited={measure.isStatusLimited}
                                    arrowColour={lastAsOf?.arrowColour}
                                    arrowDirection={
                                        lastAsOf?.arrowDirection || 'SAME'
                                    }
                                    values={lastAsOf?.values}
                                    iconFontSize={12}
                                    textFontSize={12}
                                    showPercentage
                                    percentagePosition="Left"
                                    statusValue={lastAsOf?.statusValue || 0}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            )}
        </Stack>
    );
}
